// Data
.user-input-wrapper {
    padding-bottom: 12px;
    padding-right: 30px;

    .data-wrapper {
        display: block;
        transform: translateY(8px);

        span {
            line-height: 1em;
        }
    }

    .data-title {
        margin-top: -9px;

        .data-title__label {
            color: #ececec;
            font-size: 18px;
        }
    }
}
