@import '../../../styles/vars.scss';

.subscribe-button {
    cursor: pointer;

    @media only screen and (max-width: 600px) {
        display: block;
        max-height: 18px;
    }
}

.unsubscribed {
    path {
        stroke: $grey;
    }
    ellipse {
        stroke: $grey;
    }
}
