.gt-tr {
    .gt-td {
        .clickable {
            display: flex;
        }
    }
}

.gt-header-right {
    width: 7vh;
    justify-content: space-between;

    svg {
        cursor: pointer;
    }
}