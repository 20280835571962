.mapinfo {
    background: #081b23;
    color: black;
    position: relative;
    top: -210px;
    right: 175px;
    display: flex;
    flex-direction: column;
    padding-right: 0.5em;
    padding-left: 0.5em;
    border-radius: 0.4em;
    cursor: default;
    width: 350px;
    height: 150px;
    border: 1px solid #325663;
    font-family: 'Rajdhani', sans-serif;
    text-transform: uppercase;
    justify-content: space-around;
    z-index: 1000014;
}

.mapinfo:after,
.mapinfo:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.mapinfo:after {
    border-color: rgba(8, 27, 35, 0);
    border-top-color: #081b23;
    border-width: 15px;
    margin-left: -15px;
}
.mapinfo:before {
    border-color: rgba(50, 86, 99, 0);
    border-top-color: #325663;
    border-width: 17px;
    margin-left: -17px;
}

.mapinfo-flipped {
    background: #081b23;
    color: black;
    position: relative;
    right: 175px;
    top: 10px;
    display: flex;
    flex-direction: column;
    padding-right: 0.5em;
    padding-left: 0.5em;
    border-radius: 0.4em;
    cursor: default;
    width: 350px;
    height: 150px;
    border: 1px solid #325663;
    font-family: 'Rajdhani', sans-serif;
    text-transform: uppercase;
    justify-content: space-around;
    z-index: 2;
}
.mapinfo-flipped:after,
.mapinfo-flipped:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.mapinfo-flipped:after {
    border-color: rgba(8, 27, 35, 0);
    border-bottom-color: #081b23;
    border-width: 15px;
    margin-left: -15px;
}
.mapinfo-flipped:before {
    border-color: rgba(50, 86, 99, 0);
    border-bottom-color: #325663;
    border-width: 17px;
    margin-left: -17px;
}

.info-name,
.info-description {
    padding-left: 0.5em;
    padding-right: 0.5em;
    width: 25%;
}

.info-name {
    display: flex;
    justify-content: center;
    width: auto;
    border-bottom: 1px solid #325663;
    font-size: 20px;
    color: #41c9f0;
    margin-top: 0.3em;
    padding-bottom: 0.3em;
    flex: 0 1 20%;
}

.info-connection {
    display: flex;
    justify-content: space-evenly;
    width: auto;
    color: #41c9f0;
}

.info-data {
    display: flex;
    flex: 0 1 50%;
    font-size: 12px;
    align-items: center;
    justify-content: space-around;
    width: auto;
}

.info-metadata {
    color: #325663;
    font-size: 1em;
    font-weight: lighter;
}

.hidden {
    visibility: none;
}

.mapinfo-table {
    width: 250px;
}
