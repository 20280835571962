@import '../../../../styles/vars.scss';

.infoDiv {
    margin: 0 0 8px 0px;
    display: flex;
    flex: 1;
    position: relative;
}
.sizedistribouter {
    width: 100%;
}
