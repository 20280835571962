@import '../../../styles/vars.scss';

.header {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: #0d171e;
    display: flex;
    height: 100%;
    justify-content: space-between;
    z-index: 1000;
    flex: 0 0 100vw;
    border-bottom: 1px solid #2c4147;
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 4.3em;
    background-image: url('../../../images/top-bg.jpg');
}

.header-support {
    position: relative;
    display: flex;
    height: 4.3em;
    align-items: center;
    border-right: 1px solid #3f5e6a;
    .support-mail {
        font-size: 12px;
        font-family: 'Calibri';
        color: #fff;
        font-weight: 600;
        cursor: default;
    }
    .support-icon {
        height: 55px;
        margin: 0px -60px 0px -60px;
    }
}

@media only screen and (max-width: 600px) {
    .header {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        height: 100%;
        justify-content: space-between;
        z-index: 4;
        border-bottom: none;
        background-image: url('../../../images/top-bg.jpg');
        position: relative;
    }
}

.rightmenu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-left: 1px solid #3f5e6a;
    border-right: 1px solid #3f5e6a;
    margin-right: 40px;
    background-color: rgba(13, 23, 30, 0.5);
}

@media only screen and (max-width: 600px) {
    .rightmenu {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-left: 1px solid #3f5e6a;
        background-color: rgba(13, 23, 31, 0.65);
        width: 60px !important;
        margin-right: 0;
    }
}

.left-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 1 400px;
    background-color: rgba(13, 23, 30, 0.5);
}

@media only screen and (max-width: 600px) {
    .left-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 50px;
        flex: 0 0 100px;
        background-color: rgba(13, 23, 31, 0.65);
        border-bottom: 1px solid #2c4147;
    }
}

.home {
    padding-left: 1.5em;
    padding-right: 1.5em;
    text-decoration: none;
    color: white;
    height: 100%;
    width: auto;
    align-items: center;
    display: flex;
    border-right: 1px solid #2c4147;
    > svg {
        stroke: #41c9f0;
    }
}

@media all and (-ms-high-contrast: none) {
    .home {
        width: 22px;
        height: 100%;
        display: flex;
    }
    .home svg {
        margin-top: 1.5em;
        margin-right: 0.5em;
    }

    .header {
        overflow: hidden;
    }
}

.home-icon {
    color: #68b4c9;
    fill: #68b4c9;
}

.home:hover {
    background-color: #134758;
}

.header-plantname {
    font-size: 24px;
    color: white;
    font-weight: 400;
    margin-top: auto;
    margin-bottom: auto;

    text-decoration: none;
}

.link-active {
    background-color: #0f3b49;
}

.plantname-container {
    text-decoration: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-image: url('../../../images/top-bg.jpg');
    flex: 0 1 100vw;
    justify-content: center;
    border-left: 1px solid #2c4147;
}

@supports (-ms-ime-align: auto) {
    .plantdropdown-container {
        width: 1em; /* No idea why this works, but header overflows in edge without it */
    }
}

@media only screen and (max-width: 600px) {
    .plantname-container {
        text-decoration: none;
        display: flex;
        align-items: center;
        cursor: pointer;
        flex: 0 1 100vw;
        justify-content: center;
        border-left: 1px solid #2c4147;
        border-bottom: 1px solid #2c4147;
    }
}

.plantdropdown-container {
    display: flex;
    flex: 0 1 100vw;
}

.plantdropdown {
    background: #0d171e;
    position: fixed;
    z-index: 5;
    top: 4.4em;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
}

.pd-main-nav {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
    width: 100%;
    align-items: center;
}

.pd-main-nav-element {
    display: flex;
    justify-content: center;
    height: 2em;
    margin-bottom: 0.5em;
    width: 90%;
    border: 1px solid #2c4147;
    border-radius: 0.4em;
    background: #0d171e;
}

.pd-main-nav-element a {
    color: #41c9f0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .header-plantname {
        font-size: 20px;
        color: white;
        font-weight: 500;
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 0em;
        text-decoration: none;
        text-align: center;
    }
    .home {
        padding-left: 1em;
        padding-right: 1em;
    }
}
