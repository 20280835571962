.file-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    max-width: 100px;

    word-break: break-all;
}

.file-remove {
    position: absolute;
    top: -15px;
    right: -15px;
    cursor: pointer;
}

.file-upload {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #41c9f0;
    font-family: Rajdhani;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 15px;
    height: 36px;
    padding: 0 20px 0 20px;
    margin-top: 110px;

    border: 1px solid #2c4147;
    border-radius: 5px;
    background-color: #0d171e;
    cursor: pointer;
}

.input-control {
    text-decoration: none;
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    height: 3em;
    width: 100%;
    border-style: none;
    color: #919191;
    font-size: 15px;
    /* margin-top: 1em; */
    justify-content: center;
    align-items: center;
    background-color: #0d171e;
    border: 1px solid #3f5e6a;
    border-radius: 0.4em;
    padding-left: 5px;
}
