@import '../../styles/vars.scss';

.form-group {
    padding-bottom: 10px;
}
.editor {
    width: 100%;
    & > div {
        width: 100%;
        height: 50vh;
    }
}

.buttons {
    padding-bottom: 15px;
}

textarea.jsoneditor-text {
    background: $teal;
    color: $light !important;
}

.viewslist {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 20px;

    .viewlist-content {
        cursor: pointer;
        padding-right: 30px;

        .view-active {
            color: #41c9f0;
            font-size: 20px;
            border-bottom: 2px solid #41cdf5;
            font-weight: 600;
        }

        .view-inactive {
            color: #41c9f0;
            font-size: 20px;
        }
    }
}

.designer {
    .title-form {
        display: flex;
        align-items: center;
        padding-bottom: 10px;

        label {
            padding-top: 0;
            font-size: 25px;
            padding-right: 20px;
        }

        input {
            max-width: 500px;
            margin-right: 20px;
        }

        .section-info {
            display: flex;
            flex-direction: column;
            p {
                margin: 0;
            }
        }
    }

    .sections-controls {
        width: 20px;
    }
}

.section-designer {
    padding: 20px;
    margin-left: 75px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.row-expander-icon {
    font-size: 33px;
    line-height: 1;
    padding-right: 4px;
}

.modal_content {
    position: absolute;
    inset: 54% auto auto 50%;
    border: 1px solid $teal;
    background: #0d171e;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 40px;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 30px 2px $dark;
    min-width: 500px;
    min-height: 20vw;
    max-height: 80%;
}

.auto_modal_content {
    position: absolute;
    inset: 54% auto auto 50%;
    border: 1px solid $teal;
    background: #0d171e;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 40px;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 30px 2px $dark;
}

.datapoint_details {
    list-style: none;
    margin: 0;
    padding: 0;
}

.section-designer .section-items {
    .section-item-add {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed #646464;
        height: 30px;
        overflow: visible;
        cursor: pointer;
        font-size: 33px;
        line-height: 1;
        font-weight: bold;
    }

    .section-item-content {
        position: relative;

        .section-item {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #646464;
            height: 90px;
            font-size: 33px;
            line-height: 1;
            font-weight: bold;
        }

        .configurable-item {
            cursor: pointer;
        }
        .configurable-item:hover {
            background-color: $darkblue;
        }

        .icons {
            position: absolute;
            right: 7px;
            top: 7px;

            span {
                padding-right: 5px;
                font-size: 28px;
                cursor: pointer;
            }
        }
    }
}

.datapointtable {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    td {
        justify-content: center;
        cursor: pointer;
    }

    td:hover {
        background-color: $darkblue;
    }

    .datapointtable-cell {
        vertical-align: center;
        border: 1px solid #646464;
        height: 100px;
        min-width: 100px;
        max-width: 100px;
        text-align: center;
    }

    .datapointtable-newrow,
    .datapointtable-newcol,
    .datapointtable-del {
        border: 1px dashed #646464;
        font-size: 20px;
        line-height: 1;
        font-weight: bold;
        padding: 0;
    }

    .datapointtable-newrow,
    .datapointtable-del {
        text-align: center;
        padding-right: 2px;
    }

    .datapointtable-newcol {
        span {
            line-height: 1;
        }

        .add-left {
            float: left;
            width: 33%;
        }
        .del-col {
            float: left;
            text-align: center;
            width: 33%;
        }
        .add-right {
            text-align: right;
            float: right;
            width: 33%;
        }
    }
}

.customtable_header {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    td,
    th {
        border: 1px solid #646464;
    }

    input {
        height: 25px;
        background: #1e2e34;
        border: 0;
        border-radius: 0;
    }
}

.customtable_header_l2 {
    padding-left: 30px;
}

.customtable_header_l1,
.customtable_header_l2 {
    input,
    select {
        height: 25px;
        margin-top: 5px;
        margin-left: 5px;
        color: #919191;
        background: #1e2e34;
        border: 0;
        border-radius: 0;
    }

    select {
        width: 200px;
        display: inline-block;
        margin-right: 5px;
    }
}

.sketch-picker {
    background-color: $darkblue !important;

    input {
        background-color: #1e2e34;
        color: #919191;
        border: 1px solid #3f5e6a;
    }
}
