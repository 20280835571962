@import '../../../styles/vars';

.admin-users-edit {
    display: flex;
    flex-direction: column;
}

.admin-users-edit-tabs {
    display: flex;
    margin-top: -40px;
    margin-left: -40px;
    margin-right: -40px;
    flex-direction: row;
    position: absolute;
    width: 100%;
    height: 2.5em;
    justify-content: space-between;
}

.admin-users-edit-tab {
    border-bottom: 1px solid rgb(45, 65, 80);
    border-left: 1px solid rgb(45, 65, 80);
    border-right: 1px solid rgb(45, 65, 80);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.admin-users-edit-tab > p {
    margin-top: 28px;
    display: flex;
    justify-content: center;
}

.admin-users-edit-tab:hover {
    background-color: #121e26;
}

.admin-users-edit-tab--active {
    background-color: rgb(45, 65, 80);
}

.admin-users-edit-bottom-buttons {
    margin-top: 1em;
}

.admin-users-modal-content {
    width: 100%;
}

.admin-users-modal-roles {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    height: 28.5em;
    width: 100%;
    overflow-y: scroll;
    //background-color: #14242c;
}

.admin-users-modal-items {
    width: 100%;
    display: flex;
    flex-direction: row;
    // background-color: #14242c;
}

.admin-users-modal-item {
    width: 100%;
    margin: 0.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 0;
}

.form-container {
    height: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    width: 100%;
}

.form {
    width: 500px;
}

.form-error {
    color: Red;
}

#username {
    text-decoration: none;
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    height: 3em;
    width: 100%;
    color: #919191;
    cursor: pointer;
    font-size: 15px;
    /* margin-top: 1em; */
    justify-content: center;
    align-items: center;
    background-color: #0d171e;
    border: 1px solid #3f5e6a;
    border-radius: 0.4em;
    padding-left: 5px;
}

#password {
    text-decoration: none;
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    height: 3em;
    width: 100%;
    color: #919191;
    cursor: pointer;
    font-size: 15px;
    /* margin-top: 1em; */
    justify-content: center;
    align-items: center;
    background-color: #0d171e;
    border: 1px solid #3f5e6a;
    border-radius: 0.4em;
    padding-left: 5px;
}

#account {
    text-decoration: none;
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    height: 3em;
    width: 100%;
    color: $light;
    cursor: pointer;
    font-size: 15px;
    /* margin-top: 1em; */
    justify-content: center;
    align-items: center;
    background-color: #0d171e;
    border: 1px solid #3f5e6a;
    border-radius: 0.4em;
    padding-left: 5px;
}

/* label {
    display:inline-block;
    padding-top: 20px;
} */

.submit {
    text-decoration: none;
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    height: 3em;
    width: 100%;
    color: $light;
    cursor: pointer;
    font-size: 15px;
    margin-top: 1em;
    justify-content: center;
    align-items: center;
    background-color: #0d171e;
    border: 1px solid #3f5e6a;
    border-radius: 0.4em;
}

.submit :disabled {
    background: #919191;
}

.formErrors {
    color: #ff0000;
}

.button :disabled {
    border: 1px solid #383838;
}

button:disabled,
button[disabled] {
    color: #383838;
    cursor: default;
}

.user-successfully-added {
    color: green;
}
