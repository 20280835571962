.breadcrumb {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    margin: auto;
    position: relative;
    width: 100%;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
.crumb {
    line-height: 30px;
}
.crumb a {
    text-decoration: none;

    white-space: nowrap;
    font-size: 20px;
    font-weight: lighter;
    color: #41cdf5;
}

.current a {
    color: #e6e6f0;
}

.separator {
    padding-right: 7px;
}

.sitemap-button {
    border: 1px solid #2c4147;
    border-radius: 0.3em;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 0.5em;
}

.subpath-toggle {
    text-decoration: none;
    white-space: nowrap;
    font-size: 20px;
    font-weight: lighter;
    color: #41cdf5;
    cursor: pointer;
    position: relative;
    z-index: 11;
}

.breadcrumb-subpaths {
    flex-direction: column;
    position: absolute;
    left: 10px;
    background-color: #0d171e;
    border: 1px solid #2c4147;
    padding: 10px 20px 10px 20px;
    z-index: 2;
}

.subpath:hover {
    color: #2ba7c9;
}

@media only screen and (max-width: 600px) {
    .breadcrumb-grid {
        margin-top: 1em !important;
        margin: 0%;
    }
    .breadcrumb {
        margin-top: -1em;
    }
    .crumb a {
        font-size: 14px;
    }
}
