@import '../../../styles/vars.scss';

.tooltip-box {
    border: 1px solid $teal;
    height: auto;
    max-height: 300px;
    min-width: 180px;
    width: auto;
    padding: 12px;
    background: $dark;
    max-height: none;
    white-space: nowrap;
    line-break: strict;

    .tooltip-date {
        font-size: 16px;
        color: $grey;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .tooltip-label {
        font-size: 14px;
        padding-top: 8px;
        padding-bottom: 4px;

        &.spread {
            color: $yellow;
        }
    }

    .tooltip-value {
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        .tooltipvalue {
            margin-right: 5px;
        }
        .tooltipunit {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.loading-data {
    text-align: center;
    color: $grey;
    padding: 20px;
    font-size: 14px !important;
    position: absolute;
    top: -20px;
    left: 44%;

    .loading-area {
        margin-top: 0px;
    }
}

.status-info {
    color: $yellow;
    font-size: 13px;
    left: 0;
}

.tabs {
    display: flex;
    border-bottom: 1px solid $blue;
}

.tab {
    display: flex;
    padding: 3px 15px 3px 15px;
    cursor: pointer;
}

.tab.selected {
    border-bottom: 3px solid $blue;
}

.tab:first-of-type {
    padding: 3px 15px 3px 0px;
}

.rich-list-expand-area {
    overflow: inherit !important;

    .widget {
        overflow: inherit !important;
    }
}

.widget {
    .event-table {
        width: 100%;
        margin-top: 20px;

        tr.active {
            td {
                background-color: $darkblue;
            }
        }

        th {
            text-align: left;
            border-bottom: 1px solid $teal;
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
            padding: 7px 0 6px 0;
            color: $grey;
        }
        td {
            text-align: left;
            border-bottom: 1px solid $teal;
            font-size: 18px;
            line-height: 1.5;
            color: $light;
            padding: 4px 0 3px 0;
        }
    }
}

.no-data-message {
    text-align: center;
    padding: 65px 0px;
}

.yunit {
    margin-left: 60px;
    color: rgb(100, 100, 100);
}

@media only screen and (max-width: 600px) {
    .tabs {
        padding-top: 20px;
    }
}
