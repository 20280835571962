@import '../../../../styles/vars.scss';

.profilemodal {
    display: flex;
    .profile-title {
        position: absolute;
        top: 10px;
        left: 40px;
    }

    .profile-form {
        display: flex;
        flex-direction: column;
    }

    .pat-display {
        color: $blue;
        cursor: pointer;
    }

    .pat-textarea {
        width: 100%;
        background-image: none;
        resize: none;
    }

    .pat-delete {
        color: $blue;
        cursor: pointer;
        .delete {
            line {
                stroke: $red;
            }
        }
    }
}
