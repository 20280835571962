@import '../../../styles/vars.scss';

.joblist {
    .job-data {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .row-expander-icon {
        font-size: 36px;
        line-height: 1;
        padding-right: 4px;
        display: flex;
        justify-items: center;
    }

    .new-job-dashboard {
        padding: 20px;

        .btn {
            width: 270px;
            text-align: center;
        }
    }

    .bar-gauge-bar {
        background-color: $teal;

        height: 8px;
        position: relative;
        width: 100%;

        .bar-gauge-bar-progress {
            background-color: var(--color);
            height: 100%;
            width: var(--progress);
        }
    }

    .relation-flow {
        display: flex;
        flex-flow: row nowrap;
        flex-direction: row-reverse;
        justify-content: flex-end;

        >div {
            padding: 3px 6px;
            background: $teal;
            color: white;
            margin-right: 20px;
            font-size: 16px;
            position: relative;
            cursor: pointer;

            &.predecessor {
                &::before {
                    content: '';
                    position: absolute;
                    left: -16px;
                    top: 11px;
                    width: 0;
                    height: 0;
                    border: solid white;
                    border-width: 0 3px 3px 0;
                    display: inline-block;
                    padding: 3px;
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                }
            }

            &.part-of {
                &::before {
                    content: '';
                    position: absolute;
                    left: -13px;
                    top: 11px;
                    width: 0;
                    height: 0;
                    border: solid white;
                    border-width: 0 3px 3px 0;
                    display: inline-block;
                    padding: 3px;
                    transform: rotate(135deg);
                    -webkit-transform: rotate(135deg);
                }
            }

            &.current {
                background: $grey;
                color: white;
                cursor: inherit;
            }
        }

        .relation-header {
            display: block;
            font-size: 12px;
        }

        .relation-name {
            display: block;
            font-size: 16px;
            line-height: 16px;
        }
    }
}

.right-panel-modal {
    position: absolute;
    inset: 6% 0% auto auto;
    border: 1px solid rgb(45, 65, 80);
    background: rgb(13, 23, 30);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    box-shadow: rgb(10, 22, 28) 0px 0px 30px 2px;
    max-height: 80vh;
    min-height: 92vh;
    width: auto;
    max-width: 70%;
    min-width: 50%;
}

.right-panel-modal-overlay {
    position: fixed;
    inset: 0px;
    background-color: rgba(10, 22, 28, 0.8);
}

.side-panel-header {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    opacity: 1;
    z-index: 999 !important;
    background-color: rgb(10, 22, 28);
    padding: 20px;
    border-bottom: 1px solid rgba(45, 65, 80, 0.446);
}

.side-panel-content {
    min-height: 80vh;
    padding: 20px;
}

.side-panel-footer {
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 1;
    z-index: 999 !important;
    background-color: rgb(10, 22, 28);
    padding: 20px;
    border-bottom: 1px solid rgba(45, 65, 80, 0.446);

    .btn {
        max-height: 60px;
        height: auto;
        margin: 0 2px 0 2px;
    }
}


.viewslist {
    margin-bottom: 0;
}

.side-panel-close-btn {
    cursor: pointer;
}

@media screen and (max-width: 1600px) {
    .right-panel-modal {
        max-width: 50%;
    }
}

@media screen and (max-width: 1280px) {
    .right-panel-modal {
        max-width: 80%;
    }
}

@media screen and (max-width: 992px) {
    .right-panel-modal {
        max-width: 100%;
    }
}