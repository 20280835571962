@import '../../../styles/vars.scss';

.buoy-number {
    cursor: default;
}

.buoy-group {
    display: flex;
    flex-wrap: wrap;
}

.buoy-group-buoy {
    margin: 10px;
}
