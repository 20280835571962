.content-grid-notes {
    display: grid;
    grid-template: 'crumb crumb crumb' 'sidebar notes notes' 'sidebar notes notes' / 1fr 100%;
    grid-gap: 0;
    height: 100%;
    max-width: 100%;
    width: 100%;
}

.sidebar-grid {
    margin-top: 2em;
}

.list-items-header {
    cursor: pointer;
}

.sidebar-grid-notes {
    grid-area: sidebar;
    background-color: #0d171e;
    width: 5em;
    margin-left: 1em;
    margin-top: 3.75em;
    height: 10.7em;
    overflow: hidden;
    border: 1px solid #2c4147;
    border-radius: 0.4em;
}

.notelist-grid {
    grid-area: notes;
    border-radius: 0.4em;
}

.notelist-crumb {
    grid-area: crumb;
    margin-top: 2em;
    border-radius: 0.4em;
    font-size: 20px;
}

.notelist-row {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin: 0.4em;
    align-items: center;
    font-size: 20px;
    text-decoration: none;
    font-size: 15px;
    color: #ccc;
    background-color: #0d171e;
    border: 1px solid #2c4147;
    border-radius: 0.4em;
}

.reportname-header {
    display: flex;
    justify-content: left;
    align-items: center;
    flex: 0 3 1500px;
    margin-left: 20px;
    height: 100%;
}

.notelist-name-cell {
    display: flex;
    flex: 0 3 1500px;
    justify-content: left;
    border-right: 1px solid #2c4147;
    height: 100%;
    align-items: center;
    padding: 10px;
}

.notelist-date-cell {
    display: flex;
    flex: 0 1 150px;
    justify-content: center;
    border-right: 1px solid #2c4147;
    height: 100%;
    align-items: center;
    padding: 10px;
}

.notelist-download-cell {
    display: flex;
    flex: 1 0 96px;
    height: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 16px;
    flex-direction: column;
    cursor: pointer;
}

.notelist-download-cell .download-text {
    padding-top: 8px;
}

.notelist-header {
    display: flex;
    flex-direction: row;
    justify-content: left;
    height: 3em;
    align-items: center;
    font-size: 20px;
    color: #ccc;
    font-size: 16px;
    margin-left: 10px;
}

.date-header-element {
    display: flex;
    flex: 0 1 150px;
    justify-content: left;
}

.download-header-element {
    display: flex;
    flex: 0 1 100px;
    justify-content: left;
}

.download-icon {
    font-size: 24px;
}

.less-top-margin {
    margin-top: 3em;
}

.add-note {
    position: absolute;
    right: 0;
    border: 1px solid #2c4147;
    border-radius: 3px;
    padding: 6px;
    cursor: pointer;
    font-size: 14px;
    text-transform: initial;
}

.add-note:hover {
    color: #ccc;
}

.note-comment {
    font-size: 14px;
}

.ReactModal__Overlay {
    z-index: 200;
}

.ReactModal__Overlay--after-open {
    overflow-y: scroll !important;
}

.note-text {
    flex-flow: row wrap;
}

.note-left {
    flex: 0 0 30%;
    margin-left: 10%;
}

.note-right {
    flex: 0 0 40%;
    margin-right: 10%;
}

.ascending-sort::after {
    padding-left: 15px;
    content: '\2191';
}

.descending-sort::after {
    padding-left: 15px;
    content: '\2193';
}

.ant-pagination {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', 'tnum';
}

.ant-pagination-total-text {
    display: inline-block;
    height: 32px;
    margin-right: 8px;
    line-height: 30px;
    vertical-align: middle;
}

.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus {
    cursor: not-allowed;
}

.ant-pagination-prev,
.ant-pagination-next {
    outline: 0;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    color: rgba(0, 0, 0, 0.65);
    font-family: Arial;
    line-height: 32px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border-radius: 4px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
    margin-right: 8px;
}

.ant-pagination-item-active {
    font-weight: 500;
    background: #fff;
    border-color: #1890ff;
}

.ant-pagination-item {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    margin-right: 8px;
    font-family: Arial;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    outline: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ant-pagination-total-text {
    color: #e6e6f0;
}

.ant-pagination-total-text {
    color: #e6e6f0;
}

.ant-pagination-item {
    background-color: #0d171e !important;
    color: #e6e6f0 !important;
    border: none !important;
}

.ant-pagination-item a {
    color: #e6e6f0 !important;
}

.ant-pagination-item-active a {
    background-color: #0d171e !important;
    color: #2bc9f0 !important;
    border: none !important;
}

.ant-pagination-disabled a,
.ant-pagination-disabled:hover a,
.ant-pagination-disabled:focus a,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
:root .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
:root .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-select-selection {
    background-color: #0d171e !important;
    color: #e6e6f0 !important;
    border: none !important;
}
.ant-select-arrow .ant-select-arrow-icon {
    margin-right: -5px !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
    display: block;
    height: 100%;
    font-size: 12px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    outline: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.ant-pagination-options {
    display: inline-block;
    margin-left: 16px;
    vertical-align: middle;
}

.ant-pagination-options-size-changer.ant-select {
    display: inline-block;
    width: auto;
    margin-right: 8px;
}

.ant-select {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #e6e6f0;
    font-size: 15px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', 'tnum', 'tnum';
    position: relative;
    display: inline-block;
    outline: 0;
}

.ant-select-selection--single {
    position: relative;
    height: 3em;
    cursor: pointer;
}

.ant-select-selection {
    display: block;
    box-sizing: border-box;
    background-color: #1e2e34 !important;
    border: none;
    border-top-width: 1.02px;
    outline: none;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ant-select-selection--single .ant-select-selection__rendered {
    margin-right: 24px;
}

.ant-select-selection__rendered {
    position: relative;
    display: block;
    margin-right: 11px;
    margin-left: 5px;
    line-height: 45px;
}

.ant-select-selection-selected-value {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ant-select-selection-selected-value {
    float: left;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media only screen and (max-width: 600px) {
    .content-grid-notes {
        width: 100vw;
        max-width: 100vw;
        margin-left: 0 auto;
        margin-right: 0 auto;
        display: flex;
        justify-content: flex-start;
    }
    .sidebar-grid-notes {
        display: hidden;
        width: 0px;
    }
    .noteslist-grid {
        width: 100vw;
        display: flex;
        margin-left: 0em;
    }
    .ReactModal__Content {
        width: 88%;
        height: 90%;
    }
    .ReactModal__Content form {
        width: 100%;
    }
    .form-container {
        height: auto !important;
        margin-top: 20px !important;
    }
    .note-left {
        flex: 0 0 100%;
        margin-left: 0;
    }
    .note-right {
        flex: 0 0 100%;
    }
    .comment-header {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 660px) {
    .paginator {
        padding-bottom: 80px;
    }

    .ant-pagination-total-text {
        display: block !important;
    }

    li:first-of-type::after {
        content: '\a';
        white-space: pre;
    }
}
