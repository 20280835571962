@import '../../styles/vars.scss';

.admin-container {
    color: $light;
}

.ReactTable {
    background: none;
    border: 0;
    .rt-th {
        text-align: left;
        padding-right: 10px;
        border: 0 !important;
        border-radius: 4px 0 0 0;
        padding: 0 !important;
        border-bottom: 1px solid #2d4150;
    }
    .rt-thead.-filters input {
        background: none;
        border: 0;
        background-color: #1e2e34;
        border-radius: 0;
        color: $light;
    }
    .rt-td {
        font-size: 19px;
        line-height: 1.5;
        color: $light;
        border-right: 0 !important;
        border-bottom: 1px solid #2d4150;
        padding: 5px 7px 4px 0;
    }
    .list-edit-btn {
        color: $blue;
        cursor: pointer;
        margin-right: 8px;
    }
    .rt-resizable-header {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        outline: 0;
    }
    .rt-resizer:before {
        display: inline-block;
        position: absolute;
        right: 25px;
        top: 3px;
        height: 18px;
        width: 18px;
        color: transparent;
        content: '.';
        background-size: 18px 18px;
        background-repeat: no-repeat;
        opacity: 0.87;
    }
    .rt-resizable-header-content:after {
        position: absolute;
        right: 8px;
        top: 3px;
        height: 18px;
        width: 18px;
        z-index: 120;
        color: transparent;
        content: '.';
    }
    .rt-th.-sort-asc {
        .rt-resizer:before {
            background-color: transparent;
            content: url('../../images/arrow-up.svg');
        }
    }
    .rt-th.-sort-desc {
        .rt-resizer:before {
            background-color: transparent;
            content: url('../../images/arrow-down.svg');
        }
    }
}

.-filters {
    border-bottom: 0 !important;
    > .rt-tr {
        > .rt-th {
            border-bottom: 0;
            border-radius: 0;
            margin: 7px 7px 7px 0;
            > input {
                border-radius: 0 !important;
            }
        }
    }
}

.rt-tr-group :hover {
    background-color: $form-input;
}

.form-invalid {
    color: $red;
    font-size: 14px;
}

.small-text {
    font-size: 12px;
    color: $grey;
}

h1 {
    margin-top: 15px;
}
