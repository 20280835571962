// $dark: rgb(10, 22, 28);
// $light: rgb(230, 230, 240);
// $teal: rgb(45, 65, 80);
// $blue: rgb(65, 205, 245);
// $grey: rgb(100, 100, 100);
// $wcag-grey: #808080;
// $red: rgb(255, 0, 85);
// $green: rgb(0, 255, 120);

@import '../../../styles/vars.scss';

// Dropdown

.item-dropdown {
    position: relative;
}

.item-treenav-dropdown a {
    color: $blue;
}

.items-add-edit-container {
    .item-treenav-dropdown {
        position: relative;

        .loading-area {
            position: absolute;
            margin: 0;
            background: $dark;
            z-index: 9;
            opacity: 80%;
            height: 100%;
        }
    }
}

.item-treenav-dropdown {
    display: block;
    z-index: 5;
    font-size: 18px;
    padding: 12px 0;
    top: 35px;
    left: 0;
    flex-flow: column;
    line-height: 1.5;

    &.open {
        display: flex;
    }

    .nav-root {
        display: flex;
        justify-content: space-between;
        color: $blue;
        // cursor: pointer;

        >.l-1 {
            font-size: 20px;
            font-weight: 600;

            &:last-child {
                border-bottom: 0;
            }
        }
    }

    .l-2 {
        letter-spacing: 0.5px;
        padding: 0 20px 0px 5px;
    }

    .l-3 {
        padding: 0 5px;
        line-height: 1.6;
    }
}

.item-divider {
    background-color: $teal;
    height: 1px;
    margin: 12px 0;
    width: 100%;
}

.item-nav-node {
    transition: all 100ms;
    position: relative;
    overflow: hidden;
    display: block;

    &:hover {
        /*&:after {
      background-color: $blue;
      border-radius: 50%;
      content: "";
      display: block;
      height: 8px;
      position: absolute;
      left: -4px;
      top: 8px;
      width: 8px;
    }*/
    }

    &.active {
        border-left: 3px solid $blue;
    }

    >svg {
        fill: $blue;
        margin-left: 5px;
        transform: rotate(180deg) translateY(2px);
    }
}

.item-menu-line {
    display: flex;
    align-items: center;
    cursor: pointer;

    &.active-menu {
        background-color: #1e2e34;
    }
}

.item-menu-line:hover {
    background-color: #1e2e34;
}

.item-menu-line:hover .admin-plant-item-row-action-container {
    display: flex;
    flex-flow: row;
}

.admin-plant-item-row-action-container {
    margin-left: auto;
    display: none;
}

.nav-root:hover .admin-plant-item-row-action-container {
    display: flex;
    flex-flow: row;
}

.dotted {
    width: 24px;
    height: 24px;
}

.quick-add {
    display: flex;
    width: 24px;
    height: 24px;
    margin-right: 3px;
    align-items: center;
    justify-content: center;
}

.nav-root .admin-plant-item-row-action-container .dotted {
    margin-top: 3px;
}

.dotted:hover .admin-plant-item-row-actions {
    display: flex;
    width: 150px;
    background-color: $dark;
    z-index: 9999999;
    position: relative;
    top: -10px;
    left: -10px;
    flex-flow: column;
    flex-wrap: wrap;
    padding: 10px;
    border: 1px solid $teal;
}

.item-search-input {
    border-style: hidden;
    border-bottom: 1px solid #325663;
    background-color: #1e2e34;
    text-align: center;
    color: $light;
    font-family: 'Rajdhani', sans-serif;
    text-transform: uppercase;
    width: 84%;
    font-size: 18px;
    height: 2.5em;
    margin-bottom: 16px;
}

.item-search-nav {
    border-bottom: none;
    text-align: unset;
    padding-left: 20px;
    text-transform: unset;
}

.item-search-input:focus {
    outline-width: 0;
}

.item-search-input::placeholder {
    color: $light;
}

.item-nav-caret {
    padding: 5px;
    color: $blue;
}

.item-nav-expand {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30px;
    margin-right: 20px;
}

.item-nav-expander {
    path {
        stroke: $blue;
    }

    // padding: 0 4px 0 11px;
}

.item-nav-collapser {
    path {
        stroke: $blue;
    }

    // padding-left: 8px;
}

.left-nav-wrapper {
    display: flex;
    align-items: center;
}

.left-nav-wrapper-icon {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

@media only screen and (max-width: 600px) {
    .item-treenav-dropdown {
        width: 100%;
        overflow: hidden;

        .item-nav-node {
            white-space: normal;
        }
    }

    .item-nav-pad {
        padding: 0 20px 0 0;
    }
}

.topic-indicator {
    margin-left: auto;
    margin-right: 20px;
}

.item-menu-line:hover .topic-indicator {
    display: none;
}


.nav-root:hover .topic-indicator {
    display: none;
}