.tank-gauge-wrapper {
    align-items: flex-end;
    background-color: #2c4147;
    display: flex;
    flex-flow: row wrap;
    height: 52px;
    width: 52px;

    .tank-gauge-level {
        align-content: space-between;
        background: linear-gradient(rgba(65, 200, 240, 0.6), rgba(65, 200, 240, 0));
        display: flex;
        flex-flow: row wrap;
        width: 52px;
    }

    .tank-gauge-level__waterline {
        transform: translateY(-1px);
    }

    .tank-gauge-product {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCA2IDYiPjx0aXRsZT5kb3Q8L3RpdGxlPjxyZWN0IHdpZHRoPSI2IiBoZWlnaHQ9IjYiIG9wYWNpdHk9IjAiLz48Y2lyY2xlIGN4PSIzIiBjeT0iMyIgcj0iMSIgZmlsbD0iI2VjZWNlYyIvPjwvc3ZnPg==');
        background-position: center;
        height: 50%;
        width: 52px;
    }

    .component {
        align-items: flex-end;
        display: flex;
        flex-flow: row wrap;
        position: relative;
    }

    // Data

    .data-wrapper {
        display: block;
        margin-left: 10px;
        transform: translateY(9px);
    }

    .data-title__label {
        color: #ececec;
        font-size: 18px;
    }

    .data {
        display: flex;
        flex-flow: row nowrap;
    }

    .data-value__number {
        color: #ececec;
        font-size: 46px;
        font-weight: 500;
        line-height: 1;
        margin: 0;
        padding: 0;
    }
}
