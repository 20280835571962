@import '../../../styles/vars.scss';

// bar-gauge
.bar-gauge {
    position: relative;
    padding: 8px 0 5px 0;
    width: 100%;

    .bar-gauge-upload-status {
        position: absolute;
        top: -28px;
        right: 0;
        font-size: 15px;
        color: $wcag-grey;
    }

    .old-data {
        color: $yellow;
    }

    .bar-gauge-bar {
        background-color: $teal;

        height: 8px;
        position: relative;
        width: 100%;
    }

    .bar-gauge-number {
        display: block;
        min-height: 30px;
        width: 100%;

        > span {
            display: inline-block;
            font-size: 20px;
            font-weight: 500;
            transform: translateX(-50%);
        }
    }

    .bar-gauge-bar-progress {
        background-color: var(--color);
        height: 100%;
        width: var(--progress);
    }

    .bar-gauge-values {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        margin: 5px 0 0 0;
        letter-spacing: 0.5px;
        width: 100%;
    }

    .bar-gauge-bar-marker {
        background-color: var(--color);
        border-left: 3px solid $dark;
        border-right: 3px solid $dark;
        position: absolute;
        top: 0;
        bottom: 0;
        left: var(--at);
        width: 9px;
    }

    &.large {
        .bar-gauge-bar {
            height: 28px;
        }

        .bar-gauge-values {
            display: none;
        }
    }

    .time-since {
        //position: absolute;
        color: $yellow;
        //top: -6px;
        font-size: 13px;
        left: 0;
    }
}
