@import '../../../styles/styles.scss';
$inner: #2c4046;
$border: #24353b;

.buoy {
    display: flex;
    flex: 0 1 auto;
    margin: 0 auto;
    width: 130px;
    height: 130px;
    background-color: $inner;
    border-radius: 50%;
    border: 7px solid $border;
    justify-content: space-around;
    padding: 10px;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

.buoy svg {
    -webkit-animation: shake-bottom 10s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
    animation: shake-bottom 10s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
}

.weight {
    margin-top: 4px;
    font-weight: 500;
    cursor: pointer;
    color: white;
}
.increase {
    margin-top: -5px;
    color: $wcag-grey;
    cursor: pointer;
}

.buoy-selected {
    border-color: $blue;
}

@-webkit-keyframes shake-bottom {
    0%,
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
    }
    10% {
        -webkit-transform: rotate(4deg);
        transform: rotate(4deg);
    }
    20%,
    40%,
    60% {
        -webkit-transform: rotate(-8deg);
        transform: rotate(-8deg);
    }
    30%,
    50%,
    70% {
        -webkit-transform: rotate(8deg);
        transform: rotate(8deg);
    }
    80% {
        -webkit-transform: rotate(-4deg);
        transform: rotate(-4deg);
    }
    90% {
        -webkit-transform: rotate(4deg);
        transform: rotate(4deg);
    }
}

.buoy-dashboard-link {
    font-size: 16px;
    color: #fff;
    margin-left: 25px;
}

@keyframes shake-bottom {
    0%,
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
    }
    10% {
        -webkit-transform: rotate(4deg);
        transform: rotate(4deg);
    }
    20%,
    40%,
    60% {
        -webkit-transform: rotate(-8deg);
        transform: rotate(-8deg);
    }
    30%,
    50%,
    70% {
        -webkit-transform: rotate(8deg);
        transform: rotate(8deg);
    }
    80% {
        -webkit-transform: rotate(-4deg);
        transform: rotate(-4deg);
    }
    90% {
        -webkit-transform: rotate(4deg);
        transform: rotate(4deg);
    }
}
