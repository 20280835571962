@import '../../../styles/vars';

.admin-plant-add-edit-button-container {
    display: flex;
    justify-content: space-between;
}

.admin-plant-add-edit-form {
    position: relative;
    width: 100%;
    > h1 {
        // font-size: 27px;
        font-weight: 500;
        margin: 0;
        color: $light;
    }
}

.admin-plant-add-edit-form > div > label {
    color: $light;
}
.admin-plant-add-edit-form > div > input {
    background-color: $form-input;
}

.admin-plant-add-edit-form input {
    background-color: $form-input;
}

.react-select-custom > div {
    background-color: $form-input !important;
    color: $light !important;
}

.react-select-custom > div > div {
    // background-color: $form-input !important;
    color: $light !important;
}

.admin-plant-add-edit-form-geosuggest {
    text-decoration: none;
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    height: 3em;
    width: 100%;
    border-style: none;
    color: $light;
    font-size: 15px;
    /* margin-top: 1em; */
    justify-content: center;
    align-items: center;
    background-color: #0d171e;
    border: 1px solid #3f5e6a;
    border-radius: 0.1em;
    padding-left: 5px;
}

.admin-plant-add-edit-form-geosuggest::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $light;
    opacity: 1; /* Firefox */
}

.admin-plant-add-edit-form-geosuggest:-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $light;
}
.admin-plant-add-edit-form-geosuggest::-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $light;
}

// .geosuggest__input .admin-plant-add-edit-form-geosuggest {
//   color:$light;
// }

.admin-input-control {
    text-decoration: none;
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    height: 3em;
    width: 100%;
    border-style: none;
    color: $light;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    // border: 1px solid rgb(217, 237, 245);
    border-radius: 0.1em;
    padding-left: 5px;
}

.admin-form-select {
    text-decoration: none;
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    height: 3em;
    width: 100%;
    border-style: none;
    // color: #919191;
    color: $light;
    cursor: pointer;
    font-size: 15px;
    /* margin-top: 1em; */
    justify-content: center;
    align-items: center;

    // border: 1px solid #3F5E6A;
    // border-radius: 0.1em;
    padding-left: 5px;
    background: #1e2e34;
}

.admin-plant-submit-button {
    text-decoration: none;
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    height: 3em;
    width: 100%;
    border-style: none;
    color: $light;
    cursor: pointer;
    font-size: 15px;
    margin-top: 1em;
    justify-content: center;
    align-items: center;
    background-color: $form-dark;
    border: 1px solid $form-input;
    border-radius: 0.1em;
    &.cancel {
        border: 1px solid $blue;
    }
    &.save {
        margin-left: 80px;
        border: 1px solid $blue;
        color: $form-dark;
        background-color: $blue;
    }
}

.Select-menu-outer {
    top: auto;
    bottom: 100%;

    #react-select-3-input {
        color: #e6e6f0 !important;
        border-color: #e6e6f0 !important;
    }
    .css-1y1ur04-control {
        border-color: #e6e6f0 !important;
    }
}

.Select-menu-outer > div {
    top: auto;
    bottom: 100%;
}

.optimar__menu {
    color: #1e2e34;
}
