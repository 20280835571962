a .logo {
    width: 10em;
    height: 100%;
    padding-left: 1em;
    padding-right: 1em;
    margin: 0;
    display: inline-block;
}

@media only screen and (max-width: 600px) {
    a .logo {
        width: 85px;
        padding-left: 5px;
        padding-right: 1em;
    }
}
