@import '../../../styles/vars.scss';

.form-group {
    label {
        display: inline-block;
        padding-top: 20px;
        color: #e6e6f0;
    }

    .input-control {
        background: #1e2e34 !important;
        border: 0 !important;
        border-radius: 0 !important;
    }
}

.line-fieldset,
.group-fieldset {
    width: 600px;

    .delete-icon {
        display: block;
        margin-left: auto;
        margin-right: 0;
    }

    .fields {
        display: flex;

        .fields-column {
            flex: 50%;
        }

        .fields-column:first-child {
            margin-right: 10px;
        }
    }
}

.ctype-fieldset,
.childdp-fieldset,
.childmd-fieldset {
    .delete-icon {
        display: block;
        margin-left: auto;
        margin-right: 0;
    }
}

.child-data {
    width: 750px;
    padding-top: 10px;

    .fields {
        display: flex;

        .fields-column {
            flex: 50%;
        }

        .fields-column:first-child {
            margin-right: 10px;
        }
    }
}
