@import '../../../styles/vars.scss';

.dv-table {
    th,
    td {
        padding: 0 15px 0 15px;
        vertical-align: middle;
    }
    .dv-row {
        color: $blue;
        &:hover {
            background-color: $darkblue;
        }
        .dv-delete {
            cursor: pointer;
            text-align: center;

            svg {
                line {
                    stroke: $red;
                }
            }
        }
        .dv-edit {
            text-align: center;
            cursor: pointer;
            svg {
                line {
                    stroke: $blue;
                }
                polygon {
                    stroke: $blue;
                }
            }
        }
    }
}
