@import '../../../styles/vars.scss';

.global-search {
    background-color: #2c4147;
    border: 0;
    color: #e6e6f0;
    line-height: 1;
    font-family: Rajdhani, sans-serif;
    font-size: 18px;
    font-weight: 400;
    padding: 5px 5px 5px 40px;

    &:focus {
        outline: none;
        border-bottom: 1px solid #41cdf5;
    }

    &::placeholder {
        color: #bbb;
    }
}

.gt-header {
    display: flex;
    justify-content: space-between;
    color: $light;
    margin: 10px 0;

    .gt-header-left,
    .gt-header-center,
    .gt-header-right {
        display: flex;

        .faux-checkbox {
            margin: 0 10px;
        }
    }

    .gt-header-count {
        margin: 0 10px 0 0;
    }

    .gt-header-button {
        color: $blue;
        font-size: 18px;
        cursor: pointer;
    }

    .header-filter-info {
        display: flex;
        font-size: 18px;

        .current-filter-label {
            margin-right: 10px;
            margin-top: 2px;
        }
    }

    .header-filter-selection {
        font-size: 16px;
        background: $grey;
        padding: 2px 4px;
    }

    .header-clear-filter {
        font-size: 20px;
        margin-top: 2px;
        padding-left: 8px;
        margin-right: 1rem;
        color: $blue;
        cursor: pointer;
    }
}

.generictable {
    .gt-filter-icon {
        stroke: $blue;
        fill: $blue;
        height: 20px;
        padding-top: 5px;
        width: 20px;
    }
    position: relative;
    width: 100%;
    // margin: 0px 10px;
    color: white;
    border-collapse: collapse;
    .gt-tr {
        border-bottom: 1px solid $teal;
        &:hover {
            background-color: #1e2e34;
        }
        &.clickable {
            cursor: pointer;
        }
        .gt-td {
            text-align: left;
            padding: 6px 0;
            font-size: 18px;

            .clickable {
                cursor: pointer;
            }
        }
        .gt-td-overflow-hidden {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    @media screen and (max-width: 600px) {
        thead {
            display: none;
        }
        td {
            display: flex;
            word-break: break-all;
        }
        .gt-tr {
            :first-child {
                margin-top: 5px;
            }
        }

        td::before {
            content: attr(label);
            font-weight: bold;
            width: 120px;
            min-width: 120px;
        }
    }
    .link {
        color: $blue;
    }

    .gt-spinner-container {
        position: absolute;
        background-color: $dark;
        opacity: 0.7;
        top: 15%;
        width: 100%;
        min-height: 500px;
        height: 85%;
        .gt-spinner {
            opacity: 1;
            margin: 0;
        }
    }

    .gt-thead {
        th {
            text-align: left;

            span {
                font-weight: 500;
                font-size: 18px;
            }

            input {
                padding: 8px 0 8px 4px;
                font-size: 14px;
            }
        }
    }

    input,
    select,
    textarea,
    .optimar__control {
        background: #1e2e34 !important;
        border: 0 !important;
        border-radius: 0 !important;
        -webkit-appearance: none;
        color: $light;
    }
}

.gt-pagination {
    @media screen and (max-width: 600px) {
        display: flex;
        justify-content: space-between;
        .gt-pagenum {
            display: none !important;
        }
        .gt-show-count {
            display: none !important;
        }
        div.gt-btn-group {
            display: flex;
            flex-direction: row;
            flex: 0 0 50%;
            margin-bottom: 20px;

            button {
                min-width: unset !important;
                width: 50px;
            }
        }
        :nth-child(3) {
            justify-content: flex-end;
        }
    }

    input,
    select,
    textarea,
    .optimar__control {
        background: #1e2e34 !important;
        border: 0 !important;
        border-radius: 0 !important;
        -webkit-appearance: none;
        color: $light;
    }

    .gt-pagenum {
        display: flex;
        align-items: center;

        input {
            height: 100%;
            width: 40px;
        }

        @media screen and (max-width: 600px) {
            height: 100%;
            width: 70%;
        }
    }

    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;

    &.center {
        justify-content: center;
    }

    .gt-page-settings {
        .gt-page-goto {
            margin: 0 5px 0 5px;
            display: flex;
            justify-content: space-between;
            width: auto;
        }

        .gt-show-count {
            display: flex;
            align-items: center;
            margin-left: 1em;
            div {
                display: flex;
                align-items: center;
                margin-right: 6px;
            }
        }
        display: flex;
        justify-content: space-around;

        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }
    .gt-btn-group {
        button {
            width: 100px;
        }
    }

    .btn {
        color: $light;
        text-decoration: none;
        padding: 7px 15px;
        border-radius: 2px;
        font-family: 'Rajdhani', sans-serif;
        font-size: 17px;
        transition: all 100ms;
        border: 1px solid $form-dark;
        cursor: pointer;
        font-weight: 500;
        outline: none;
        min-width: 115px;
        height: 36px;
    }

    .btn-grow {
        flex-grow: 1;
    }

    .btn-blue {
        background-color: $button-blue-bg-normal;
        border: 1px solid $button-blue-border;

        &:hover {
            background-color: $button-blue-bg-hover;
        }

        &:active {
            background-color: $button-blue-bg-active;
        }

        &:disabled {
            background-color: $button-gray-bg;
            color: $button-gray-text;
            border: 1px solid $button-gray-border;
        }
    }
}

.gtw-ctx {
    position: relative;
    text-align: center;

    .gtw-ctx-icon {
        padding: 0 8px;
        cursor: pointer;
    }

    .gtw-ctx-menu {
        text-align: initial;
        z-index: 100;
        position: absolute;
        top: 25px;
        border: 1px solid $teal;
        border-radius: 3px;
        transform: translateX(-92%);
        box-shadow: 0 0.5rem 1rem #060b0e;
        //padding: 10px;
        background-color: $dark;
        white-space: nowrap;

        .gtw-ctx-opt {
            cursor: pointer;
            color: $blue;
            padding: 7px 7px;
            width: auto;
            &:hover {
                background-color: #1e2e34;
            }
        }
    }
}
