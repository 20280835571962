.settings-table {
    display: table;
    margin: 0;
    padding: 0;
    border-collapse: collapse;
}

.settings-table tr {
    border-bottom: 1px solid rgb(128, 128, 128);
}

.settings-table td {
    line-height: 27px;
    border-left: none;
    border-right: none;
    color: #ffffff;
    font-size: 18px;
    padding: 6px 0;
}

.table-header th {
    text-align: left;
    padding: 0;
}

.table-header span {
    color: #ffffff;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    font-family: 'Rajdhani', sans-serif;
    height: 30px;
}

.email-column {
    text-align: right;
    width: 50%;
}
