@import '../../../styles/vars.scss';

.menu {
    position: fixed;
    background: $form-dark;
    top: 70px;
    width: 200px;
    right: 40px;
    border: 1px solid #2c4147;
    overflow: hidden;
    z-index: 5;
    box-shadow: -2px 2px 20px 1px #000000;
    border-radius: 4px;
}

.menubutton {
    width: 100%;
    height: 3em;
    margin: 0;
    color: $blue;
    font-family: 'Rajdhani', sans-serif;
    background: $form-dark;
    border-style: none;
    z-index: 5;
    &:hover {
        background: #101b24;
        cursor: pointer;
    }
}

.username {
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid #2c4147;
    font-size: 14px;
    display: flex;
    flex-direction: column;
}

.logout-button {
    border-top: 1px solid #2c4147;
}
