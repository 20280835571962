.viewlist-title {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;

    .title {
        font-weight: bold;
    }

    span {
        color: #fff;
        padding-left: 5px;
    }

    .data {
        display: flex;
    }
}