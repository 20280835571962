@import '../../../styles/vars.scss';

.slider-wrapper {
    margin: 14px 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.slider {
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #4caf50;

    &.warn {
        background: $yellow;
    }

    &.error {
        background: $red;
    }
}

.slider-label {
    flex: 0 0 25%;
    max-width: 25%;
}
