@import '../../../styles/vars.scss';

.dashboard-edit-pane {
    .widget-form {
        width: 100% !important;
        min-width: 450px;

        label input {
            visibility: hidden;
            /* <-- Hide the default checkbox. The rest is to hide and allow tabbing, which display:none prevents */
            display: block;
            height: 0;
            width: 0;
            position: absolute;
            overflow: hidden;
        }

        .formula-link {
            color: $blue;
            cursor: pointer;
        }

        .formula-list {
            border: 1px solid $teal;
            border-radius: 5px;
            padding: 5px;
            display: flex;
            max-height: 300px;
            overflow-y: auto;
            flex-direction: column;
            .formula {
                display: flex;
                align-items: center;
                .formula-name {
                    display: flex;
                    flex: 0 1 20%;
                    flex-direction: row;
                    color: $blue;
                    cursor: pointer;
                }
            }
        }

        label span {
            /* <-- Style the artificial checkbox */
            height: 10px;
            width: 10px;
            border: 1px solid grey;
            display: inline-block;
        }

        input[type='checkbox'] {
            visibility: visible;
            width: 20px;
            height: 20px;
            vertical-align: middle;
            position: relative;
            bottom: 1px;
            margin-left: 10px;
        }

        input[type='checkbox'] {
            -webkit-appearance: checkbox;
        }

        input:invalid {
            box-shadow: 0 0 5px 1px red;
        }

        .component-setting {
            display: flex;
            flex-flow: row nowrap;

            .component-fields {
                width: 100%;
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;

                .form-group {
                    flex: 0 0 48%;
                }
            }

            .optimar__control {
                height: 2.8em;

                .Select-placeholder {
                    line-height: 26px;
                }

                .Select-value {
                    line-height: 26px !important;

                    .Select-value-label {
                        line-height: 26px;
                    }
                }

                .Select-input {
                    height: 26px;
                }
            }
        }

        .small-btn {
            width: 126px;
            height: 30px;
            font-size: 14px;
        }
    }

    .multiple-inline {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        input {
            width: 98%;
        }
    }

    .available-widgets {
        width: 450px;
        padding: 30px;

        span {
            display: block;
            font-size: 18px;
            color: $light;
            padding-top: 8px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;

            li {
                margin: 5px;
                padding: 10px;
                border: 1px solid $teal;
                cursor: pointer;
                justify-content: space-between;
                display: flex;
                flex-flow: column;
            }
        }
    }
}
