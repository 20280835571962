body,
html {
    background-color: #0d171e;
    color: #919191;
    margin: 0;
    padding: 0;
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.title {
    display: flex;
}
