$dark: rgb(10, 22, 28);
$light: rgb(230, 230, 240);
$teal: rgb(45, 65, 80);
$blue: rgb(65, 205, 245);
$grey: rgb(100, 100, 100);
$darkgrey: #333333;
$darkgrey-faded: rgba(51, 51, 51, 0.2);
$wcag-grey: #808080;
$red: rgb(255, 0, 85);
$red-faded: rgba(255, 0, 85, 0.2);
$green: #00ff78;
$yellow: rgb(201, 181, 79);
$yellow-faded: rgba(201, 181, 78, 0.2);
$darkblue: #141e28;
$blue-faded: rgba(65, 205, 245, 0.8);

$blue-gradient: linear-gradient(rgba(65, 200, 240, 0.8), rgba(65, 200, 240, 0.1));
$dark-gradient: linear-gradient(rgba(51, 51, 51, 0.8), rgba(10, 22, 28, 0.1));

$form-dark: #0d171e;
$form-input: #1e2e34;

// Buttons
$button-red-border: #da3a3a;
$button-red-bg-normal: #221b21;
$button-red-bg-hover: #411f25;
$button-red-bg-active: #73282c;

$button-yellow-border: #c9b54e;
$button-yellow-bg-normal: #212823;
$button-yellow-bg-hover: #3d3e2a;
$button-yellow-bg-active: #6b6636;

$button-blue-border: #41c9f0;
$button-blue-bg-normal: #122a34;
$button-blue-bg-hover: #1a4453;
$button-blue-bg-active: #267087;

$button-gray-border: #444444;
$button-gray-bg: #1a2329;
$button-gray-text: #9f9f9f;

// :export is magic sauce for when you wish to import scss variables into js code. -> Example in SizeDistributionChart.js
:export {
    dark: $dark;
    light: $light;
    teal: $teal;
    blue: $blue;
    grey: $grey;
    darkgrey: $darkgrey;
    darkgreyfaded: $darkgrey-faded;
    wcag-grey: $wcag-grey;
    red: $red;
    redfaded: $red-faded;
    green: $green;
    yellow: $yellow;
    yellowfaded: $yellow-faded;
    darkblue: $darkblue;
    bluefaded: $blue-faded;
    bluegradient: $blue-gradient;
    darkgradient: $dark-gradient;
    formdark: $form-dark;
    forminput: $form-input;
    buttonredborder: $button-red-border;

    buttonredbgnormal: $button-red-bg-normal;
    buttonredbghover: $button-red-bg-hover;
    buttonredbgactive: $button-red-bg-active;

    buttonyellowborder: $button-yellow-border;
    buttonyellowbgnormal: $button-yellow-bg-normal;
    buttonyellowbghover: $button-yellow-bg-hover;
    buttonyellowbgactive: $button-yellow-bg-active;

    buttonblueborder: $button-blue-border;
    buttonbluebgnormal: $button-blue-bg-normal;
    buttonbluebghover: $button-blue-bg-hover;
    buttonbluebgactive: $button-blue-bg-active;

    buttongrayborder: $button-gray-border;
    buttongraybg: $button-gray-bg;
    buttongraytext: $button-gray-text;
}
