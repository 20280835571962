@import '../../styles/vars.scss';
.widget-config-info-tooltip {
    .infoicon {
        fill: $blue-faded;
        // position: absolute;
        // right: 2px;
        // top: 2px;
        //z-index: 100;
    }
}
