.login {
    font-size: 14px;
    display: flex;
    justify-content: center;
}

.login-content {
    background-image: url('../../images/bg-login.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100vw;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.login-panel {
    width: 20em;
    height: 15em;
    text-align: center;
    background-color: rgba(13, 23, 30, 0.9);
    border: 1px solid #325663;
    color: #41c9f0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.4em;
    margin-bottom: 5em;
}

.optimar-logo-login {
    width: 15em;
    height: auto;
    margin-bottom: 2em;
}
