.test-area {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 15px;
    .btn {
        margin: 0;
        margin-left: 10px;
        margin-top: 40px;
    }

    .testresult {
        width: 100%;

        &.red {
            color: red;
        }
    }
}
