.rmsc {
    --rmsc-main: #4285f4 !important;
    --rmsc-hover: #0b1920 !important;
    --rmsc-selected: #122a34 !important;
    --rmsc-border: #333333 !important;
    --rmsc-gray: #91999d !important;
    --rmsc-bg: #111d24 !important;
    --rmsc-h: 32px !important;
    color: white !important;
}

.rmsc .search input:focus {
    color: white !important;
}

.item {    
    align-content: center !important;
    padding: 0px 5px !important;
}

.item-multi-select {    
    margin: 4px 0px 0px 0px !important;
}

