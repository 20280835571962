.transfer-operation-edit {
    width: 50%;
    padding: 10px;
    padding-left: 50px;
    padding-top: 5px;
}

.transfer-operation-edit> h3 {
    font-weight: 500 !important;
}

.transfer-operation-edit> h2 {
    margin-bottom: 0px !important;
}

.transfer-operation-edit-header {
    display: flex;
    width: 100%;
    justify-content: space-between
}

.transfer-operation-edit .form-container {
    display: block !important;
    padding: 0px 20px 40px 20px;
    height: 400px;
}

.transfer-operation-edit .form-container form {
    padding-bottom: 50px;
}