/* autoprefixer grid: on */
.plantlist {
    margin: auto;
    display: flex;
    flex-direction: column;
}

.plantlist-accountmanager {
    display: flex;
    flex-direction: column;
}

@media all and (-ms-high-contrast: none) {
    .plantlist {
        width: auto;
    }
}

.inactive {
    color: #919191;
}

.plantlist-row {
    display: flex;
    flex-direction: row;
    justify-content: left;
    min-height: 4.5em;
    align-items: center;
    font-size: 20px;
    text-decoration: none;
    color: #919191;
    background-color: #0d171e;
    border-bottom: 1px solid #2c4147;
    cursor: pointer;
    width: auto;
}

.planttype {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex: 0 1 50px;
    height: 100%;
}

.plantlist-cell {
    display: flex;
    flex: 1 0 20%;
    justify-content: left;
    height: 100%;
    align-items: center;
    color: #41cdf5;
    font-size: 20px;
    font-weight: 600;
}

.plantlist-cell.subscribe {
    flex: 0 0 20px !important;
}

.plantlist-name-cell {
    display: flex;
    flex: 0 3 1000px;
    justify-content: left;
    height: 100%;
    align-items: center;
}

.plantlist-error {
    border-left: 2px solid red;
}

.cell-image-text {
    display: flex;
    justify-content: flex-start;
    width: auto;
    padding-left: 2em;
    padding-right: 2em;
}

.cell-metadata {
    color: #325663;
    font-size: 1em;
    font-weight: lighter;
}

@media only screen and (max-width: 600px) {
    .cell-metadata {
        color: #325663;
        font-family: Rajdhani;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0.33px;
        line-height: 12px;
    }
    .cell-image-text {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        padding-left: 0.5em;
        padding-right: 0em;
    }
}

.goto-plant-location {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 4em;
    height: 3em;
    border: 1px solid #3f5e6a;
    border-radius: 0.4em;
    margin-right: 1em;
    z-index: 2;
}

.plantlist-cell-go {
    display: flex;
    flex: 0 1 50px;
    justify-content: space-evenly;
    height: 100%;
    align-items: center;
    color: #41c9f0;
}
/* EDGE */
@supports (-ms-ime-align: auto) {
    .plantlist-cell-go {
        justify-content: space-around;
        flex: 0 2 100px;
    }

    .plantlist-cell .cell-image-text {
        text-align: center;
        justify-content: space-around;
    }
}

/* IE */
@media all and (-ms-high-contrast: none) {
    .plantlist-cell-go span {
        margin: 0 auto;
    }

    .plantlist-cell .cell-image-text {
        text-align: center;
        justify-content: space-around;
    }

    .cell-image-text img {
        width: 25px;
        height: 100%;
    }
}

.plantlist-cell-go .inactive {
    color: #575757;
}

.cell-text-grey {
    color: #575757;
    font-weight: lighter;
}

.type-name-row {
    display: flex;
    height: 100%;
    flex: 0 3 1100px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .plantlist-cell {
        font-size: 16px;
    }
    .cell-image-text {
        padding-left: 1em;
        padding-right: 1em;
    }
}

@media only screen and (max-width: 600px) {
    .plantlist-row {
        flex-flow: row wrap;
        padding-top: 14px;
        padding-bottom: 14px;
    }
    .plantlist-row .plantname {
        flex: 1 1 100px;
    }
    .plantlist-row .plantstatus {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
    }
    .type-name-row {
        display: flex;
        height: 55px;
        width: 100%;
        flex: 0 3 1300px;
    }
    .info-row {
        display: flex;
        flex-direction: row;
        height: 95px;
        width: 100%;
    }
    .cell-text-grey {
        color: rgb(145, 145, 145);
        font-weight: lighter;
        font-size: 10px;
        letter-spacing: 0.33px;
    }
    .plantlist-cell-go {
        border-bottom: 1px solid #2c4147;
    }
    .plantlist-cell-go-arrow {
        width: 10px;
        height: 10px;
    }
    .plantlist-header {
        width: 0px;
        height: 0px;
        overflow: hidden;
    }
    .plantlist {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: unset;
        max-width: inherit;
    }
    .plantlist-cell {
        display: flex;
        flex: 0 1 600px;
        justify-content: flex-start;
        height: 100%;
        align-items: center;
        color: #41c9f0;
        font-size: 20px;
        width: 50%;
        position: relative;
    }
    .plantlist-cell.subscribe {
        justify-content: flex-end;
        padding-right: 10px;
    }

    .plantlist-name-cell {
        display: flex;
        flex: 0 3 500px;
        justify-content: space-evenly;
        border-right: 1px solid #2c4147;
        border-bottom: 1px solid #2c4147;
        align-items: flex-start;
        padding-left: 1em;
        flex-direction: column;
        font-size: 17px;
        letter-spacing: 0.34px;
    }
    .cell-image-text {
        flex: 0 1 200px;
        font-size: 14px;
        padding-top: 1em;
    }
    .cell-name {
        font-size: 12px;
        color: #919191;
        letter-spacing: 0.27pt;
    }
    .cell-name {
        font-size: 8px;
        color: #919191;
    }
    .cell-name-info {
        font-size: 8px;
        color: #919191;
        position: absolute;
        top: 10px;
        left: 10px;
        letter-spacing: 0.27px;
    }

    .map-toggle {
        display: flex;
        margin-left: 10px;
        border: 1px solid #2d4150;
        border-radius: 0.4em;
        padding: 3px;
        flex: 0 0 30px;
    }

    .list-search {
        display: flex;
        justify-content: space-between;
        height: 40px;
    }

    .search-plant {
        height: 30px;
        margin-bottom: 1em;
        -webkit-appearance: none;
    }

    .search-plant {
        padding-bottom: 0px;
    }
}

.search-plant {
    background-color: transparent;
    -webkit-appearance: none;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #2d4150;
    color: #e6e6f0;
    line-height: 1;
    font-family: Rajdhani, sans-serif;
    font-size: 20px;
    font-weight: 400;
    padding: 0 5px 10px 61px;
    width: 100%;
}

.search-plant:focus {
    outline: none;
    border-bottom: 1px solid #41cdf5;
}

.search-plant::placeholder {
    color: #bbb;
}

.list-search {
    position: relative;
    width: auto;
}

.search-icon {
    stroke: '#ff00ff';
    position: absolute;
    top: 8px;
    left: 12px;
}

.plantlist-container {
    width: auto;
}
