@import '../../styles/vars.scss';

.content-grid-dash {
    width: 100%;
}

.dashboard-wrapper {
    box-sizing: border-box;
    color: $light;
    font-family: 'Rajdhani', sans-serif;
    font-size: 17px;
    font-weight: normal;
    line-height: 1;
    margin: 0;
    padding: 0;

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    .dashboard-widget-wrapper {
        display: flex;
        width: auto;
        flex-wrap: wrap;
    }

    .disabled-widget {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(10, 22, 28, 0.8);
        color: $light;
        font-size: 35px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }

    .dashboard-edit-wrapper {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

        // &.edit-dashboard {
        //     position: -webkit-sticky;
        //     position: sticky;
        //     background: #0D171E;
        //     top:100px;
        // }

        .btn {
            margin-top: 0 !important;
            margin-left: 5px;
        }

        .new-dashboard-exists {
            color: $yellow;
            padding: 8px;
        }
    }

    &.admin-dashboard {
        .dashboard-draft {
            color: $yellow;
        }

        .add-widget-inline {
            width: 1px;
            padding: 0 10px;
            background: none;
            text-align: center;

            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px dashed $grey;
            width: 1px;
            overflow: visible;
            cursor: pointer;

            .fa {
                display: block;
                font-size: 20px;
                z-index: 2;
            }
        }

        .inline-first-add {
            display: flex;
            height: 130px;
            width: 100%;
            align-items: center;
            justify-content: space-around;
            font-size: 24px;
            border: 1px dashed $grey;

            .fa {
                font-size: 24px;
                margin-left: 20px;
                display: block !important;
            }

            .btn {
                margin-top: 0;
            }
        }

        .add-row-inline {
            height: 1px;
            width: 100%;
            text-align: center;
            border: none;

            .fa {
                display: none;
            }
        }

        .active-move {
            background: $darkblue;
        }

        .add-row-inline {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px dashed $grey;
            height: 30px;
            overflow: visible;
            cursor: pointer;

            .fa {
                display: block;
                font-size: 20px;
                z-index: 2;
                margin-left: -7px;
            }
        }

        h2 {
            margin: 0;
        }

        .place-widget-inline {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px;
            overflow: visible;
            cursor: pointer;
            border: 1px $grey dashed;
            font-size: 30px;
            color: $grey;

            &:hover {
                background: $grey;
                color: $light;
            }
        }

        .move-widget {
            position: absolute;
            right: 7px;
            top: 7px;

            .fa {
                font-size: 18px;
            }
        }
    }

    .container {
        margin: 0 auto;
        max-width: 1600px;
    }

    .full-container {
        display: flex;
        flex-flow: row wrap;
    }

    main {
        flex: 1 0 auto;
        padding: 0 30px;
    }

    .pull-right {
        margin-left: auto;
    }

    .b-l {
        border-left: 1px solid $teal;
    }

    .b-r {
        border-right: 1px solid $teal;
    }

    .b-t {
        border-top: 1px solid $teal;
    }

    .p-t-40 {
        padding-top: 30px;
    }

    .p-b-30 {
        padding-bottom: 30px;
    }

    .m-b-10 {
        margin-bottom: 10px;
    }

    // Buttons
    .buttons {
        display: flex;
        flex-flow: row wrap;

        > .btn {
            margin-right: 20px;
        }
    }

    // .btn {
    //     border-style: solid;
    //     border-radius: 3px;
    //     border-width: 1px;
    //     background-color: transparent;
    //     color: $light;
    //     cursor: pointer;
    //     font-family: inherit;
    //     font-size: 18px;
    //     font-weight: 600;
    //     outline: none;
    //     padding: 7px 15px;
    //     transition: all 100ms;
    // }
    // .btn-blue {
    //     border-color: $blue;
    //     &:hover {
    //         background-color: $blue;
    //     }
    // }
    // .btn-red {
    //     border-color: $red;
    //     &:hover {
    //         background-color: $red;
    //     }
    // }

    // Site header
    .site-header {
        align-items: center;
        border-bottom: 1px solid $teal;
        display: flex;
        height: 73px;
        width: 100%;
    }

    .top-nav {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
    }

    .top-nav-link {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        height: 100%;
        padding: 0 20px;

        > .nav-icon {
            fill: $blue;
        }
    }

    .site-logo {
        padding: 10px 20px;
    }

    // Page header
    .page-header {
        align-items: center;
        flex: 0 0 100%;
        display: flex;

        > h1 {
            font-weight: 500;
            margin: 0;
        }
    }

    .page-header-title {
        align-items: center;
        margin-left: 30px;
        display: flex;

        > .page-header-icon {
            height: 30px;
            width: 30px;
            margin-right: 20px;

            > svg {
                height: 100%;
                width: 100%;
            }
        }

        > h1 {
            font-size: 27px;
            font-weight: 500;
            margin: 0;
        }
    }

    .page-breadcrumb {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        padding: 30px 0 5px 0;
    }

    .breadcrumb-divider {
        margin: 0 10px;
    }

    // List
    .rich-list {
        display: block;
        width: 100%;
        padding-top: 22px;

        .widget-wrapper {
            display: flex;
            flex-flow: row wrap;
            width: 100%;
            flex: 1 0 320px;

            @media only screen and (max-width: 600px) {
                flex: none;
            }
        }
    }

    .list-items-header {
        align-items: center;
        border-bottom: 1px solid $teal;
        display: flex;
        flex-flow: row wrap;
        width: 100%;
    }

    .list-item {
        align-items: center;
        border-bottom: 1px solid $teal;
        display: flex;
        font-size: 20px;
        flex-flow: row wrap;
        padding: 0;
        width: 100%;

        &.expanded {
            border-bottom: 0;
        }
    }

    .rich-list-non-clickable {
        background-color: rgba($teal, 0);
        padding: 12px 40px;
        flex: 0 1 320px;
        transition: all 150ms;
        max-width: 100%;
        display: flex;
        position: relative;
        // align-items: flex-end;

        @media only screen and (max-width: 600px) {
            padding: 20px 0;
        }

        &.two-zones {
            flex: 0 1 640px;
        }

        &.three-zones {
            flex: 0 1 960px;
        }

        &.full-zones {
            flex: 0 1 100%;
        }

        &.text {
            position: relative;
            padding: 0;
            min-height: 105px;
        }

        .histogram-header {
            display: flex;
            justify-content: space-between;

            h3 {
                padding-left: 30px;
            }
        }
    }

    .list-expand-toggle {
        display: inline-block;

        &.expanded {
            > svg {
                transform: rotate(180deg);
            }
        }

        > h4 {
            display: inline-block;
            vertical-align: middle;
        }

        > svg {
            display: inline-block;
            fill: $blue;
            margin-left: 4px;
            vertical-align: middle;
        }
    }

    .rich-list-expand-area {
        background-color: rgba($teal, 0.15);
        display: flex;
        flex-flow: row wrap;
        padding: 16px 0;
        position: relative;
        overflow: hidden;
        border-left: 1px solid #2d4150;
        border-right: 1px solid #2d4150;
        border-top: 1px solid #2d4150;

        .histogram-header {
            margin-left: 40px;
        }

        .close-histogram {
            color: $blue;
            cursor: pointer;
            font-size: 22px;
            position: absolute;
            top: 20px;
            right: 20px;
        }
    }

    .rich-list-tools {
        flex: 0 0 200px;
    }

    .widget-bar-chart {
        width: 100%;
        height: 110px;
    }

    .note-text {
        margin-left: 170px;
        max-width: 650px;
    }

    .list-item-expandable {
        border-bottom: 1px solid $teal;
        padding: 15px 0 25px 0;
    }

    .list-item-date {
        flex: 0 1 170px;
    }

    .list-item-title {
        flex: 0 1 350px;

        @media only screen and (max-width: 600px) {
            flex: 0 1 350px;
        }
    }

    .list-item-person {
        flex: 0 1 300px;
    }

    .list-item-status {
        flex: 0 1 150px;
    }

    .list-item-text {
        flex: 0 1 20%;
    }

    // List header
    .main-header {
        display: inline-block;

        > h2 {
            display: inline-block;
        }

        > a {
            display: inline-block;
        }
    }

    .main-header-action {
        padding: 0 20px;
        font-weight: 400;
        font-size: 20px;
    }

    // Rich list
    .rich-list {
        display: block;
        width: 100%;

        &.half {
            width: 50%;
            min-width: 500px;
        }

        border-bottom: 1px solid #2d4150;
    }

    .rich-list-item {
        align-items: flex-start;
        //border-bottom: 1px solid $teal;
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        min-height: 105px;
        position: relative;
    }

    .rich-list-icon {
        flex: 0 0 65px;
    }

    .rich-list-text {
        flex: 0 0 25%;
        padding: 10px 40px 10px 0;
    }

    .rich-list-title {
        flex: 0 0 200px;
        padding: 10px 40px 10px 0;
        position: absolute;
        top: -34px;
        left: 0px;
    }

    .list-search {
        position: relative;
    }

    .list-search-input {
        background-color: transparent;
        border-top: 0;
        border-right: 0;
        border-bottom: 1px solid $teal;
        border-left: 0;
        color: $light;
        line-height: 1;
        font-family: 'Rajdhani', sans-serif;
        font-size: 20px;
        font-weight: normal;
        padding: 0 5px 10px 61px;
        width: 100%;

        &::placeholder {
            color: #bbb;
        }

        &:focus {
            outline: none;
            border-bottom: 1px solid $blue;
        }
    }

    .input-icon {
        fill: $light;
        position: absolute;
        top: 2px;
        left: 10px;
    }

    // SVG
    .position-yes {
        > .line {
            fill: none;
            stroke: $blue;
        }
    }

    .position-no {
        > .line {
            fill: none;
            stroke: $grey;
        }
    }

    .position-error {
        > .line {
            fill: none;
            stroke: $red;
        }
    }

    .position-uploading {
        > .line {
            fill: none;
            stroke: $green;
        }
    }

    .cloud-yes {
        > .plant {
            fill: none;
            stroke: $blue;
        }
    }

    .cloud-no {
        > .plant {
            fill: none;
            stroke: $grey;
        }
    }

    .cloud-uploading {
        > .plant {
            fill: none;
            stroke: $green;
        }
    }

    .cloud-error {
        > .plant {
            fill: none;
            stroke: $red;
        }
    }

    // Overview
    .ship {
        fill: $blue;
    }

    .ship-blueprint {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        height: 700px;
    }

    // Global search
    .global-search {
        align-items: center;
        flex: 1 1 auto;
        position: relative;
        display: flex;
        flex-flow: row wrap;

        > svg {
            top: 26px;
            margin-left: 10px;
        }
    }

    .global-search-input {
        background-color: transparent;
        border: 0;
        color: $light;
        height: 100%;
        line-height: 1;
        font-family: 'Rajdhani', sans-serif;
        font-size: 20px;
        font-weight: normal;
        padding: 0 0 0 60px;
        transition: all 100ms;
        width: 100%;

        &::placeholder {
            color: #bbb;
        }

        &:focus {
            outline: none;
            border-bottom: 1px solid $blue;
        }
    }

    .open-dropdown {
        align-items: center;
        display: flex;

        > svg {
            margin-right: 8px;
            stroke: $blue;
        }
    }

    // Cards
    .card-list {
        margin-top: 20px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .card-list-item {
        border: 1px solid $teal;
        border-radius: 3px;
        display: flex;
        flex: 0 0 22%;
        flex-flow: row wrap;
        padding: 30px;
        position: relative;
        margin: 10px 10px 50px 10px;
    }

    .card-list-icon {
        background-color: $dark;
        box-shadow: 0 0 3px 10px $dark;
        position: absolute;
        top: -20px;
        left: -20px;
    }

    .rich-list-clickable {
        position: relative;
        background-color: rgba($teal, 0);
        cursor: pointer;
        padding: 12px 40px;
        flex: 0 1 320px;
        transition: all 150ms;
        // display: flex;
        // align-items: flex-end;
        min-height: 105px;

        @media only screen and (max-width: 600px) {
            padding: 5px 8px;
        }

        &.active {
            background-color: rgba($teal, 0.15);
            border-left: 1px solid #2d4150;
            border-right: 1px solid #2d4150;
            border-top: 1px solid #2d4150;

            @media only screen and (max-width: 600px) {
                border: 0;
            }
        }

        &:hover {
            background-color: rgba($teal, 0.25);
        }

        &.two-zones {
            flex: 0 1 640px;
        }

        &.three-zones {
            flex: 0 1 960px;
        }

        &.full-zones {
            flex: 0 1 100%;
        }

        &.text {
            position: relative;
            padding: 0;
            min-height: 105px;
        }
    }

    .rich-list-tools {
        h3 {
            margin-left: 20px;
            font-size: 16px;
        }
    }

    .date-wrapper {
        display: flex;
        justify-content: flex-end;
        position: relative;
        float: right;
    }

    .date-picker-button {
        margin-left: 5px;
        border-radius: 8px;
        min-width: 100px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .tabledata-nodata {
        min-height: 3em;
    }

    .date-picker-button-end {
        margin-left: 8px;
        min-width: 100px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .date-picker-button .fa-angle-down:before {
        color: #41c9f0;
        font-size: 28px;
        padding: 0 5px;
    }

    .dates-value {
        color: #41c9f0;
        font-size: 17px;
        padding-right: 5px;
        text-transform: uppercase;
    }

    .summary-value {
        font-size: 17px;
        padding-right: 6px;
        color: $grey;
    }
    .dashwarnlabel {
        align-items: center;
        margin-right: 30px;
        color: $yellow;
    }
}

@media only screen and (max-width: 600px) {
    .ReactModal__Content {
        width: 70%;
        height: auto;
    }

    .close-histogram {
        position: absolute;
        right: 14px;
        top: 10px;
        color: $blue;
        font-size: 26px;
    }
}

@media only screen and (max-width: 1024px) {
    /* tablet */
    .dashboard-edit-wrapper {
        display: none !important;
    }
}
