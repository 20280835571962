@import '../../../styles/vars.scss';

.signal {
    position: relative;

    .signal-wrapper {
        margin: 0px 0;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
    }

    .signal-lamp {
        -webkit-appearance: none;
        width: 12px;
        height: 12px;
        flex: 0 0 12px;
        border-radius: 50%;
        background: rgb(76, 175, 80);
        box-shadow: 0px 0px 2px 8px rgba(76, 175, 80, 0.2);
        margin-left: 5px;

        &.no-data {
            background: $darkgrey;
            box-shadow: 0px 0px 2px 8px $darkgrey;
        }

        &.warn {
            background: $yellow;
            box-shadow: 0px 0px 2px 8px $yellow-faded;
        }

        &.error {
            background: $red;
            box-shadow: 0px 0px 2px 8px $red-faded;
        }

        &.Red {
            background: $red !important;
            box-shadow: 0px 0px 2px 8px $red-faded !important;
        }
        &.Green {
            background: $green !important;
            box-shadow: 0px 0px 2px 8px rgba(76, 175, 80, 0.2) !important;
        }
        &.Grey {
            background: $darkgrey !important;
            box-shadow: 0px 0px 2px 8px $darkgrey-faded !important;
        }
        &.Yellow {
            background: $yellow !important;
            box-shadow: 0px 0px 2px 8px $yellow-faded !important;
        }
    }

    .signal-label {
        flex: 0 1 75%;
        max-width: 75%;
        font-size: 32px;
        margin-left: 20px;
        line-height: 1;
        font-weight: 500;
    }

    .time-since {
        // position: absolute;
        // top: -6px;
        color: $yellow;
        font-size: 13px;
        left: 0;
    }

    // Data

    .data-wrapper {
        display: block;
        margin-left: 10px;
        transform: translateY(0px);
    }

    .data-title__label {
        color: #ececec;
        font-size: 18px;
    }

    .data {
        display: flex;
        flex-flow: row nowrap;
    }

    .data-value__number {
        color: #ececec;
        font-size: 46px;
        font-weight: 500;
        line-height: 1;
        margin: 0;
        padding: 0;
    }
}
