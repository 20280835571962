.transfer-operation-edit-tree {
    width: 50%;
    padding-top: 5px;
}

.transfer-operation-edit-tree > h3 {
    font-weight: 500 !important;
}

.transfer-operation-edit-tree > h4 {
    font-size: 15px;
}

.transfer-operation-edit-tree h2 {
    margin: 0px;
}

.transfer-operation-edit-tree ul {
    list-style-type: none;
}

.transfer-operation-edit-tree .form-container {
    display: block !important;
    padding: 0px 20px 40px 20px;
    height: 400px;
}

.tree__children {
    margin-left: 20px;
}

.tree {
    padding-bottom: 50px;
}
