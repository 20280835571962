@import '../../styles/vars.scss';

.items-add-edit-container {
    display: flex;
    flex-flow: row;
    width: 100%;
    flex-wrap: wrap;
}

.items-add-edit-header {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #2c4147;
    color: $light;
    font-weight: 500;
    margin: 0;
    font-size: 23px;
}

.items-add-edit-content {
    display: flex;
    flex-flow: row;
    width: 100%;
    flex-wrap: nowrap;
    height: 100%;
}

.items-add-edit-tree {
    width: 30%;
    padding-top: 15px;
    > h4 {
        font-size: 15px;
    }
}

.items-add-edit-tree-account {
    min-width: 15%;
    max-width: 30%;
    padding-top: 15px;
    > h4 {
        font-size: 15px;
    }
}

.items-add-edit {
    width: 70%;
    border-left: 1px solid #2c4147;
    padding: 10px;
    padding-left: 50px;
    padding-top: 21px;
}
