@import '../../../styles/vars.scss';

.device-wrapper {
    width: 40%;

    .green {
        color: $green !important;
    }

    .red {
        color: $red !important;
    }
}
