.form-container {
    height: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    width: 100%;
}

form {
    width: 600px;
}

#name {
    text-decoration: none;
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    height: 3em;
    width: 100%;
    border-style: none;
    color: #919191;
    font-size: 15px;
    /* margin-top: 1em; */
    justify-content: center;
    align-items: center;
    background-color: #0d171e;
    border: 1px solid #3f5e6a;
    border-radius: 0.4em;
    padding-left: 5px;
}

.admin-plant-add-edit-text-input {
    text-decoration: none;
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    height: 3em;
    width: 100%;
    border-style: none;
    color: #919191;
    font-size: 15px;
    /* margin-top: 1em; */
    justify-content: center;
    align-items: center;
    background-color: #0d171e;
    border: 1px solid #3f5e6a;
    border-radius: 0.4em;
    padding-left: 5px;
}

#password {
    text-decoration: none;
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    height: 3em;
    width: 100%;
    border-style: none;
    color: #919191;
    cursor: pointer;
    font-size: 15px;
    /* margin-top: 1em; */
    justify-content: center;
    align-items: center;
    background-color: #0d171e;
    border: 1px solid #3f5e6a;
    border-radius: 0.4em;
    padding-left: 5px;
}

#plant_type {
    text-decoration: none;
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    height: 3em;
    width: 100%;
    border-style: none;
    color: #919191;
    cursor: pointer;
    font-size: 15px;
    /* margin-top: 1em; */
    justify-content: center;
    align-items: center;
    background-color: #0d171e;
    border: 1px solid #3f5e6a;
    border-radius: 0.4em;
    padding-left: 5px;
}

/* label {
    display:inline-block;
    padding-top: 20px;
} */

.submit {
    text-decoration: none;
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    height: 3em;
    width: 100%;
    border-style: none;
    color: #919191;
    cursor: pointer;
    font-size: 15px;
    margin-top: 1em;
    justify-content: center;
    align-items: center;
    background-color: #0d171e;
    border: 1px solid #3f5e6a;
    border-radius: 0.4em;
}

.submit :disabled {
    background: #919191;
}

.formErrors {
    color: #ff0000;
}

.button :disabled {
    border: 1px solid #383838;
}

button:disabled,
button[disabled] {
    color: #383838;
    cursor: default;
}

.user-successfully-added {
    color: green;
}

.geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
}

.geosuggest__item {
    cursor: pointer;
}
