@import '../../../styles/vars.scss';

.admin-itemtype-add-edit-form {
    .add-edit-form {
        width: 100%;
    }

    .admin-input-control {
        text-decoration: none;
        font-family: 'Rajdhani', sans-serif;
        display: flex;
        height: 3em;
        width: 100%;
        border-style: none;
        color: $light;
        font-size: 15px;
        justify-content: center;
        align-items: center;
        // border: 1px solid rgb(217, 237, 245);
        border-radius: 0.1em;
        padding-left: 5px;
    }

    div > input,
    .admin-itemtype-add-edit-form input {
        background-color: #1e2e34;
    }

    textarea {
        background-color: #1e2e34;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .item-type-checkbox {
        padding-left: 0;
        justify-content: flex-start;
    }
}
