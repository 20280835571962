.mapContainer {
    .gm-style .gm-style-iw-c {
        background: #081b23 !important;
        display: flex;
        flex-direction: column;
        padding-right: 1em !important;
        padding-left: 1em !important;
        border-radius: 0.4em;
        cursor: default;
        width: 380px;
        height: 285px;
        border: 1px solid #325663;
        font-family: 'Rajdhani', sans-serif;
        text-transform: uppercase;
        justify-content: flex-start;
        z-index: 1000002;
    }

    .gm-style .gm-style-iw-d {
        overflow: hidden !important;
    }

    .gm-style .gm-style-iw-t::after {
        background: #081b23 !important;
        z-index: 1000002;
        border-left: 2px solid #325663;
        border-bottom: 2px solid #325663;
        border-radius: 1px;
        box-shadow: none;
    }

    .info-name {
        display: flex;
        justify-content: center;
        width: auto;
        border-bottom: 1px solid #325663;
        font-size: 20px;
        color: #41c9f0;
        margin-top: 0.3em;
        padding-bottom: 0.3em;
        flex: 0 1 20%;
    }

    .content-grid-map {
        display: grid;
        grid-template: 'sidebar map map' 'sidebar map map'/ 1fr 100%;
        grid-gap: 0;
        height: 100%;
    }

    @media all and (-ms-high-contrast: none) {
        .content-grid-map {
            width: 100vw;
        }
    }

    iframe {
        background-color: #0d171e !important;
    }

    .sidebar-grid-map {
        grid-area: sidebar;
        background-color: #0d171e;
        width: 5em;
        margin-left: 1em;
        margin-top: 2em;
        height: 10.7em;
        overflow: hidden;
        border: 1px solid #2c4147;
        border-radius: 0.4em;
    }

    .map-grid {
        grid-area: map;
        width: 100vw;
        height: 100%;
        overflow: hidden;
    }

    .less-top-margin {
        margin-top: 3em;
    }

    .satellite-button {
        position: absolute;
        top: 1em;
        left: 17em;
        z-index: 2;
        border: 1px solid #2c4147;
        border-radius: 0.4em;
        color: #35c4ec;
        background-color: #0d171e;
        padding: 0.5em;
        box-sizing: border-box;
        cursor: pointer;
    }

    .localities-button {
        position: absolute;
        top: 1em;
        left: 22em;
        z-index: 2;
        border: 1px solid #2c4147;
        border-radius: 0.4em;
        color: #35c4ec;
        background-color: #0d171e;
        padding: 0.5em;
        box-sizing: border-box;
        cursor: pointer;
    }

    .info-table-wrapper {
        padding: 10px;
    }

    .info-table {
        color: #35c4ec;
    }

    .info-table td {
        padding-right: 10px;
        vertical-align: top;
    }

    .title-cell {
        width: 60%;
    }

    .info-header {
        display: block;
    }

    .info-address {
        color: #35c4ec;
    }

    .safari-satellite {
        margin-left: 3em;
    }

    .visibleChk {
        display: inherit;
    }

    .hiddenChk {
        display: none;
    }

    .liceCheck {
        position: absolute;
        top: 12em;
        left: 1em !important;
        z-index: 2;
        font: 0.8em arial;
        color: #35c4ec;
        /* background-color: rgba(13, 23, 30, 0.8) !important; */
        padding: 0;
        cursor: pointer;
    }

    .liceCheck span {
        /* color: #41c9f0 !important; */
        font-size: 14px !important;
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }

    .toggleNamesCheck {
        position: absolute;
        top: 9em;
        left: 1em !important;
        z-index: 2;
        font: 0.8em arial;
        color: #35c4ec;
        /* background-color: rgba(13, 23, 30, 0.8) !important; */
        padding: 0;
        cursor: pointer;
    }

    .toggleNamesCheck span {
        /* color: #41c9f0 !important; */
        font-size: 14px !important;
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }

    .localitiesCheck {
        position: absolute;
        top: 6em !important;
        left: 1em !important;
        z-index: 2;
        font: 0.8em arial;
        color: #35c4ec;
        /* background-color: rgba(13, 23, 30, 0.8) !important; */
        padding: 0;
        cursor: pointer;
    }

    .localitiesCheck span {
        /* color: #41c9f0 !important; */
        font-size: 14px !important;
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }

    .liceCheck > label {
        padding-top: 0;
    }

    .toggleNamesCheck > label {
        padding-top: 0;
    }

    .localitiesCheck > label {
        padding-top: 0;
    }

    input[type='checkbox'] {
        visibility: hidden;
    }

    input[type='checkbox'] + label:before {
        border: 1px solid #2c4147;
        content: '\A0';
        display: inline-block;
        font: 1em sans-serif;
        height: 16px;
        margin: 0.1em 0.25em 0 0;
        padding: 0;
        vertical-align: bottom;
        width: 18px;
        background: #0d171e;
    }

    input[type='checkbox']:checked + label:before {
        background: #0d171e;
        color: #35c4ec;
        content: '\A0\2714';
    }

    .safari-label {
        margin-left: -3em;
        margin-top: -0.2em;
    }

    input[type='checkbox']:checked + label:after {
        font-weight: bold;
    }

    input[type='checkbox']:focus + label::before {
        outline: rgb(59, 153, 252) auto 5px;
    }

    .visibleLbl {
        visibility: visible;
    }

    .hiddenLbl {
        visibility: hidden;
    }

    .gm-ui-hover-effect {
        opacity: 0.7;
        width: 25px;
        background: #41c9f0 !important;
        width: 34px !important;
        height: 25px !important;
    }

    .tabletCheck {
        left: 2.7em !important;
    }

    .search-map {
        border-radius: 0px;
        -webkit-appearance: none;
    }

    @media only screen and (max-width: 600px) {
        .content-grid-map {
            height: 100vh;
            width: 100vw;
            position: fixed;
            left: 0;
            top: 0;
        }

        .satellite-button {
            top: 0;
            left: 15em;
        }

        .search-map {
            top: 0;
            left: -15px;
        }

        .liceCheck {
            top: 7.5em !important;
            left: -1em !important;
        }

        .localitiesCheck {
            top: 4.5em !important;
            left: -1em !important;
        }

        .toggleNamesCheck {
            top: 10.5em !important;
            left: -1em !important;
        }

        .react-confirm-alert-body {
            padding: 0 !important;
            width: 300px !important;
        }

        .no-right-margin {
            margin-right: 0 !important;
        }

        .plantlist-toggle {
            position: absolute;
            top: 0em;
            right: -1em;
            z-index: 2;
            border: 1px solid #2c4147;
            border-radius: 0.4em;
            color: #35c4ec;
            background-color: #0d171e;
            padding: 0.5em;
            box-sizing: border-box;
            cursor: pointer;
        }

        .ReactModal__Content {
            width: 80% !important;
        }
    }
}
