/* autoprefixer grid: on */
.content-grid {
    display: grid;
    grid-template:
        'breadcrumb breadcrumb breadcrumb' 100px
        'tables tables tables'
        'tables tables tables' / 0.1fr 1fr 1fr;
    height: 100%;
    width: auto;
    max-width: 100%;
}

.sidebar-grid {
    grid-area: sidebar;
    background-color: #0d171e;
    width: 5em;
    margin-left: 1em;
    margin-top: 4.5em;
    overflow: hidden;
}

.breadcrumb-grid {
    grid-area: breadcrumb;
    margin-top: 0.5em;
}

.tables-grid {
    grid-area: tables;
    margin-top: 0em;
}

.less-top-margin {
    margin-top: 3em;
}

.currentHeading {
    font-weight: 500;
    margin: 0;
    color: rgb(230, 230, 240);
    font-size: 33px;
    margin: auto;
}

@media only screen and (max-width: 600px) {
    .sidebar-grid {
        display: hidden;
        width: 0px;
        border: none;
        margin: 0;
    }

    .content-grid {
        display: grid;
        grid-template:
            'breadcrumb breadcrumb' 3.5em
            'tables tables'
            'tables tables'
            / 1fr 100%;
        grid-gap: 0;
        height: 100%;
        width: 100vw;
    }

    .tables-grid {
        grid-area: tables;
        margin-top: 0em;
    }
}
