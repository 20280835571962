$dark: rgb(10, 22, 28);
$light: rgb(230, 230, 240);
$teal: rgb(45, 65, 80);
$blue: rgb(65, 205, 245);
$grey: rgb(100, 100, 100);
$wcag-grey: #808080;
$red: rgb(255, 0, 85);
$green: rgb(0, 255, 120);

// Dropdown

.dropdown {
    position: relative;
}

.treenav-dropdown a {
    color: $blue;
}

.orderpos-display {
    color: $light;
    background-color: $dark;
}

.tree-close {
    position: relative;
    cursor: pointer;
    color: $blue;
    right: 10px;
    line {
        stroke: $blue;
        fill: $blue;
    }
}

.treenav-dropdown {
    display: block;
    z-index: 12;
    background-color: $dark;
    font-size: 18px;
    padding: 12px 0;
    position: sticky;
    overflow: hidden;
    top: 0 !important;
    height: 90vh;
    left: 0;
    border: 1px solid $teal;
    border-radius: 3px;
    box-shadow: 0 0 30px 2px $dark;
    //display: none;
    flex-flow: column;
    line-height: 1.5;
    width: 400px;
    &.open {
        display: flex;
    }

    .nav-root {
        display: flex;
        justify-content: space-between;
        color: $blue;

        > .l-1 {
            font-size: 20px;
            font-weight: 600;
            padding: 0 20px;
            &:last-child {
                border-bottom: 0;
            }
        }
    }

    .l-0 {
        letter-spacing: 0.5px;
        padding: 0 20px 0px 5px;
    }

    .l-1 {
        line-height: 1.6;
    }

    .l-2 {
        line-height: 1.6;
    }

    .l-3 {
        line-height: 1.6;
    }
}

.divider {
    background-color: $teal;
    height: 1px;
    margin: 12px 0;
    width: 100%;
}

.menu-line {
    display: flex;
    align-items: center;
    position: relative;

    .menu-line-content {
        position: relative;
        width: 100%;
        // display: flex;
        justify-content: flex-start;
        align-items: center;

        .nav-node-active {
            font-weight: bold;
            text-decoration: underline;
            font-size: 20px;
        }

        .nav-node {
            transition: all 100ms;
            color: $blue;
            position: relative;
            overflow: hidden;
            display: block;
            margin-left: 22px;
            padding: 0 6px;

            &:hover {
                /*&:after {
          background-color: $blue;
          border-radius: 50%;
          content: "";
          display: block;
          height: 8px;
          position: absolute;
          left: -4px;
          top: 8px;
          width: 8px;
        }*/
            }
            &.active {
                border-left: 3px solid $blue;
            }
            > svg {
                fill: $blue;
                margin-left: 5px;
                transform: rotate(180deg) translateY(2px);
            }
        }

        .nav-expander {
            height: 27px;
            width: 27px;
            position: absolute;
            left: 0;
            padding: 0 4px 0 11px;
            path {
                stroke: $blue;
            }
        }

        .nav-collapser {
            height: 27px;
            width: 27px;
            position: absolute;
            padding-left: 8px;
            padding-bottom: 4px;
            path {
                stroke: $blue;
            }
        }
    }
}

.nav-caret {
    padding: 5px;
    color: $blue;
}

.nav-expand {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30px;
    margin-right: 20px;
}

.nav-pad {
    padding: 0 0 0 0px;
}

@media only screen and (max-width: 600px) {
    .treenav-dropdown {
        width: 100%;
        overflow: hidden;
        position: absolute;
        min-height: 100%;
        height: auto;

        .nav-node {
            white-space: normal;
        }
    }
    .nav-pad {
        padding: 0 40px 0 0;
    }
}
@media only screen and (max-width: 1100px) and (min-width: 601px) {
    .treenav-dropdown {
        //width: 100%;
        overflow: hidden;
        position: absolute;
        min-height: 100%;
        height: auto;

        .nav-node {
            white-space: normal;
        }
    }
    .nav-pad {
        padding: 0 40px 0 0;
    }
}
