.transfer-operation-container {
    display: flex;
    flex-flow: row;
    width: 100%;
    flex-wrap: wrap;
}

.transfer-operation-header {
    display: flex;
    width: 100%;
    justify-content: space-between
}

.transfer-operation-header > h2 {
    font-weight: 600 !important;
    margin: 0px !important;
}

.transfer-operation-content {
    display: flex;
    flex-flow: row;
    width: 100%;
    flex-wrap: nowrap;
    height: 100%;
}

.btn-blue {
    width:  auto !important;
}

