@import '../../../styles/vars.scss';

.connection-state {
    display: flex;
    height: 100%;
    align-items: center;
    // justify-content: center;
    margin-left: 30px;
}

.disconnected {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: $red;
    line-height: 24px;
}

.connected {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: $green;
    align-items: center;
}
.versioninfocontainer .outdated {
    color: $red;
}
.versioninfocontainer {
    width: 250px;
    .versioninfoitem {
        display: flex;
        justify-content: space-between;
    }
    .versioninfoitemlabel {
        // float:left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 140px;
    }
    .versioninfoitemvalue {
        // float:right;
        margin-left: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;
    }
}

.react-select-custom > div {
    background-color: $form-input !important;
    color: $light !important;
}

.react-select-custom > div > div {
    color: $light !important;
}
