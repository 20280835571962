@import '../../../styles/vars.scss';

.tooltip-box {
    border: 1px solid $teal;
    height: auto;
    width: 140px;
    padding: 12px;
    background: $dark;
    max-height: none;

    .tooltip-date {
        font-size: 16px;
        color: $grey;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .tooltip-label {
        font-size: 14px;
        padding-top: 8px;
        padding-bottom: 4px;

        &.spread {
            color: $yellow;
        }
    }

    .tooltip-value {
        font-size: 22px;
    }
}

.status-info {
    color: $yellow;
    font-size: 13px;
    left: 0;
}

.tabs {
    display: flex;
    border-bottom: 1px solid $blue;
}

.tab {
    display: flex;
    padding: 3px 15px 3px 15px;
    cursor: pointer;
}

.tab.selected {
    border-bottom: 3px solid $blue;
}

.tab:first-of-type {
    padding: 3px 15px 3px 0px;
}

@media only screen and (max-width: 600px) {
    .tabs {
        padding-top: 20px;
    }
}
