// * {
//     color: #fff
// }

.ant-input,
.ant-form-item-required,
.ant-form-item-label>label {
    color: #fff !important;
}

.ant-input {
    background-color: #1e2e34;
    border-style: none;
    height: 3em;
}

.anticon-minus-circle {
    background-color: #1e2e34 !important;
    color: #da3a3a !important;
}

.ant-btn {
    background-color: #122a34;
    color: #fff;
    border-color: #41C9F0;
}

.ant-select-item-option-content {
    color: #fff !important;
}

.ant-select-item-option-active,
.ant-select-item-option-selected {
    background-color: #0d171e !important;
}