@import '../../../styles/vars.scss';

.apexcharts-canvas {
    .apexcharts-xaxis-label,
    .apexcharts-yaxis-label {
        fill: $grey;
        font-family: 'Rajdhani', sans-serif !important;
        font-size: 17px;
    }

    .apexcharts-xaxis line {
        display: none;
    }

    .apexcharts-grid line,
    .apexcharts-heatmap-rect {
        stroke: $dark;
        stroke-linecap: round;
        stroke-width: 1.5;
    }
}

.widget-content {
    min-height: 250px;
}

.tooltip-box {
    border: 1px solid $teal;
    border-radius: 5px;
    height: auto;
    max-height: 300px;
    min-width: 180px;
    width: auto;
    padding: 12px;
    background: $dark;
    max-height: none;
    white-space: nowrap;
    line-break: strict;

    .tooltip-date {
        font-size: 16px;
        color: $grey;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .tooltip-label {
        font-size: 14px;
        padding-top: 8px;
        padding-bottom: 4px;
    }

    .tooltip-value {
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        color: $blue;

        .tooltipvalue {
            margin-right: 5px;
        }
        .tooltipunit {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
