.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}
/*Toaster messages support linebreaks in texts..*/
.notification-message .message {
    white-space: pre-wrap;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: #919191;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

.widget-wrapper {
    width: 30%;
}

.update-app-warning {
    width: 100%;
    height: 30px;
    background: #2c4147;
    color: white;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    position: fixed;
    top: 0;
    z-index: 9999;
}

.dismiss {
    float: right;
    margin-right: 10px;
    font-size: 12px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
