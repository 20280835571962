@import '../../../styles/vars.scss';
.editor-wrapper {
    display: flex;

    height: 60vh;
    flex: 1 1 100vw;
    .editor {
        margin: 1em;
        width: 50%;
        & > div {
            width: 100%;
            height: 60vh;
        }
    }
    .parse-error {
        pre,
        td {
            color: $light;
        }
    }
}
