.search-input {
    border-style: hidden;
    border-bottom: 1px solid #325663;
    background-color: rgba(13, 23, 30, 0);
    text-align: center;
    color: #41c9f0;
    font-family: 'Rajdhani', sans-serif;
    text-transform: uppercase;
    width: 84%;
    font-size: 18px;
}

.search-nav {
    border-bottom: none;
    text-align: unset;
    padding-left: 20px;
    text-transform: unset;
}

.search-input:focus {
    outline-width: 0;
}

.plant-node {
    cursor: pointer;
    font-weight: bold;
    font-size: 17px;
    padding: 10px 0;
    color: #41c9f0;
}
.plant-node:hover {
    color: #e6e6f0;
}

::placeholder {
    color: #325663;
}

.admin-plant-item-row {
    display: flex;
    flex-flow: row;
    border-bottom: #0d171e;
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    height: 25px;
}

.admin-plant-item-row:hover {
    border-bottom: #41c9f0;
    border-bottom-width: 1px;
    border-bottom-style: dashed;
}

.admin-plant-item-row:hover .admin-plant-item-row-actions {
    display: block;
}

.admin-plant-item-row-actions {
    margin-left: auto;
    display: none;
}

.ant-tree.ant-tree-show-line li span.ant-tree-switcher {
    color: #e6e6f0;
    background: #0b171c;
}

.ant-tree-title {
    color: #e6e6f0;
}

.anticon-caret-down {
    color: #41c9f0;
}

.ant-tree li .ant-tree-node-content-wrapper:hover {
    background-color: #2c4147;
}

.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: #2c4147;
}
