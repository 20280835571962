@import '../../../styles/vars';

.admin-plant-items-add-edit-button-container {
    display: flex;
    justify-content: space-between;
}

.admin-plant-items-add-edit-header {
    width: 100%;
    height: 30px;
    color: $light;
    font-weight: 500;
    font-size: 23px;
}

.admin-plant-items-add-edit-form {
    max-width: 100%;

    >h1 {
        font-size: 27px;
        font-weight: 500;
        margin: 0;
        color: $light;
    }
}

.admin-plant-items-add-edit-form>div>label {
    color: $light;
}

.admin-plant-items-add-edit-form>div>input {
    background-color: $form-input;
}

.admin-plant-items-add-edit-form input {
    background-color: $form-input;
}

.react-select-custom>div {
    background-color: $form-input !important;
    color: $light !important;
}

.react-select-custom>div>div {
    color: $light !important;
}

.admin-plant-items-add-edit-form-geosuggest::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $light;
    opacity: 1;
    /* Firefox */
}

.admin-plant-items-add-edit-form-geosuggest:-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $light;
}

.admin-plant-items-add-edit-form-geosuggest::-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $light;
}

.admin-plant-items-input-control {
    text-decoration: none;
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    height: 3em;
    width: 100%;
    border-style: none;
    color: $light;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    border-radius: 0.1em;
    padding-left: 5px;
    background-color: #1e2e34;
}

.admin-plant-items-submit-button {
    text-decoration: none;
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    height: 3em;

    width: 200px;
    border-style: none;
    color: $light;
    cursor: pointer;
    font-size: 15px;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    background-color: $form-dark;
    border: 1px solid $form-input;
    border-radius: 0.1em;

    &.cancel {
        border: 1px solid $blue;
    }

    &.save {
        border: 1px solid $blue;
        color: $form-dark;
        background-color: $blue;
    }
}

.Select-menu-outer {
    top: auto;
    bottom: 100%;
}

.Select-menu-outer>div {
    top: auto;
    bottom: 100%;
}

.css-mv73hb {
    color: inherit !important;
}

.addDataValue {
    display: flex;
    margin-bottom: 2px;
    align-items: center;
}

/*!
 * 
 * antd v3.23.1
 * 
 * Copyright 2015-present, Alipay, Inc.
 * All rights reserved.
 *       
 */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-no-unknown */
/* MODIFIED VERSION */

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

// .ant-select-tree::after,
// .ant-select-dropdown::after,
// .ant-select-tree-dropdown::after,
// .ant-select-dropdown--single::after,
// .ant-select-dropdown-placement-bottomLeft ::after,
// .ant-select-tree::before,
// .ant-select-dropdown::before,
// .ant-select-tree-dropdown::before,
// .ant-select-dropdown--single::before,
// .ant-select-dropdown-placement-bottomLeft ::before {
//   -webkit-box-sizing: border-box;
//   box-sizing: border-box;
// }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-select-auto-complete {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 15px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', 'tnum';
}

.ant-select-auto-complete.ant-select .ant-select-selection {
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff !important;
}

.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
    height: 100%;
    margin-right: 0;
    margin-left: 0;
    line-height: 32px;
}

.ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
    margin-right: 12px;
    margin-left: 12px;
}

.ant-select-auto-complete.ant-select .ant-select-selection--single {
    height: auto;
}

.ant-select-auto-complete.ant-select .ant-select-search--inline {
    position: static;
    float: left;
}

.ant-select-auto-complete.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
    margin-right: 0 !important;
}

.ant-select-auto-complete.ant-select .ant-input {
    height: 32px;
    line-height: 1.5;
    background: transparent;
    border-width: 1px;
}

.ant-select-auto-complete.ant-select .ant-input:focus,
.ant-select-auto-complete.ant-select .ant-input:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
}

.ant-select-auto-complete.ant-select .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
    background-color: transparent;
}

.ant-select-auto-complete.ant-select .ant-input[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
}

.ant-select-auto-complete.ant-select-lg .ant-select-selection__rendered {
    line-height: 40px;
}

.ant-select-auto-complete.ant-select-lg .ant-input {
    height: 40px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.ant-select-auto-complete.ant-select-sm .ant-select-selection__rendered {
    line-height: 24px;
}

.ant-select-auto-complete.ant-select-sm .ant-input {
    height: 24px;
    padding-top: 1px;
    padding-bottom: 1px;
}

.ant-input-group>.ant-select-auto-complete .ant-select-search__field.ant-input-affix-wrapper {
    display: inline;
    float: none;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-select {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #e6e6f0;
    font-size: 15px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', 'tnum';
    position: relative;
    display: inline-block;
    outline: 0;
}

.ant-select ul,
.ant-select ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

.ant-select>ul>li>a {
    padding: 0;
    background-color: #fff;
}

.ant-select-arrow {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -6px;
    color: #e6e6f0 !important;
    font-size: 11px;
    line-height: 1;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    cursor: pointer;
}

.ant-select-arrow>* {
    line-height: 1;
}

.ant-select-arrow svg {
    display: inline-block;
}

.ant-select-arrow::before {
    display: none;
}

.ant-select-arrow .ant-select-arrow-icon {
    display: block;
}

.ant-select-arrow .ant-select-arrow-icon svg {
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.ant-select-selection {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #1e2e34;
    border: none;
    border-top-width: 1.02px;
    outline: none;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ant-select-selection:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.ant-select-selection__clear {
    position: absolute;
    top: 50%;
    right: 40px;
    z-index: 1;
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-top: -6px;
    color: #e6f7ff;
    font-size: 15px;
    font-style: normal;
    line-height: 12px;
    text-align: center;
    text-transform: none;
    background: #1e2e34;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: color 0.3s ease, opacity 0.15s ease;
    transition: color 0.3s ease, opacity 0.15s ease;
    text-rendering: auto;
}

.ant-select-selection__clear::before {
    display: block;
}

.ant-select-selection__clear:hover {
    color: rgba(255, 255, 255, 0.45);
}

.ant-select-selection:hover .ant-select-selection__clear {
    opacity: 1;
}

.ant-select-selection-selected-value {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ant-select-no-arrow .ant-select-selection-selected-value {
    padding-right: 0;
}

.ant-select-disabled {
    color: rgba(0, 0, 0, 0.25);
}

.ant-select-disabled .ant-select-selection {
    background: #f5f5f5;
    cursor: not-allowed;
}

.ant-select-disabled .ant-select-selection:hover,
.ant-select-disabled .ant-select-selection:focus,
.ant-select-disabled .ant-select-selection:active {
    border-color: #d9d9d9;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ant-select-disabled .ant-select-selection__clear {
    display: none;
    visibility: hidden;
    pointer-events: none;
}

.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
    padding-right: 10px;
    color: rgba(0, 0, 0, 0.33);
    background: #f5f5f5;
}

.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice__remove {
    display: none;
}

.ant-select-selection--single {
    position: relative;
    height: 3em;
    cursor: pointer;
}

.ant-select-selection--single .ant-select-selection__rendered {
    margin-right: 24px;
}

.ant-select-no-arrow .ant-select-selection__rendered {
    margin-right: 11px;
}

.ant-select-selection__rendered {
    position: relative;
    display: block;
    margin-right: 11px;
    margin-left: 5px;
    line-height: 45px;
}

.ant-select-selection__rendered::after {
    display: inline-block;
    width: 0;
    visibility: hidden;
    content: '.';
    pointer-events: none;
}

.ant-select-lg {
    font-size: 16px;
}

.ant-select-lg .ant-select-selection--single {
    height: 40px;
}

.ant-select-lg .ant-select-selection__rendered {
    line-height: 38px;
}

.ant-select-lg .ant-select-selection--multiple {
    min-height: 40px;
}

.ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li {
    height: 32px;
    line-height: 32px;
}

.ant-select-lg .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-lg .ant-select-selection--multiple .ant-select-arrow {
    top: 20px;
}

.ant-select-sm .ant-select-selection--single {
    height: 24px;
}

.ant-select-sm .ant-select-selection__rendered {
    margin-left: 7px;
    line-height: 22px;
}

.ant-select-sm .ant-select-selection--multiple {
    min-height: 24px;
}

.ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered li {
    height: 16px;
    line-height: 14px;
}

.ant-select-sm .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-sm .ant-select-selection--multiple .ant-select-arrow {
    top: 12px;
}

.ant-select-sm .ant-select-selection__clear,
.ant-select-sm .ant-select-arrow {
    right: 8px;
}

.ant-select-disabled .ant-select-selection__choice__remove {
    color: rgba(0, 0, 0, 0.25);
    cursor: default;
}

.ant-select-disabled .ant-select-selection__choice__remove:hover {
    color: rgba(0, 0, 0, 0.25);
}

.ant-select-search__field__wrap {
    position: relative;
    display: inline-block;
}

.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
    position: absolute;
    top: 50%;
    right: 9px;
    left: 0;
    max-width: 100%;
    height: 20px;
    margin-top: -10px;
    overflow: hidden;
    color: #e6e6f0;
    line-height: 20px;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
}

.ant-select-search__field__placeholder {
    left: 12px;
}

.ant-select-search__field__mirror {
    position: absolute;
    top: 0;
    left: 0;
    white-space: pre;
    opacity: 0;
    pointer-events: none;
}

.ant-select-search--inline {
    position: absolute;
    width: 100%;
    height: 100%;
}

.ant-select-search--inline .ant-select-search__field__wrap {
    width: 100%;
    height: 100%;
}

.ant-select-search--inline .ant-select-search__field {
    width: 100%;
    height: 100%;
    font-size: 100%;
    line-height: 1;
    background: transparent;
    border-width: 0;
    border-radius: 4px;
    outline: 0;
}

.ant-select-search--inline>i {
    float: right;
}

.ant-select-selection--multiple {
    min-height: 32px;
    padding-bottom: 3px;
    cursor: text;
    zoom: 1;
}

.ant-select-selection--multiple::before,
.ant-select-selection--multiple::after {
    display: table;
    content: '';
}

.ant-select-selection--multiple::after {
    clear: both;
}

.ant-select-selection--multiple .ant-select-search--inline {
    position: static;
    float: left;
    width: auto;
    max-width: 100%;
    padding: 0;
}

.ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
    width: 0.75em;
    max-width: 100%;
}

.ant-select-selection--multiple .ant-select-selection__rendered {
    height: auto;
    margin-bottom: -3px;
    margin-left: 5px;
}

.ant-select-selection--multiple .ant-select-selection__placeholder {
    margin-left: 6px;
}

.ant-select-selection--multiple>ul>li,
.ant-select-selection--multiple .ant-select-selection__rendered>ul>li {
    height: 24px;
    margin-top: 3px;
    line-height: 22px;
}

.ant-select-selection--multiple .ant-select-selection__choice {
    position: relative;
    float: left;
    max-width: 99%;
    margin-right: 4px;
    padding: 0 20px 0 10px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fafafa;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    cursor: default;
    -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-selection--multiple .ant-select-selection__choice__disabled {
    padding: 0 10px;
}

.ant-select-selection--multiple .ant-select-selection__choice__content {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-selection--multiple .ant-select-selection__choice__remove {
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    right: 4px;
    color: rgba(0, 0, 0, 0.45);
    font-weight: bold;
    line-height: inherit;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: inline-block;
    font-size: 12px;
    font-size: 10px \9;
    -webkit-transform: scale(0.83333333) rotate(0deg);
    -ms-transform: scale(0.83333333) rotate(0deg);
    transform: scale(0.83333333) rotate(0deg);
}

.ant-select-selection--multiple .ant-select-selection__choice__remove>* {
    line-height: 1;
}

.ant-select-selection--multiple .ant-select-selection__choice__remove svg {
    display: inline-block;
}

.ant-select-selection--multiple .ant-select-selection__choice__remove::before {
    display: none;
}

.ant-select-selection--multiple .ant-select-selection__choice__remove .ant-select-selection--multiple .ant-select-selection__choice__remove-icon {
    display: block;
}

:root .ant-select-selection--multiple .ant-select-selection__choice__remove {
    font-size: 12px;
}

.ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
    color: rgba(0, 0, 0, 0.75);
}

.ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-selection--multiple .ant-select-arrow {
    top: 16px;
}

.ant-select-allow-clear .ant-select-selection--single .ant-select-selection-selected-value {
    padding-right: 16px;
}

.ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered,
.ant-select-show-arrow .ant-select-selection--multiple .ant-select-selection__rendered {
    margin-right: 20px;
}

.ant-select-open .ant-select-arrow-icon svg {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.ant-select-open .ant-select-selection {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.ant-select-combobox .ant-select-arrow {
    display: none;
}

.ant-select-combobox .ant-select-search--inline {
    float: none;
    width: 100%;
    height: 100%;
}

.ant-select-combobox .ant-select-search__field__wrap {
    width: 100%;
    height: 100%;
}

.ant-select-combobox .ant-select-search__field {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
}

.ant-select-combobox.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered,
.ant-select-combobox.ant-select-show-arrow .ant-select-selection:hover .ant-select-selection__rendered {
    margin-right: 20px;
}

.ant-select-selector {
    text-decoration: none !important;
    font-family: 'Rajdhani', sans-serif !important;
    display: flex !important;
    height: 3em !important;
    width: 100% !important;
    border-style: none !important;
    color: $light !important;
    font-size: 15px !important;
    align-items: center !important;
    border-radius: 0.1em !important;
    background-color: #1e2e34 !important;
}

.ant-select-selection-search,
.ant-select-selection-search-input {
    height: 100% !important;
    width: 100% !important;
}

.ant-select-selection-search-input {
    border: none !important;
    color: #fff !important;
}

.ant-select-selection-item,
.ant-select-selection-placeholder {
    // padding-left: 10px !important;
    position: absolute !important;
    color: #fff !important;
}

.ant-select-dropdown {
    margin: 0;
    padding: 2px;
    color: rgba(0, 0, 0, 0.65);
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', 'tnum';
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 15px;
    font-variant: initial;
    background-color: #1e2e34;
    border-radius: 4px;
    outline: none;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 8px rgba(79, 79, 79, 0.628);
}

.ant-select-dropdown-hide {
    visibility: hidden !important;
}

.ant-select-dropdown-visible {
    visibility: visible !important;
}

.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn;
}

.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn;
}

.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut;
}

.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut;
}

.ant-select-dropdown-hidden {
    display: none;
}

.ant-select-dropdown-menu {
    max-height: 250px;
    margin-bottom: 0;
    padding-left: 0;
    overflow: auto;
    list-style: none;
    outline: none;
}

.ant-select-dropdown-menu-item-group-list {
    margin: 0;
    padding: 0;
}

.ant-select-dropdown-menu-item-group-list>.ant-select-dropdown-menu-item {
    padding-left: 20px;
}

.ant-select-dropdown-menu-item-group-title {
    height: 32px;
    padding: 0 12px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    line-height: 32px;
}

.ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:first-child:not(:last-child),
.ant-select-dropdown-menu-item-group:not(:last-child) .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:last-child {
    border-radius: 0;
}

.ant-select-dropdown-menu-item {
    position: relative;
    display: block;
    padding: 5px 12px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #e6f7ff;
}

.ant-select-dropdown-menu-item:first-child {
    border-radius: 4px 4px 0 0;
}

.ant-select-dropdown-menu-item:last-child {
    border-radius: 0 0 4px 4px;
}

.ant-select-dropdown-menu-item-selected {
    color: rgba(0, 0, 0, 0.65);
    font-weight: 600;
    background-color: #fafafa;
}

.ant-select-dropdown-menu-item-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}

.ant-select-dropdown-menu-item-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #e6f7ff;
}

.ant-select-dropdown-menu-item-divider {
    height: 1px;
    margin: 1px 0;
    overflow: hidden;
    line-height: 0;
    background-color: #e8e8e8;
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
    padding-right: 32px;
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .ant-select-selected-icon {
    position: absolute;
    top: 50%;
    right: 12px;
    color: transparent;
    font-weight: bold;
    font-size: 12px;
    text-shadow: 0 0.1px 0, 0.1px 0 0, 0 -0.1px 0, -0.1px 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:hover .ant-select-selected-icon {
    color: rgba(0, 0, 0, 0.87);
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-disabled .ant-select-selected-icon {
    display: none;
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
    display: inline-block;
    color: #1890ff;
}

.ant-select-dropdown--empty.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
    padding-right: 12px;
}

.ant-select-dropdown-container-open .ant-select-dropdown,
.ant-select-dropdown-open .ant-select-dropdown {
    display: block;
}

.ant-select-clear {
    position: absolute !important;
    // top: 13px !important;
    right: 25px !important;
    cursor: pointer !important;
    color: #fff !important;
    background-color: #1e2e34 !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-dropdown {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 15px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', 'tnum';
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    display: block;
}

.ant-dropdown::before {
    position: absolute;
    top: -7px;
    right: 0;
    bottom: -7px;
    left: -7px;
    z-index: -9999;
    opacity: 0.0001;
    content: ' ';
}

.ant-dropdown-wrap {
    position: relative;
}

.ant-dropdown-wrap .ant-btn>.anticon-down {
    display: inline-block;
    font-size: 12px;
    font-size: 10px \9;
    -webkit-transform: scale(0.83333333) rotate(0deg);
    -ms-transform: scale(0.83333333) rotate(0deg);
    transform: scale(0.83333333) rotate(0deg);
}

:root .ant-dropdown-wrap .ant-btn>.anticon-down {
    font-size: 12px;
}

.ant-dropdown-wrap .anticon-down::before {
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
}

.ant-dropdown-wrap-open .anticon-down::before {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.ant-dropdown-hidden,
.ant-dropdown-menu-hidden {
    display: none;
}

.ant-dropdown-menu {
    position: relative;
    margin: 0;
    padding: 4px 0;
    text-align: left;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 4px;
    outline: none;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
}

.ant-dropdown-menu-item-group-title {
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.45);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.ant-dropdown-menu-submenu-popup {
    position: absolute;
    z-index: 1050;
}

.ant-dropdown-menu-submenu-popup>.ant-dropdown-menu {
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
    clear: both;
    margin: 0;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    white-space: nowrap;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.ant-dropdown-menu-item>.anticon:first-child,
.ant-dropdown-menu-submenu-title>.anticon:first-child {
    min-width: 12px;
    margin-right: 8px;
}

.ant-dropdown-menu-item>a,
.ant-dropdown-menu-submenu-title>a {
    display: block;
    margin: -5px -12px;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.65);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected>a,
.ant-dropdown-menu-submenu-title-selected>a {
    color: #1890ff;
    background-color: #e6f7ff;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
    background-color: #e6f7ff;
}

.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}

.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: not-allowed;
}

.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
    height: 1px;
    margin: 4px 0;
    overflow: hidden;
    line-height: 0;
    background-color: #e8e8e8;
}

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
    position: absolute;
    right: 8px;
}

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
    color: rgba(0, 0, 0, 0.45);
    font-style: normal;
    display: inline-block;
    font-size: 12px;
    font-size: 10px \9;
    -webkit-transform: scale(0.83333333) rotate(0deg);
    -ms-transform: scale(0.83333333) rotate(0deg);
    transform: scale(0.83333333) rotate(0deg);
}

:root .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
:root .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
    font-size: 12px;
}

.ant-dropdown-menu-submenu-title {
    padding-right: 26px;
}

.ant-dropdown-menu-submenu-vertical {
    position: relative;
}

.ant-dropdown-menu-submenu-vertical>.ant-dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 100%;
    margin-left: 4px;
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
}

.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
    color: rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: not-allowed;
}

.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn;
}

.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn;
}

.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut;
}

.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut;
}

.ant-dropdown-trigger>.anticon.anticon-down,
.ant-dropdown-link>.anticon.anticon-down {
    display: inline-block;
    font-size: 12px;
    font-size: 10px \9;
    -webkit-transform: scale(0.83333333) rotate(0deg);
    -ms-transform: scale(0.83333333) rotate(0deg);
    transform: scale(0.83333333) rotate(0deg);
}

:root .ant-dropdown-trigger>.anticon.anticon-down,
:root .ant-dropdown-link>.anticon.anticon-down {
    font-size: 12px;
}

.ant-dropdown-button {
    white-space: nowrap;
}

.ant-dropdown-button.ant-btn-group>.ant-btn:last-child:not(:first-child) {
    padding-right: 8px;
    padding-left: 8px;
}

.ant-dropdown-button .anticon.anticon-down {
    display: inline-block;
    font-size: 12px;
    font-size: 10px \9;
    -webkit-transform: scale(0.83333333) rotate(0deg);
    -ms-transform: scale(0.83333333) rotate(0deg);
    transform: scale(0.83333333) rotate(0deg);
}

:root .ant-dropdown-button .anticon.anticon-down {
    font-size: 12px;
}

.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
    background: #001529;
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item>a {
    color: rgba(255, 255, 255, 0.65);
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item>a .ant-dropdown-menu-submenu-arrow::after {
    color: rgba(255, 255, 255, 0.65);
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item>a:hover {
    color: #fff;
    background: transparent;
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected>a {
    color: #fff;
    background: #1890ff;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@-webkit-keyframes antCheckboxEffect {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(1.6);
        transform: scale(1.6);
        opacity: 0;
    }
}

@keyframes antCheckboxEffect {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(1.6);
        transform: scale(1.6);
        opacity: 0;
    }
}

.ant-select-tree-checkbox {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 15px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', 'tnum';
    position: relative;
    top: -0.09em;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    vertical-align: middle;
    outline: none;
    cursor: pointer;
}

.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus+.ant-select-tree-checkbox-inner {
    border-color: #1890ff;
}

.ant-select-tree-checkbox-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #1890ff;
    border-radius: 2px;
    visibility: hidden;
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
    animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
    content: '';
}

.ant-select-tree-checkbox:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after {
    visibility: visible;
}

.ant-select-tree-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    border-collapse: separate;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.ant-select-tree-checkbox-inner::after {
    position: absolute;
    top: 50%;
    left: 21%;
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
    -ms-transform: rotate(45deg) scale(0) translate(-50%, -50%);
    transform: rotate(45deg) scale(0) translate(-50%, -50%);
    opacity: 0;
    -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    content: ' ';
}

.ant-select-tree-checkbox-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}

.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
}

.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    background-color: #1890ff;
    border-color: #1890ff;
}

.ant-select-tree-checkbox-disabled {
    cursor: not-allowed;
}

.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
    border-color: rgba(0, 0, 0, 0.25);
    -webkit-animation-name: none;
    animation-name: none;
}

.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
    cursor: not-allowed;
}

.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
    background-color: #f5f5f5;
    border-color: #d9d9d9 !important;
}

.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
    border-color: #f5f5f5;
    border-collapse: separate;
    -webkit-animation-name: none;
    animation-name: none;
}

.ant-select-tree-checkbox-disabled+span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}

.ant-select-tree-checkbox-disabled:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-disabled::after {
    visibility: hidden;
}

.ant-select-tree-checkbox-wrapper {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 15px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', 'tnum';
    display: inline-block;
    line-height: unset;
    cursor: pointer;
}

.ant-select-tree-checkbox-wrapper+.ant-select-tree-checkbox-wrapper {
    margin-left: 8px;
}

.ant-select-tree-checkbox+span {
    padding-right: 8px;
    padding-left: 8px;
}

.ant-select-tree-checkbox-group {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 15px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', 'tnum';
    display: inline-block;
}

.ant-select-tree-checkbox-group-item {
    display: inline-block;
    margin-right: 8px;
}

.ant-select-tree-checkbox-group-item:last-child {
    margin-right: 0;
}

.ant-select-tree-checkbox-group-item+.ant-select-tree-checkbox-group-item {
    margin-left: 0;
}

.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
    background-color: #fff;
    border-color: #d9d9d9;
}

.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: #1890ff;
    border: 0;
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    content: ' ';
}

.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
    background-color: rgba(0, 0, 0, 0.25);
    border-color: rgba(0, 0, 0, 0.25);
}

.ant-select-tree {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    color: rgba(0, 0, 0, 0.65) !important;
    font-size: 15px;
    font-family: 'Rajdhani', sans-serif;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', 'tnum';
    margin: 0;
    margin-top: -4px;
    padding: 0 4px;
    background-color: #1e2e34 !important;
}

.ant-select-tree li {
    margin: 8px 0;
    padding: 0;
    white-space: nowrap;
    list-style: none;
    outline: 0;
}

.ant-select-tree li.filter-node>span {
    font-weight: 500;
}

.ant-select-tree li ul {
    margin: 0;
    padding: 0 0 0 18px;
}

.ant-select-tree li .ant-select-tree-node-content-wrapper {
    display: inline-block;
    width: calc(100% - 24px);
    margin: 0;
    padding: 3px 5px;
    color: #e6e6f0;
    text-decoration: none;
    border-radius: 2px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
    background-color: #1d43c5;
}

.ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
    background-color: #1d43c5;
}

.ant-select-tree li span.ant-select-tree-checkbox {
    margin: 0 4px 0 0;
}

.ant-select-tree li span.ant-select-tree-checkbox+.ant-select-tree-node-content-wrapper {
    width: calc(100% - 46px);
}

.ant-select-tree li span.ant-select-tree-switcher,
.ant-select-tree li span.ant-select-tree-iconEle {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: 0;
    line-height: 22px;
    text-align: center;
    vertical-align: middle;
    border: 0 none;
    outline: none;
    cursor: pointer;
}

.ant-select-tree li span.ant-select-icon_loading .ant-select-switcher-loading-icon {
    position: absolute;
    left: 0;
    display: inline-block;
    color: #1890ff;
    font-size: 15px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.anticon-caret-down {
    color: #e6e6f0;
}

.ant-select-tree li span.ant-select-icon_loading .ant-select-switcher-loading-icon svg {
    position: absolute;
    color: white;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.ant-select-tree li span.ant-select-tree-switcher {
    position: relative;
}

.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher-noop {
    cursor: auto;
}

.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon {
    font-size: 12px;
    font-size: 10px \9;
    -webkit-transform: scale(0.83333333) rotate(0deg);
    -ms-transform: scale(0.83333333) rotate(0deg);
    transform: scale(0.83333333) rotate(0deg);
    display: inline-block;
    font-weight: bold;
}

:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon,
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon {
    font-size: 12px;
}

.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon svg {
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon {
    font-size: 12px;
    font-size: 10px \9;
    -webkit-transform: scale(0.83333333) rotate(0deg);
    -ms-transform: scale(0.83333333) rotate(0deg);
    transform: scale(0.83333333) rotate(0deg);
    display: inline-block;
    font-weight: bold;
}

:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon,
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon {
    font-size: 12px;
}

.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon svg {
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon svg {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-loading-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-loading-icon {
    position: absolute;
    left: 0;
    display: inline-block;
    width: 24px;
    height: 24px;
    color: #1890ff;
    font-size: 15px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-loading-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-loading-icon svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.ant-select-tree-treenode {
    color: #fff !important;
    padding: 1px !important;
}

.ant-select-tree-node-content-wrapper {
    cursor: pointer !important;
}

.ant-select-tree .ant-select-tree-treenode-loading .ant-select-tree-iconEle {
    display: none;
}

.ant-select-tree-child-tree {
    display: none;
}

.ant-select-tree-child-tree-open {
    display: block;
}

li.ant-select-tree-treenode-disabled>span:not(.ant-select-tree-switcher),
li.ant-select-tree-treenode-disabled>.ant-select-tree-node-content-wrapper,
li.ant-select-tree-treenode-disabled>.ant-select-tree-node-content-wrapper span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}

li.ant-select-tree-treenode-disabled>.ant-select-tree-node-content-wrapper:hover {
    background: transparent;
}

.ant-select-tree-icon__open {
    margin-right: 2px;
    vertical-align: top;
}

.ant-select-tree-icon__close {
    margin-right: 2px;
    vertical-align: top;
}

.ant-select-tree-dropdown {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 15px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', 'tnum';
    background-color: #1e2e34;
}

.ant-select-tree-dropdown .ant-select-dropdown-search {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    display: block;
    padding: 4px;
    background: #1e2e34;
}

.ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field__wrap {
    width: 100%;
}

.ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    padding: 4px 7px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    outline: none;
    background-color: #1e2e34;
    color: #e6e6f0;
}

.ant-select-tree-dropdown .ant-select-dropdown-search.ant-select-search--hide {
    display: none;
}

.ant-select-tree-dropdown .ant-select-not-found {
    display: block;
    padding: 7px 16px;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@-webkit-keyframes antCheckboxEffect {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(1.6);
        transform: scale(1.6);
        opacity: 0;
    }
}

@keyframes antCheckboxEffect {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(1.6);
        transform: scale(1.6);
        opacity: 0;
    }
}

.ant-tree.ant-tree-directory {
    position: relative;
}

.ant-tree.ant-tree-directory>li span.ant-tree-switcher,
.ant-tree.ant-tree-directory .ant-tree-child-tree>li span.ant-tree-switcher {
    position: relative;
    z-index: 1;
}

.ant-tree.ant-tree-directory>li span.ant-tree-switcher.ant-tree-switcher-noop,
.ant-tree.ant-tree-directory .ant-tree-child-tree>li span.ant-tree-switcher.ant-tree-switcher-noop {
    pointer-events: none;
}

.ant-tree.ant-tree-directory>li span.ant-tree-checkbox,
.ant-tree.ant-tree-directory .ant-tree-child-tree>li span.ant-tree-checkbox {
    position: relative;
    z-index: 1;
}

.ant-tree.ant-tree-directory>li span.ant-tree-node-content-wrapper,
.ant-tree.ant-tree-directory .ant-tree-child-tree>li span.ant-tree-node-content-wrapper {
    border-radius: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ant-tree.ant-tree-directory>li span.ant-tree-node-content-wrapper:hover,
.ant-tree.ant-tree-directory .ant-tree-child-tree>li span.ant-tree-node-content-wrapper:hover {
    background: transparent;
}

.ant-tree.ant-tree-directory>li span.ant-tree-node-content-wrapper:hover::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree>li span.ant-tree-node-content-wrapper:hover::before {
    background: #e6f7ff;
}

.ant-tree.ant-tree-directory>li span.ant-tree-node-content-wrapper.ant-tree-node-selected,
.ant-tree.ant-tree-directory .ant-tree-child-tree>li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
    color: #fff;
    background: transparent;
}

.ant-tree.ant-tree-directory>li span.ant-tree-node-content-wrapper::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree>li span.ant-tree-node-content-wrapper::before {
    position: absolute;
    right: 0;
    left: 0;
    height: 24px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    content: '';
}

.ant-tree.ant-tree-directory>li span.ant-tree-node-content-wrapper>span,
.ant-tree.ant-tree-directory .ant-tree-child-tree>li span.ant-tree-node-content-wrapper>span {
    position: relative;
    z-index: 1;
}

.ant-tree.ant-tree-directory>li.ant-tree-treenode-selected>span.ant-tree-switcher,
.ant-tree.ant-tree-directory .ant-tree-child-tree>li.ant-tree-treenode-selected>span.ant-tree-switcher {
    color: #fff;
}

.ant-tree.ant-tree-directory>li.ant-tree-treenode-selected>span.ant-tree-checkbox .ant-tree-checkbox-inner,
.ant-tree.ant-tree-directory .ant-tree-child-tree>li.ant-tree-treenode-selected>span.ant-tree-checkbox .ant-tree-checkbox-inner {
    border-color: #1890ff;
}

.ant-tree.ant-tree-directory>li.ant-tree-treenode-selected>span.ant-tree-checkbox.ant-tree-checkbox-checked::after,
.ant-tree.ant-tree-directory .ant-tree-child-tree>li.ant-tree-treenode-selected>span.ant-tree-checkbox.ant-tree-checkbox-checked::after {
    border-color: #fff;
}

.ant-tree.ant-tree-directory>li.ant-tree-treenode-selected>span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner,
.ant-tree.ant-tree-directory .ant-tree-child-tree>li.ant-tree-treenode-selected>span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background: #fff;
}

.ant-tree.ant-tree-directory>li.ant-tree-treenode-selected>span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after,
.ant-tree.ant-tree-directory .ant-tree-child-tree>li.ant-tree-treenode-selected>span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
    border-color: #1890ff;
}

.ant-tree.ant-tree-directory>li.ant-tree-treenode-selected>span.ant-tree-node-content-wrapper::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree>li.ant-tree-treenode-selected>span.ant-tree-node-content-wrapper::before {
    background: #1890ff;
}

.ant-tree-checkbox {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 15px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', 'tnum';
    position: relative;
    top: -0.09em;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    vertical-align: middle;
    outline: none;
    cursor: pointer;
}

.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus+.ant-tree-checkbox-inner {
    border-color: #1890ff;
}

.ant-tree-checkbox-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #1890ff;
    border-radius: 2px;
    visibility: hidden;
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
    animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
    content: '';
}

.ant-tree-checkbox:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
    visibility: visible;
}

.ant-tree-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    border-collapse: separate;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.ant-tree-checkbox-inner::after {
    position: absolute;
    top: 50%;
    left: 21%;
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
    -ms-transform: rotate(45deg) scale(0) translate(-50%, -50%);
    transform: rotate(45deg) scale(0) translate(-50%, -50%);
    opacity: 0;
    -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    content: ' ';
}

.ant-tree-checkbox-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: #1890ff;
    border-color: #1890ff;
}

.ant-tree-checkbox-disabled {
    cursor: not-allowed;
}

.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
    border-color: rgba(0, 0, 0, 0.25);
    -webkit-animation-name: none;
    animation-name: none;
}

.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
    cursor: not-allowed;
}

.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
    background-color: #f5f5f5;
    border-color: #d9d9d9 !important;
}

.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
    border-color: #f5f5f5;
    border-collapse: separate;
    -webkit-animation-name: none;
    animation-name: none;
}

.ant-tree-checkbox-disabled+span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}

.ant-tree-checkbox-disabled:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled::after {
    visibility: hidden;
}

.ant-tree-checkbox-wrapper {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 15px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', 'tnum';
    display: inline-block;
    line-height: unset;
    cursor: pointer;
}

.ant-tree-checkbox-wrapper+.ant-tree-checkbox-wrapper {
    margin-left: 8px;
}

.ant-tree-checkbox+span {
    padding-right: 8px;
    padding-left: 8px;
}

.ant-tree-checkbox-group {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 15px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', 'tnum';
    display: inline-block;
}

.ant-tree-checkbox-group-item {
    display: inline-block;
    margin-right: 8px;
}

.ant-tree-checkbox-group-item:last-child {
    margin-right: 0;
}

.ant-tree-checkbox-group-item+.ant-tree-checkbox-group-item {
    margin-left: 0;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
    background-color: #fff;
    border-color: #d9d9d9;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: #1890ff;
    border: 0;
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    content: ' ';
}

.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
    background-color: rgba(0, 0, 0, 0.25);
    border-color: rgba(0, 0, 0, 0.25);
}

.ant-tree {
    /* see https://github.com/ant-design/ant-design/issues/16259 */
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.65);
    font-size: 15px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', 'tnum';
    margin: 0;
    padding: 0;
}

.ant-tree-checkbox-checked::after {
    position: absolute;
    top: 16.67%;
    left: 0;
    width: 100%;
    height: 66.67%;
}

.ant-tree ol,
.ant-tree ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.ant-tree li {
    margin: 0;
    padding: 4px 0;
    white-space: nowrap;
    list-style: none;
    outline: 0;
}

.ant-tree li span[draggable],
.ant-tree li span[draggable='true'] {
    line-height: 20px;
    border-top: 2px transparent solid;
    border-bottom: 2px transparent solid;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* Required to make elements draggable in old WebKit */
    -khtml-user-drag: element;
    -webkit-user-drag: element;
}

.ant-tree li.drag-over>span[draggable] {
    color: white;
    background-color: #1890ff;
    opacity: 0.8;
}

.ant-tree li.drag-over-gap-top>span[draggable] {
    border-top-color: #1890ff;
}

.ant-tree li.drag-over-gap-bottom>span[draggable] {
    border-bottom-color: #1890ff;
}

.ant-tree li.filter-node>span {
    color: #f5222d !important;
    font-weight: 500 !important;
}

.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-loading-icon,
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-loading-icon {
    position: absolute;
    left: 0;
    display: inline-block;
    width: 24px;
    height: 24px;
    color: #1890ff;
    font-size: 15px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-loading-icon svg,
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-loading-icon svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

:root .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open::after,
:root .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close::after {
    opacity: 0;
}

.ant-tree li ul {
    margin: 0;
    padding: 0 0 0 18px;
}

.ant-tree li .ant-tree-node-content-wrapper {
    display: inline-block;
    height: 24px;
    margin: 0;
    padding: 0 5px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 24px;
    text-decoration: none;
    vertical-align: top;
    border-radius: 2px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.ant-tree li .ant-tree-node-content-wrapper:hover {
    background-color: #e6f7ff;
}

.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: #bae7ff;
}

.ant-tree li span.ant-tree-checkbox {
    top: auto;
    top: initial;
    height: 24px;
    margin: 0 4px 0 2px;
    padding: 4px 0;
}

.ant-tree li span.ant-tree-switcher,
.ant-tree li span.ant-tree-iconEle {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: 0;
    line-height: 24px;
    text-align: center;
    vertical-align: top;
    border: 0 none;
    outline: none;
    cursor: pointer;
}

.ant-tree li span.ant-tree-switcher {
    position: relative;
}

.ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
    cursor: default;
}

.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
    font-size: 12px;
    font-size: 10px \9;
    -webkit-transform: scale(0.83333333) rotate(0deg);
    -ms-transform: scale(0.83333333) rotate(0deg);
    transform: scale(0.83333333) rotate(0deg);
    display: inline-block;
    font-weight: bold;
}

:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
    font-size: 12px;
}

.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon svg {
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
    font-size: 12px;
    font-size: 10px \9;
    -webkit-transform: scale(0.83333333) rotate(0deg);
    -ms-transform: scale(0.83333333) rotate(0deg);
    transform: scale(0.83333333) rotate(0deg);
    display: inline-block;
    font-weight: bold;
}

:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
    font-size: 12px;
}

.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon svg {
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.ant-tree li:last-child>span.ant-tree-switcher::before,
.ant-tree li:last-child>span.ant-tree-iconEle::before {
    display: none;
}

.ant-tree>li:first-child {
    padding-top: 7px;
}

.ant-tree>li:last-child {
    padding-bottom: 7px;
}

.ant-tree-child-tree>li:first-child {
    padding-top: 8px;
}

.ant-tree-child-tree>li:last-child {
    padding-bottom: 0;
}

li.ant-tree-treenode-disabled>span:not(.ant-tree-switcher),
li.ant-tree-treenode-disabled>.ant-tree-node-content-wrapper,
li.ant-tree-treenode-disabled>.ant-tree-node-content-wrapper span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}

li.ant-tree-treenode-disabled>.ant-tree-node-content-wrapper:hover {
    background: transparent;
}

.ant-tree-icon__open {
    margin-right: 2px;
    vertical-align: top;
}

.ant-tree-icon__close {
    margin-right: 2px;
    vertical-align: top;
}

.ant-tree.ant-tree-show-line li {
    position: relative;
}

.ant-tree.ant-tree-show-line li span.ant-tree-switcher {
    color: rgba(0, 0, 0, 0.45);
    background: #fff;
}

.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-select-switcher-icon {
    display: inline-block;
    font-weight: normal;
    font-size: 12px;
}

.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-select-switcher-icon svg {
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
    display: inline-block;
    font-weight: normal;
    font-size: 12px;
}

.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon svg {
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
    display: inline-block;
    font-weight: normal;
    font-size: 12px;
}

.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon svg {
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.ant-tree.ant-tree-show-line li:not(:last-child)::before {
    position: absolute;
    left: 12px;
    width: 1px;
    height: 100%;
    height: calc(100% - 22px);
    margin: 22px 0 0;
    border-left: 1px solid #d9d9d9;
    content: ' ';
}

.ant-tree.ant-tree-icon-hide .ant-tree-treenode-loading .ant-tree-iconEle {
    display: none;
}

.ant-tree.ant-tree-block-node li .ant-tree-node-content-wrapper {
    width: calc(100% - 24px);
}

.ant-tree.ant-tree-block-node li span.ant-tree-checkbox+.ant-tree-node-content-wrapper {
    width: calc(100% - 46px);
}

.with-margin {
    margin-right: 10px !important;
}

.action-icon {
    height: 22px;
    width: 22px;
    vertical-align: middle;
    margin-left: 10px;
    cursor: pointer;
}

.add-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.datavalues-list,
.route-list {
    height: 100px !important;
}

.datavalue-list .input-control {
    justify-content: start;
    padding-left: 0 !important;
    padding-left: 10px !important;
}

.list-view {
    overflow: auto;
}

.datavalues-wrapper {
    min-height: 50px;
    background-color: $form-input;
}

.circle-icon,
.dotted-datavalue {
    justify-content: center;
    align-items: center;
    margin: auto;
    padding-left: 25px;
    max-height: 15px !important;
    max-width: 15px !important;
}

.td-datavalue {
    display: inline-block;
    margin-left: 5px !important;
}

.td-key-value {
    min-width: 320px;
}

.topic-text-header {
    font-size: 16px;
    font-weight: bold;
}

.add-datavalue {
    color: $blue;
}

.ant-empty-description {
    color: #fff;
}