/* autoprefixer grid: on */

.optimar-date-picker {
    border: 1px solid #2c4147;
    border-radius: 3px;
    background: linear-gradient(143deg, #0a1c23 0%, #06181f 93%) !important;
    padding: 10px;
    position: absolute;
    z-index: 4;
    top: 22px;
    display: flex;
    width: 500px;
}

.optimar-time-part {
    border-left: 1px solid #2c4147;
    padding: 20px;
}

.optimar-time-part .times {
    display: flex;
    flex-flow: row wrap;
    width: 130px;
}

.optimar-time-part .active {
    color: #41c9f0;
}

.action-btn {
    float: left;
    text-align: center;
    padding: 7px;
    margin: 14px 4px 4px 4px;
    background-color: #122a34;
    border: 1px solid #41c9f0;
    border-radius: 2px;
    cursor: pointer;
    color: rgb(230, 230, 240);
}

.optimar-time-part .now {
    text-align: center;
    width: 130px;
    display: block;
    border-bottom: 1px solid #2c4147;
    padding-bottom: 6px;
    cursor: pointer;
}

.optimar-time-part .hour {
    float: left;
    padding: 6px;
    cursor: pointer;
    text-align: center;
    width: 30px;
}

.optimar-date-picker-period {
    border: 1px solid #2c4147;
    border-radius: 3px;
    background: linear-gradient(143deg, #0a1c23 0%, #06181f 93%);
    padding: 20px;
    position: absolute;
    z-index: 2;
    top: 22px;
    right: 118px;
    width: 200px;
}

.date-wrapper-item-row .optimar-date-picker-period {
    right: 0;
    left: 115px;
}

.optimar-date-picker-period div {
    cursor: pointer;
}

.optimar-date-picker .rdrInputRanges {
    display: none;
}

.optimar-date-picker .rdrStaticRanges button {
    text-transform: uppercase;
    color: #41c9f0;
}

.optimar-date-picker .rdrStaticRanges .rdrStaticRangeSelected {
    color: white;
}

.optimar-date-picker .rdrDay {
    background: none;
    border: none;
    font-size: 16px;
    width: 42px;
}

.optimar-date-picker .rdrDay:focus,
.optimar-date-picker .rdrDay:active {
    outline: none;
}

.optimar-date-picker .rdrDayNumber span {
    color: #41c9f0;
    font-size: 16px;
}

.optimar-date-picker .rdrDayPassive .rdrDayNumber span {
    color: #2f5360;
}

.optimar-date-picker .rdrDefinedRangesWrapper {
    display: none;
    background: none;
}

.optimar-date-picker .rdrDateDisplayWrapper {
    display: none;
}

.optimar-date-picker .rdrMonthPicker select,
.optimar-date-picker .rdrYearPicker select {
    color: #41c9f0;
}

.optimar-date-picker .rdrStaticRange {
    background: none;
    color: white;
    border: none;
}

.optimar-date-picker .rdrCalendarWrapper,
.optimar-date-picker .rdrDateDisplay {
    background: none;
}

.optimar-date-picker .rdrDefinedRangesWrapper {
    border-right: 1px solid #2b4147;
    margin-right: 20px;
    width: 200px;
}

.optimar-date-picker .rdrStaticRangeSelected span {
    color: #41c9f0;
}

.optimar-date-picker .rdrWeekDays {
    display: flex;
    justify-content: space-evenly;
}

.optimar-date-picker .rdrMonthAndYearPickers {
    width: 220px;
    display: flex;
    justify-content: space-evenly;
}
.optimar-date-picker .rdrMonthAndYearWrapper {
    position: relative;
}

.optimar-date-picker .rdrMonthAndYearPickers select {
    text-transform: uppercase;
}

.optimar-date-picker .rdrMonthAndYearWrapper .rdrPprevButton {
    position: absolute;
    right: 26px;
    top: 12px;
    background: none;
    border: none;
}

.optimar-date-picker .rdrMonthAndYearWrapper button {
    position: absolute;
    right: 0;
    top: 12px;
    background: none;
    border: none;
}

.optimar-date-picker .rdrMonthAndYearWrapper button i {
    border: none;
}

.optimar-date-picker .rdrMonthAndYearWrapper .rdrPprevButton:after {
    content: '<';
    color: #41c9f0;
    font-size: 18px;
}

.optimar-date-picker .rdrMonths {
    margin-top: 20px;
}

.optimar-date-picker .rdrMonthAndYearWrapper .rdrNextButton:after {
    content: '>';
    color: #41c9f0;
    font-size: 18px;
}
.optimar-date-picker .rdrDay {
    line-height: 17px !important;
    position: relative;
}
.optimar-date-picker .rdrDay .rdrSelected {
    background: none !important;
    border: 1px solid white;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 26px;
}
.optimar-date-picker .rdrDay:hover {
    background: none !important;
    border: 1px solid #41c9f0;
    border-radius: 50%;
}

.optimar-date-picker .rdrMonthPicker {
    position: absolute;
    left: 0;
}

.rdrInRange {
    color: #1d343c !important;
}

.rdrStartEdge {
    color: #41c9f0 !important;
    border-radius: 1.042em;
}

.rdrEndEdge {
    color: #05181f !important;
    border: 1px solid #2b4147;
    border-radius: 1.042em;
}

.rdrStartEdge.rdrEndEdge {
    color: #41c9f0 !important;
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
    border: none;
}

.optimar-date-picker-mobile {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 12px;
    font-size: 14px;
    height: 40px;
}

.optimar-date-picker-mobile span {
    padding: 5px;
}

.optimar-date-picker-mobile span.active {
    border-left: 1px solid #2b4147;
    border-right: 1px solid #2b4147;
    color: #41c9f0;
}

@media only screen and (max-width: 600px) {
    .date-picker-button {
        border: none;
        font-size: 14px;
        text-align: center;
    }

    .dates-value {
        font-size: 14px;
    }
}
