@import '../../../styles/vars.scss';
.file-list {
    border-top: 1px solid #2d4150;
    display: flex;
    flex-direction: column;
}

.file-line {
    border-bottom: 1px solid #2d4150;
    display: flex;
    @media only screen and (max-width: 600px) {
        flex-wrap: wrap;
    }
    justify-content: space-between;
    padding: 5px 0 5px 0;
}

.file-download {
    margin: 0 10px 0 10px;
    color: $blue;
    margin: 0 10px 0 10px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    svg {
        width: 13px;
        height: 13px;
        path {
            stroke: $blue;
        }
        line {
            stroke: $blue;
        }
    }
}

.file-delete {
    color: $blue;
    margin: 0 20px 0 10px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    svg {
        width: 11px;
        height: 11px;
        path {
            stroke: $red;
        }
        line {
            stroke: $red;
        }
    }
}

.file-actions {
    display: flex;
    justify-content: space-around;
}
