@import '../../styles/vars.scss';

p {
    font-size: 18px;
    line-height: 1.5;
    margin: 0 0 30px 0;
    color: $light;
    &.lead {
        font-size: 21px;
    }
}

a {
    color: $blue;
    cursor: pointer;
    text-decoration: none;
}

h2 {
    font-weight: 400;
    line-height: 1.5;
    margin: 0 0 20px;
}

h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    margin: 0;
}

.rich-list {
    display: block;
    width: 100%;
}

.list-items-header {
    align-items: center;
    border-bottom: 1px solid $teal;
    display: flex;
    flex-flow: nowrap;
    padding: 7px 0 6px 0;
    width: 100%;
}

.list-item {
    align-items: flex;
    border-bottom: 1px solid $teal;
    display: flex;
    font-size: 20px;
    line-height: 1.5;
    flex-flow: nowrap;
    padding: 8px 0 7px 0;
    width: 100%;
    color: $light;
    &.expanded {
        border-bottom: 0;
    }
    &.download {
        cursor: pointer;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        font-size: 15px;

        h4 {
            font-size: 16px;
        }
    }
}

.list-expand-toggle {
    align-items: flex-start;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 0 30px 0 0;
    color: $blue;
    cursor: pointer;
    text-decoration: none;

    &.expanded {
        > svg {
            transform: rotate(180deg) translateY(-10px);
        }
    }
    > h4 {
        display: inline-block;
        vertical-align: middle;
        flex: 0 0 80%;
    }
    > svg {
        display: inline-block;
        fill: $blue;
        margin-left: 4px;
        vertical-align: middle;
        transform: rotate(0) translateY(10px);
        transform-origin: center;
    }
}

.note-text {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    color: $light;
}

.list-item-expandable {
    border-bottom: 1px solid $teal;
    padding: 15px 0 25px 0;
    background: $darkblue;
}

.list-item-date {
    flex: 0 1 165px;
}

.list-item-delete {
    cursor: pointer;
    color: $blue;
    > svg {
        margin-right: 7px;
    }
}

.list-item-plantName {
    flex: 0 1 260px;
}

.list-item-title {
    flex: 1 1 350px;

    // a {
    //     color: $light;
    // }

    &.download {
        text-align: center;
        font-size: 16px;
    }

    &.download-icon {
        font-size: 20px;
    }
}

.list-item-person {
    flex: 0 1 300px;
}

.list-item-status {
    flex: 0 1 150px;
}

.list-item-comment {
    flex: 0 1 50px;
}

.list-item-text {
    flex: 0 1 20%;
}

// List header
.main-header {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    vertical-align: top;
    align-items: baseline;
    span {
        display: flex;
        align-items: center;
        > h2 {
            display: inline-block;
            margin: 0;
        }
        > a {
            display: inline-block;
        }
    }
}

.main-header-title {
    display: block;
    > h2 {
        display: inline-block;
        line-height: 1.5;
        margin: 0;
    }
    > a {
        display: inline-block;
        line-height: 1.5;
        margin: 0;
    }
}

.main-header-action {
    padding: 0 20px;
    font-weight: 400;
    font-size: 20px;
}

// Rich list
.rich-list {
    display: block;
    width: 100%;
}

.rich-list-item {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
}

.rich-list-icon {
    flex: 0 0 65px;
}

.rich-list-info {
    border-bottom: 1px solid $teal;
    display: flex;
    flex: 1 0 300px;
    flex-flow: row wrap;
    padding: 15px 0;
    width: 100%;
}

.rich-list-text {
    flex: 0 0 25%;
    padding-right: 40px;
}

.list-search {
    position: relative;
    margin-right: 8px;
}

.list-search-input {
    background-color: transparent;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid $teal;
    border-left: 0;
    color: $light;
    line-height: 1;
    font-family: 'Rajdhani', sans-serif;
    font-size: 20px;
    font-weight: normal;
    padding: 0 5px 10px 61px;
    width: 100%;
    &::placeholder {
        color: #bbb;
    }
    &:focus {
        outline: none;
        border-bottom: 1px solid $blue;
    }
}

.table-tools {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin: 5px 0 8px 0;
}

.faux-checkbox {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-flow: row wrap;
    transition: all 100ms;
    &:hover {
        > span {
            opacity: 1;
        }
    }
    > .checkbox {
        background-color: #0d171e;
        border: 3px solid #2c4147;
        border-radius: 50%;
        height: 13px;
        width: 13px;
        margin-right: 7px;
        transition: all 100ms;
        &.checked {
            background-color: #41c9f0;
        }
    }
    > span {
        color: #ececec;
        font-size: 16px;
        margin: 0;
        opacity: 0.8;
        transition: all 100ms;
    }
}

.input-icon {
    fill: $light;
    position: absolute;
    top: 2px;
    left: 10px;
}

// Buttons
.buttons {
    display: flex;
    flex-flow: row wrap;
    > .btn {
        margin-right: 20px;
    }
}

// .btn {
//     border-style: solid;
//     border-radius: 3px;
//     border-width: 1px;
//     background-color: transparent;
//     color: $light;
//     cursor: pointer;
//     font-family: inherit;
//     font-size: 18px;
//     font-weight: 600;
//     outline: none;
//     padding: 7px 15px;
//     transition: all 100ms;
// }

// .btn-blue {
//     border-color: $blue;
//     &:hover {
//         background-color: $blue;
//     }
// }

// .btn-red {
//     border-color: $red;
//     &:hover {
//         background-color: $red;
//     }
// }

.search-list {
    background-color: #2c4147;
    border: 0;
    color: #e6e6f0;
    line-height: 1;
    font-family: Rajdhani, sans-serif;
    font-size: 18px;
    font-weight: 400;
    padding: 5px 5px 5px 40px;
}

.search-list:focus {
    outline: none;
    border-bottom: 1px solid #41cdf5;
}

.search-list::placeholder {
    color: #bbb;
}

@media only screen and (max-width: 600px) {
    .list-items-header {
        display: none;
        flex-flow: row wrap;
    }

    .list-item {
        flex-flow: row wrap;
    }

    .note-text {
        margin-left: 0;
        padding: 10px !important;
    }

    .main-header {
        flex-flow: row wrap;

        .list-search {
            margin: 20px 0;
        }

        h2 {
            flex: 0 0 100%;
            max-width: 100%;
        }

        span {
            flex-flow: row wrap;
        }

        .main-header-action {
            padding-left: 0;
            margin-right: 10px;
        }
    }

    .list-item-title {
        flex: 0 1 350px;
    }

    .list-expand-toggle {
        padding: 0;
    }
}
