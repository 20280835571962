.optimar-form-date-picker {
    border: 1px solid #2c4147;
    border-radius: 3px;
    background: linear-gradient(143deg, #0a1c23 0%, #06181f 93%) !important;
    padding: 20px;
    z-index: 4;
    width: 350px;
    position: absolute;
}

.optimar-date-part {
}

.optimar-time-part {
    border-left: 1px solid #2c4147;
    padding: 20px;
}

.optimar-time-part .active {
    color: #41c9f0;
}

.action-btn {
    float: left;
    text-align: center;
    padding: 2px;
    margin: 2px;
    background-color: #0d171e;
    border: 1px solid #3f5e6a;
    border-radius: 0.4em;
    cursor: pointer;
    color: #41c9f0;
}

.optimar-time-part .now {
    text-align: center;
    width: 100px;
    display: block;
    border-bottom: 1px solid #2c4147;
    padding-bottom: 6px;
    cursor: pointer;
}

.optimar-time-part .hour {
    float: left;
    padding: 6px;
    cursor: pointer;
    text-align: center;
}

.optimar-form-date-picker-period {
    border: 1px solid #2c4147;
    border-radius: 3px;
    background: linear-gradient(143deg, #0a1c23 0%, #06181f 93%);
    padding: 20px;
    position: absolute;
    z-index: 2;
    top: 50px;
    right: 135px;
    width: 96px;
}

.date-wrapper-item-row .optimar-form-date-picker-period {
    right: 0;
    left: 115px;
}

.optimar-form-date-picker-period div {
    cursor: pointer;
}

.optimar-form-date-picker .rdrInputRanges {
    display: none;
}

.optimar-form-date-picker .rdrStaticRanges button {
    text-transform: uppercase;
    color: #41c9f0;
}

.optimar-form-date-picker .rdrStaticRanges .rdrStaticRangeSelected {
    color: white;
}

.optimar-form-date-picker .rdrDayNumber span {
    color: #41c9f0;
    font-size: 16px;
}

.optimar-form-date-picker .rdrDayPassive .rdrDayNumber span {
    color: #2f5360;
}

.optimar-form-date-picker .rdrDefinedRangesWrapper {
    display: none;
    background: none;
}

.optimar-form-date-picker .rdrDateDisplayWrapper {
    display: none;
}

.optimar-form-date-picker .rdrMonthPicker select,
.optimar-form-date-picker .rdrYearPicker select {
    color: #41c9f0;
}

.optimar-form-date-picker .rdrStaticRange {
    background: none;
    color: white;
    border: none;
}

.optimar-form-date-picker .rdrCalendarWrapper,
.optimar-form-date-picker .rdrDateDisplay {
    background: none;
}

.optimar-form-date-picker .rdrDefinedRangesWrapper {
    border-right: 1px solid #2b4147;
    margin-right: 20px;
    width: 200px;
}

.optimar-form-date-picker .rdrStaticRangeSelected span {
    color: #41c9f0;
}

.optimar-form-date-picker .rdrMonthAndYearWrapper {
    position: relative;
}

.optimar-form-date-picker .rdrMonthAndYearPickers select {
    text-transform: uppercase;
}

.optimar-form-date-picker .rdrMonthAndYearWrapper .rdrPprevButton {
    position: absolute;
    right: 26px;
}

.optimar-form-date-picker .rdrMonthAndYearWrapper button {
    background: none;
}

.optimar-form-date-picker .rdrMonthAndYearWrapper button i {
    border: none;
}

.optimar-form-date-picker .rdrMonthAndYearWrapper .rdrPprevButton:after {
    content: '<';
    color: #41c9f0;
    font-size: 18px;
}

.optimar-form-date-picker .rdrMonthAndYearWrapper .rdrNextButton:after {
    content: '>';
    color: #41c9f0;
    font-size: 18px;
}

.optimar-form-date-picker .rdrMonthPicker {
    position: absolute;
    left: 0;
}

.rdrInRange {
    color: #1d343c !important;
}

.rdrStartEdge {
    color: #41c9f0 !important;
    border-radius: 1.042em;
}

.rdrEndEdge {
    color: #05181f !important;
    border: 1px solid #2b4147;
    border-radius: 1.042em;
}

.rdrStartEdge.rdrEndEdge {
    color: #41c9f0 !important;
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
    border: none;
}

.optimar-form-date-picker-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 12px;
    font-size: 14px;
    height: 40px;
}

.optimar-form-date-picker-mobile span {
    padding: 5px;
}

.optimar-form-date-picker-mobile span.active {
    border-left: 1px solid #2b4147;
    border-right: 1px solid #2b4147;
    color: #41c9f0;
}

@media only screen and (max-width: 600px) {
    .date-picker-button {
        border: none;
        font-size: 14px;
    }

    .dates-value {
        font-size: 14px;
    }
}
