.profileimage {
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
    margin: 0;
}

.profilebutton {
    display: flex;
    padding: 2em;
    padding-left: 1.3em;
    padding-right: 1.3em;
    height: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: space-evenly;
    text-decoration: none;
    color: white;
    background: 0;
    border: 0;
    cursor: pointer;
    border-left: 1px solid #3f5e6a;
}

@media all and (-ms-high-contrast: none) {
    .profilebutton {
        padding-right: 2.5em;
    }
}

.profilebutton:hover {
    background-color: #134758;
}

.no-pimage {
    display: flex;
    align-items: center;
}
