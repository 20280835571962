@import './vars.scss';

html,
body {
    -webkit-overflow-scrolling: touch !important;
    overflow: auto !important;
    height: 100% !important;
}
.modal-child-wrapper {
    input {
        background: #1e2e34 !important;
    }
}
.form-container {
    input,
    select,
    textarea,
    .optimar__control {
        background: #1e2e34 !important;
        border: 0 !important;
        border-radius: 0 !important;
        -webkit-appearance: none;
    }

    .optimar__single-value {
        color: inherit;
    }

    .optimar__input {
        color: #ececec;
    }
}

.ReactModal__Overlay {
    overflow: auto;
}

// Lists
.ReactTable .rt-noData {
    margin-top: 70px;
    background: none;
    color: white;
}

// React confirm alert
.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

.react-confirm-alert-body {
    width: 400px;
    padding: 30px;
    text-align: left;
    background: $form-dark;
    border-radius: 1px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #666;
    opacity: 1;
}

.react-confirm-alert-button-group {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

.react-confirm-alert-body > h1 {
    color: $light;
    font-weight: 600;
    font-size: 25px;
    margin: 0;
}

.react-confirm-alert-body > p {
    font-size: 18px;
    margin: 20px 0 0 0;
}

.react-confirm-alert-body > span {
    font-size: 18px;
    margin: 0;
    color: $light;
}

.react-confirm-alert-body > .buttons {
    margin-top: 20px;
}

.react-confirm-alert-body > .buttons > .btn {
    font-weight: 500;
}

// Buttons
.btn-row {
    display: flex;
    align-items: baseline;
    flex-direction: row;
}

.btn-row > :first-child {
    margin-right: auto;
}

.btn-row-grow {
    display: flex;
    align-items: baseline;
    flex-direction: row;
}

.btn-row-grow > :not(:last-child) {
    margin-right: 50px;
}

.btn {
    color: $light;
    text-decoration: none;
    padding: 7px 15px;
    border-radius: 2px;
    font-family: 'Rajdhani', sans-serif;
    font-size: 17px;
    transition: all 100ms;
    border: 1px solid $form-dark;
    cursor: pointer;
    font-weight: 500;
    outline: none;
    min-width: 98px;
    height: 36px;
    margin-top: 5px;
}

.btn-grow {
    flex-grow: 1;
}

.btn-blue {
    background-color: $button-blue-bg-normal;
    border: 1px solid $button-blue-border;

    &:hover {
        background-color: $button-blue-bg-hover;
    }

    &:active {
        background-color: $button-blue-bg-active;
    }

    &:disabled {
        background-color: $button-gray-bg;
        color: $button-gray-text;
        border: 1px solid $button-gray-border;
    }
}

.btn-yellow {
    background-color: $button-yellow-bg-normal;
    border: 1px solid $button-yellow-border;

    &:hover {
        background-color: $button-yellow-bg-hover;
    }

    &:active {
        background-color: $button-yellow-bg-active;
    }

    &:disabled {
        background-color: $button-gray-bg;
        color: $button-gray-text;
        border: 1px solid $button-gray-border;
    }
}

.btn-red {
    background-color: $button-red-bg-normal;
    border: 1px solid $button-red-border;

    &:hover {
        background-color: $button-red-bg-hover;
    }

    &:active {
        background-color: $button-red-bg-active;
    }

    &:disabled {
        background-color: $button-gray-bg;
        color: $button-gray-text;
        border: 1px solid $button-gray-border;
    }
}

.btn-full-width {
    width: 100%;
}

h1 {
    font-size: 33px;
    font-weight: 500;
    line-height: 1.5;
    // margin: 0 0 20px 0;
    color: $light;
}

h2 {
    font-size: 25px;
    font-weight: normal;
    line-height: 1.5;
    // margin: 0 0 20px 0;
    color: $light;
}

h3 {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 1.5;
    margin: 0;
    color: $light;
}

h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    margin: 0;
    color: $light;
}

p {
    font-size: 18px;
    line-height: 1.5;
    margin: 0 0 30px 0;

    &.lead {
        font-size: 21px;
    }
}

label {
    display: inline-block;
    padding-top: 20px;
    color: $light;
}

a {
    color: $blue;
    cursor: pointer;
    text-decoration: none;
}

span {
    margin: 0;
    line-height: 1.5;
}
