@import '../../styles/vars';

.plant-deactivated > .rt-td {
    color: #585858 !important;
}

.toggle-container {
    display: flex;
    align-items: center;
    padding-left: 2px;
}

.toggle-container > span {
    margin-left: 10px;
}

.toggle {
    background-color: #1e2e34;
    border-radius: 15px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    height: 16px;
    position: relative;
    transition: all 200ms ease-in;
    width: 30px;
    cursor: pointer !important;
    border: 1px solid $button-blue-border;
}

.dot {
    background-color: #2c4147;
    border-radius: 50%;
    height: 12px;
    margin: 1px;
    transition: all 200ms ease-in;
    width: 12px;
}

.toggle.on {
    justify-content: flex-end;
}

.toggle.on > .dot {
    background-color: $blue;
}

.action-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
}

.action-element {
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: flex-start;
}

.action-element.padding {
    padding-left: 27px;
}

.action-element > span {
    margin-left: 4px;
}

.action-icon {
    height: 11px;
    width: 11px;
}

.action-icon path {
    stroke: $blue;
}

.action-icon rect {
    stroke: $blue;
}

.action-icon.red line {
    stroke: $red;
}

.action-icon line {
    stroke: $blue;
}

.action-icon polygon {
    stroke: $blue;
}

.angle-down path {
    stroke: $light;
    fill: $light;
}
