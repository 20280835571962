.map {
    display: block;
    width: 100%;
    height: 100vh;
    position: relative;
}

.mapContainer {
    width: 100%;
    height: 100%;
    position: relative;
}

.sidebar-title {
    font-family: 'Rajdhani', sans-serif;
    color: #919191;
}

.status {
    width: 90%;
    border-top: 1px solid #143744;
    margin-bottom: 1em;
}

.sla {
    width: 90%;
    border-top: 1px solid #143744;
    color: #919191;
}

.imo-mmsi {
    width: 90%;
    border-top: 1px solid #143744;
    display: flex;
    justify-content: space-evenly;
    color: #919191;
    padding-top: 1em;
    padding-bottom: 1em;
}

.imo {
    border-right: 1px solid #143744;
    align-items: center;
    width: 50%;
    color: #919191;
}

.imo h3,
.mmsi h3 {
    margin-top: 0;
}

.mmsi {
    width: 50%;
    height: 100%;
    color: #919191;
}

.goto-btn {
    text-decoration: none;
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    height: 3em;
    width: 100%;
    border-style: none;
    color: #919191;
    cursor: pointer;
    font-size: 15px;
    margin-top: 1em;
    justify-content: center;
    align-items: center;
    background-color: #0d171e;
    border: 1px solid #3f5e6a;
    border-radius: 0.4em;
}

.plantlist-cell .goto-btn {
    margin-top: 0;
}

.online {
    color: #47b565;
}

.offline {
    color: #da3a3a;
}

.metadata {
    color: #325663;
}

.value {
    color: #41c9f0;
}

.status a {
    text-decoration: none;
}

.goto-list {
    text-decoration: none;
    list-style: none;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: stretch;
    flex-direction: column;
}

.marinetraffic {
    text-align: center;
}

.goto-btn:hover {
    background-color: #134454;
}

.plantimage {
    width: 100%;
    max-height: 100%;
    padding-bottom: 1em;
}

.search-map {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    border: 1px solid #2d4150;
    color: #e6e6f0;
    background: #0d171e;
    line-height: 1;
    font-family: 'Rajdhani', sans-serif;
    font-size: 20px;
    font-weight: 400;
    padding: 5px 5px 5px 10px;
    width: 200px;
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    z-index: 2;
}

.search-map:focus {
    outline: none;
    border-bottom: 1px solid #41cdf5;
}

.search-map::placeholder {
    color: #bbb;
}

.search-dropdown {
    display: flex;
    flex-direction: column;
    background: #0d171e;
    border: 1px solid #2d4150;
    z-index: 5;
    position: absolute;
    top: 3em;
    left: 0.65em;
    width: 198px;
}

.search-drodown-element {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    cursor: pointer;
    padding-left: 10px;
}

.search-dropdown .active {
    background: #13232d;
}

.search-drodown-element:hover {
    background: #13232d;
}
