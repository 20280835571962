.userInfoTooltip {
    pointer-events: auto !important;
    //&:hover {
    //visibility: visible !important;
    //opacity: 1 !important;
    //}

    .userInfoOuter {
        display: flex;

        .userInfoLeft {
            height: 50px;
            width: 50px;
            margin-top: 10px;
            margin-right: 10px;

            .infoicon {
                box-sizing: 'content-box';
                height: 80%;
                width: 80%;
            }
        }
    }
}
