// Data
.value-cell-wrapper {
    padding-bottom: 12px;
    padding-right: 30px;

    .data-wrapper {
        display: block;
        transform: translateY(8px);

        span {
            line-height: 1em;
        }
    }

    .data-title {
        margin-top: -9px;

        .data-title__label {
            color: #ececec;
            font-size: 18px;
        }
    }

    .data {
        display: flex;
        flex-flow: row nowrap;
    }

    .data-value__number {
        color: #ececec;
        font-size: 46px;
        font-weight: 500;
        line-height: 1;
        margin: 0;
        padding: 0;
    }

    .data-units {
        margin-left: 8px;
        transform: translateY(3px);
    }

    .data-units__total {
        color: #ececec;
        display: block;
        font-size: 18px;
        margin: 0;
        padding: 0;
    }

    .data-units__unit {
        color: #b4b4b4;
        display: block;
        font-size: 16px;
        margin: 0;
        padding: 0;
    }
}
