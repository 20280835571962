@import '../../../styles/vars';

.question,
.wrapper {
    position: relative;
    margin-left: 2px;
    cursor: help;
    color: #fff;
    font-weight: normal;
}

.question {
    display: flex;
    align-items: center;

}

.wrapper:hover {
    .color-desc-modal {
        display: flex;
        flex-direction: column;
        z-index: 1;
    }
}

.color-desc-modal {
    display: none;
    position: absolute;
    background-color: $form-dark;
    min-width: 400px;
    max-width: 600px;
    font-size: 12px;
    border: solid 1px #ffffff42;
    border-radius: 5px;
    padding: 10px 10px 10px 10px;

    .color-desc-modal-color-definition {
        display: flex;
        align-items: center;
        margin-top: 5px;

        span {
            margin-left: 5px;
        }
    }

}