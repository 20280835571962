.widget {
    width: 100%;
    position: relative;
    /* overflow: hidden; */
}
.widget-config-info {
    float: right;
    position: relative;
    z-index: 100;
}

.widget-header {
    display: flex;
    flex-flow: row nowrap;
    font-size: 18px;
    justify-content: space-between;
    margin: 0 0 4px 0;
}

.widget-title {
    font-weight: 400;
}

.widget-value {
    font-weight: 600;
    letter-spacing: 0.5px;
}

.value-cell-wrapper {
    position: relative;
}

.value-cell-wrapper .widget-header {
    padding-top: 8px;
}

.value-cell {
    position: relative;
    flex: 0 1 33%;
    min-width: 150px;
    text-align: right;
}

.value-cell .single-value {
    text-align: left;
    font-size: 48px;
    font-weight: 600;
}

.manual-add {
    font-size: 24px;
    line-height: 24px;
    color: #35c4ec;
    font-weight: bold;
    cursor: pointer;
}

.blue-header {
    color: #35c4ec;
}

.widget-sub-header {
    font-size: 18px;
    text-align: center;
}

/* 
.widget-content {
    padding: 0 30px 30px 30px;
} */

.progress-wheel {
    display: flex;
    align-items: center;
}
.progress-wheel span {
    line-height: 1em;
}

.widget-content .widget-wheel {
    flex: 0 0 60%;
    max-width: 60%;
}

.widget-content-full {
    flex: 0 0 100%;
    max-width: 100%;
}

.widget-table {
    width: 100%;
}

.widget-table-horizontal {
    flex: 0 0 50%;
    display: flex;
    flex-wrap: nowrap;
    max-width: 50%;
    justify-content: center;
    margin: 0 auto;
    margin-top: 10px;
}

.widget-table .table-row {
    padding: 8px;
    width: 98%;
    border: 1px solid #2c4147;
    border-radius: 3px;
    display: flex;
    justify-content: space-around;
    font-size: 16px;
}

.widget-table .log-row {
    padding: 8px;
    width: 98%;
    border: 1px solid #2c4147;
    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
}

.widget-table .log-row td {
    padding: 2px;
}

.widget-table-horizontal .table-cell {
    padding: 2%;
    flex: 0 0 29%;
    max-width: 29%;
    border: 1px solid #2c4147;
    border-radius: 3px;
    display: flex;
    justify-content: space-around;
    font-size: 16px;
}

.plant-table {
    width: 100%;
}

.plant-table .table-row,
.plant-links-table .table-row {
    padding: 1%;
    width: 91%;
    border-bottom: 1px solid #2c4147;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    align-items: center;
}

.plant-table .cell-image-text {
    padding: 0;
}

.plant-table .cell-image-text a {
    padding-right: 10px;
}

.plant-table .table-row .map-value {
    color: #35c4ec;
    text-align: right;
    font-size: 18px;
    display: flex;
    align-items: center;
}

.plant-table .table-row .map-value a {
    margin-right: 0;
    margin-left: 20px;
}

.widget-table .table-row .label,
.widget-content .widget-table-horizontal .table-cell .label,
.plant-table .table-row .label {
    color: #e6e6f0;
    font-size: 18px;
}

.widget-table .table-row .value,
.widget-content .widget-table-horizontal .table-cell .value,
.plant-table .table-row .value {
    color: #35c4ec;
    text-align: right;
    font-size: 18px;
}

.reference-line-label {
    fill: white;
    font-size: 16px;
    letter-spacing: 0.5px;
    direction: initial;
}

.label-wrapper {
    fill: red;
}

.recharts-text {
    direction: initial;
}

.widget-bar-chart {
    direction: initial;
    display: flex;
    justify-content: center;
}

.widget-bar-chart .recharts-tooltip-wrapper {
    border: 1px solid #cabf30;
    margin-top: -50px;
    margin-left: -22px;
    padding: 8px;
    border-radius: 4px;
}

.recharts-tooltip-wrapper {
    z-index: 999999;
}

.widget-bar-chart .arrow-down {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 20px solid #cabf30;
    position: absolute;
    bottom: -20px;
    left: 1px;
}

#plant-info-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin: 0 auto;
}

#plant-image {
    flex: 0 0 40%;
    max-width: 40%;
    height: auto;
    background-size: cover !important;
}

#plant-info {
    flex: 0 0 60%;
    max-width: 60%;
    display: flex;
    justify-content: space-between;
}

#plant-info-details {
    flex: 1 1 61%;
    margin: 0 auto;
}

.fish-farm-table {
    margin-top: 50px;
    max-width: 60%;
}

.fish-farm-table h3 {
    color: #41c9f0;
    margin-left: 10px;
}

.tablet-h3 {
    margin-left: 0.2em !important;
}

@media only screen and (max-width: 600px) {
    #plant-info-wrapper {
        display: flex;
        justify-content: flex-start;
        flex: 0 1 100%;
        border-radius: 10px;
        margin-top: 20px;
        margin: 0 auto;
        flex-direction: column;
    }
    #plant-image {
        flex: 0 0 30%;
        max-width: 100%;
        height: auto;
        height: 150px;
        margin-top: 20px;
    }
    #plant-info {
        flex: 0 1 100%;
        max-width: 100%;
        padding: 2%;
        display: flex;
        justify-content: space-between;
    }
    #plant-info-details {
        margin: 0 auto;
    }

    .fish-farm-table {
        margin-top: 30px;
        max-width: 100%;
    }

    .fish-farm-table h3 {
        margin-left: 4px;
    }
}

#plant-info .plant-name {
    color: #919191;
    font-size: 25px;
    text-align: center;
    padding-bottom: 22px;
    text-transform: uppercase;
}

#plant-info .upload-status {
    color: #4ec96e;
    font-size: 24px;
    padding-bottom: 20px;
}

#plant-info-details .cell-text-grey {
    padding-left: 10px;
}

#plant-info-actions {
    flex: 0 0 36%;
    max-width: 36%;
    text-align: center;
    padding-top: 30px;
}

.widget-table .warn {
    color: #cabf30;
}

.widget .warn {
    color: #cabf30;
}

.widget .alert {
    color: #da3a3a;
}

.tick-interval {
    font-size: 18px;
    position: absolute;
    right: 6px;
}

.tick-interval span {
    text-decoration: underline;
    cursor: pointer;
}

.tick-interval span.active {
    color: white;
}

.line-nav #Artboard-Copy-3 {
    fill: #ccc !important;
}

.line-nav .left-nav {
    position: absolute;
    left: 4px;
    top: 40%;
    font-size: 44px;
    cursor: pointer;
    z-index: 2;
}

.line-nav .active #Artboard-Copy-3 {
    fill: rgb(65, 201, 240) !important;
}

.line-nav .right-nav {
    position: absolute;
    right: 4px;
    top: 40%;
    font-size: 44px;
    cursor: pointer;
    z-index: 2;
}

.widget-sub-header.end-date-selected {
    color: white;
}

.widget-content.end-date-selected {
    opacity: 0.2;
}

.value-cell.end-date-selected {
    opacity: 0.2;
}

.value-cell.end-date-selected .single-value {
    color: #919191;
}

.value-cell-upload-status {
    position: absolute;
    top: -27px;
    right: 0;
    font-size: 14px;
    color: rgb(100, 100, 100);
}

.old-data {
    color: #c9b54e;
}

.log-table {
    border-radius: 3px;
    border-spacing: 0;
}

.log-table tr th {
    height: 38px;
    text-align: left;
}

.log-table tr td {
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid #2c4147;
    border-spacing: 0;
    border-radius: 0px;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .info-box-label {
        font-size: 4px;
    }
    .line-nav .right-nav,
    .line-nav .left-nav {
        display: none;
    }
    .value-cell .single-value {
        font-size: 35px;
    }
}

.time-since {
    color: #c9b54e;
    font-size: 13px;
    /* position: absolute;
    top: -10px; */
}
