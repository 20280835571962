/* autoprefixer grid: on */

.content-grid-plant {
    display: grid;
    grid-template:
        'plant plant'
        'plant plant'
        / 1fr 100%;
    grid-gap: 0;
    height: 100%;
    max-width: 100%;
    width: 100%;
}

.plantlist-grid {
    grid-area: plant;
    margin-top: 2em;
    width: auto;
    border-radius: 0.4em;
}

.less-top-margin {
    margin-top: 3em;
}

@media only screen and (max-width: 600px) {
    .content-grid-plant {
        width: 100%;
        max-width: 100%;
        margin-left: 0 auto;
        margin-right: 0 auto;
        display: flex;
        justify-content: flex-start;
    }

    .sidebar-grid-plant {
        display: hidden;
        width: 0px;
    }

    .plantlist-grid {
        width: 100%;
        max-width: inherit;
        display: flex;
        margin-left: 0em;
        margin-top: 0em;
    }
}
