@import '../../styles/vars.scss';

.designer_modal_content {
    position: absolute;
    inset: 54% auto auto 50%;
    border: 1px solid $teal;
    background: #0d171e;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 40px;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 30px 2px $dark;
    height: 80%;
    width: 90%;
}

.addedit_modal_content {
    position: absolute;
    inset: 54% auto auto 50%;
    border: 1px solid $teal;
    background: #0d171e;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 40px;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 30px 2px $dark;
    min-height: 20vw;
    max-height: 80vh;
    width: 600px;
}

.ReactModal__Overlay {
    background: rgba(10, 22, 28, 0.8) !important;
}
