.statistics-container {
    display: flex;
    justify-content: space-between;

    .stat-cell {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .stat-num {
            font-weight: 500;
            font-size: 24px;
        }
    }
}
