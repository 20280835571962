@import '../../../styles/vars.scss';

.notificationbell {
    /* display: flex; */
    height: 100%;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%;
}

.notificationbell:hover {
    background-color: #134758;
}

.notification-path {
    font-size: 12px;
    color: $blue;
}

.notification-timestamp {
    font-size: 12px;
}

@media all and (-ms-high-contrast: none) {
    .notificationbell {
        padding-right: 1.6em;
    }
}

.alert-count {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    font-size: 10px;
    color: #e6e6f0;
    line-height: 16px;
    text-align: center;
    top: 10px;
    right: 5px;
    background-color: $red;
}

.alert-list {
    -webkit-transform: translateZ(5px);
    -moz-transform: translateZ(5px);
    -o-transform: translateZ(5px);
    transform: translateZ(5px);
    position: fixed;
    background: $form-dark;
    top: 70px;
    width: 450px;
    right: 94px;
    border-radius: 4px;
    overflow: hidden;
    z-index: 500;
    box-shadow: -2px 2px 20px 1px #000000;
    border: 1px solid #2c4147;
    :hover {
        cursor: pointer;
    }
}

.alert-list-item {
    // outline: pink;
    // outline-width: 2px;
    margin: 0;
    -webkit-transform: translateZ(5px);
    -moz-transform: translateZ(5px);
    -o-transform: translateZ(5px);
    transform: translateZ(5px);
    color: white;
    font-family: 'Rajdhani', sans-serif;
    // background-color: #12323e;
    border-style: none;
    z-index: 5;
    padding: 10px;
    line-height: 25px;
    &:hover {
        background: #101b24;
    }
}

.alert-list-item:not(:last-child) {
    border-bottom: 1px solid #2c4147;
}

.alert-message {
    color: $blue;
}

.alert-severity {
    color: $grey;
    font-size: 13px;
}

.alert-severity-icon {
    height: 11px;
    width: 11px;
    margin-right: 7px;
}

.alert-severity-icon-info {
    path,
    rect,
    line,
    polygon {
        stroke: $blue;
        fill: $blue;
    }
}

.alert-severity-icon-warning {
    path,
    rect,
    line,
    polygon {
        stroke: $red;
    }
}

/* .alert-list-item:not(:first-child) {
  border-bottom: 1px solid #2c4147;
} */

.username {
    color: white;
    display: block;
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid #2c4147;
    font-size: 14px;
    display: flex;
    flex-direction: column;
}

.logout-button {
    border-top: 1px solid #2c4147;
}

.release-number {
    text-align: center;
    padding: 2px 0;
    font-size: 13px;
}
