/* autoprefixer grid: on */

.maingrid {
    display: grid;

    grid-template:
        'header' 3.5em
        'content' / 100vw;

    height: 100%;
    width: auto;
    max-width: 100%;
    overflow-x: hidden;
}

.sidebar-nav {
    box-shadow: none !important;
    margin-right: 10px;
    width: 330px !important;
    top: -2px !important;
    height: inherit;
    min-height: 92.3vh;
}

@supports (-ms-ime-align: auto) {
    .maingrid {
        grid-template:
            'header' 3.5em
            'content' / 100vw;
    }

    .header-grid {
        margin-bottom: 0.5em;
        width: 100%;
        display: hidden;
        height: 0px;
    }
}

.sidebar-grid {
    grid-area: sidebar;
    background-color: #0d171e;
    border-radius: 0.4em;
}

.header-grid {
    grid-area: header;
    height: 69px;
    width: auto;
    max-width: 100vw;
}

@media only screen and (max-width: 600px) {
    .header-grid {
        grid-area: header;
        width: 100vw;
        height: 60px;
    }

    .content {
        width: 90%;
        margin: 0 auto;
        margin-top: 20px;
    }
}

.content {
    grid-area: content;
    /*border: 1px solid white;*/
    /* HACK: Set this way to compensate for sidebar width. Should find more dynamic way*/

    max-width: auto;
    height: calc(100vh - 75px);
    margin-top: 1em;
    overflow-y: auto;
    overflow-x: hidden;
}

.inner-content {
    max-width: 90%;
    width: 1600px;
    margin: 0 auto;
}

.content-table {
    grid-area: content;
    display: grid;
    grid-template-columns: repeat(16, 1fr);

    margin: 1em;
    width: 100%;
}
