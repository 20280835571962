@import '../../../styles/vars.scss';

.ao-title {
    color: $light;
    font-weight: 500;
    > h4 {
        font-weight: normal;
        font-size: 20px;
    }
    &.enabled {
        color: $green;
        font-size: 15px;
    }
    &.disabled {
        color: $red;
    }
}

.ao-container {
    display: flex;
    justify-content: left;
    width: 100%;
}

.items-add-edit-container {
    .dashboard-edit {
        width: 100%;

        .main-content {
            width: 100%;
        }
    }
}
