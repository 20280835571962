@import '../../../styles/vars.scss';
// general
.widget-label {
    width: 74%;
    margin-bottom: 5px;
    > span {
        font-size: 16px;
        letter-spacing: 0.5px;
    }
}

.histogram-more-data {
    position: absolute;
    top: 40%;
    left: 18%;
    z-index: 5;
    background-color: $dark;
    border: 1px solid $blue;
    border-radius: 0.4em;
    height: 100px;
    width: 200px;
}

.widget-title.regular {
    margin-left: 20px;
}

.bar-gauge-histogram.regular {
    padding-top: 55px;
    height: 250px;
    margin-left: 12px;

    .line-nav {
        .left-nav,
        .right-nav {
            top: 34%;
        }
    }
}

.histogram-wrapper {
    width: 100%;
    display: block;
}

// bar-gauge
.bar-gauge-histogram {
    display: flex;
    flex-flow: row nowrap !important;
    flex: 0 0 auto;
    height: 295px;
    padding: 55px 30px 90px 0px;
    overflow: hidden;
    direction: rtl;
    @media only screen and (max-width: 600px) {
        overflow-x: auto;
    }
    .bar-gauge-bar {
        background-color: $teal;
        height: 12px;
        position: relative;
        width: 100%;
    }
    .bar-gauge-number {
        display: block;
        width: 100%;
        > span {
            display: inline-block;
            font-size: 20px;
            font-weight: 500;
            transform: translateX(-50%);
        }
    }
    .bar-gauge-bar-progress {
        background-color: var(--color);
        height: 100%;
        width: var(--progress);
    }
    .bar-gauge-values {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .bar-gauge-bar-marker {
        background-color: var(--color);
        border-left: 3px solid $dark;
        border-right: 3px solid $dark;
        position: absolute;
        top: 0;
        bottom: 0;
        left: var(--at);
        width: 9px;
    }
    .rich-list-expand-area {
        background-color: rgba($teal, 0.15);
        display: flex;
        flex-flow: row wrap;
        padding: 40px 0;
        overflow: hidden;
    }
    .rich-list-tools {
        flex: 0 0 200px;
    } // Histogram
    .bar-gauge-historgram-bar {
        background-color: $teal;
        height: 80%;
        margin-right: 15px;
        position: relative;
        width: 8px;
    }
    .bar-gauge-histogram-values {
        position: relative;
        text-align: left;
        display: flex;
        flex-flow: column wrap;
        flex: 0 0 50px;
        height: 80%;
        justify-content: space-between;
        margin-right: 15px;
        > span {
            line-height: 0.7;
        }
    }
    .bar-gauge-historgram-progress {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.8;
        border-top: 2px solid $light;
    }
    .bar-gauge-historgram-bars {
        flex: 0 0 auto;
        display: flex;
        flex-flow: row nowrap !important;
        direction: ltr;
    }
    .bar-gauge-historgram-marker {
        border-top: 3px solid $dark;
        border-bottom: 3px solid $dark;
        position: absolute;
        left: 0;
        right: 0;
        height: 9px;
    }
    .x-label {
        position: absolute;
        left: 50%;
        top: 85%;
        transform: translateX(0%) translateY(10px);
        @media only screen and (max-width: 600px) {
            transform: translateX(0%) translateY(32px);
        }
        white-space: nowrap;
        > span {
            display: block;
            font-size: 15px;
            letter-spacing: 0.5px;
            transform: rotate(-23deg) translateX(-100%);
        }
    }
    .histogram-hover {
        display: block;
        position: absolute;
        width: 106px;
        padding: 1px;
        top: -52px;
        left: -47px;
        background: #2d4150;
        border-radius: 3px;
        text-align: center;
        border: 1px solid #325663;

        @media only screen and (max-width: 600px) {
            top: -55px;
            left: -49px;
        }
    }
    .histogram-hover:after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(5, 31, 84, 0);
        border-top-color: $teal;
        border-width: 14px;
        margin-left: -14px;
    }
    .histogram-hover-hidden {
        display: none;
    }
}
