$primaryDark: #0d171e;

._2pZMF {
    fill: #359fbf !important;
    display: none !important;
}

._2RbVy {
    fill: #244350 !important;
    opacity: 100% !important;
}

._2dZTy {
    fill: $primaryDark !important;
}

._2dZTy:nth-child(even) {
    fill: $primaryDark !important;
}

._3lLk3 {
    background-color: $primaryDark !important;
    border-bottom: #e6e4e4 1px solid !important;
}

._35nLX {
    fill: $primaryDark !important;
}

._9w8d5 {
    fill: rgb(230, 230, 240) !important;
}

._3_ygE {
    display: table !important;
    border-bottom: #e6e4e4 1px solid !important;
    border-top: none !important;
    border-left: none !important;
}

._2eZzQ {
    border-right: none !important;
    opacity: 1 !important;
    margin-left: -2px !important;
}

._3ZbQT {
    display: table !important;
    border-bottom: #e6e4e4 1px solid !important;
    border-left: none !important;
}

._2q1Kt {
    fill: #e6e4e4 !important;
}

._3lLk3:nth-child(n+2):nth-child(-n+3),
._1nBOt div:nth-child(n+2):nth-child(-n+5) {
    display: none !important;
}

._1KJ6x rect:nth-child(2) {
    fill: #49c4e7 !important;
}

._2k9Ys {
    scrollbar-color: #fff $primaryDark !important;
}

//scrollbar css other than firefox
::-webkit-scrollbar-track {
    background-color: $primaryDark !important;
}

::-webkit-scrollbar-thumb {
    background-color: #fff !important;
}