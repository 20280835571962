@import '../../styles/vars.scss';
.-next .-btn {
    background-color: $teal !important;
    color: $light !important;
}

.-previous .-btn {
    background-color: $teal !important;
    color: $light !important;
}

.date-wrapper {
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.date-picker-button {
    margin-left: 5px;
    border-radius: 8px;
    min-width: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.date-picker-button-end {
    margin-left: 8px;
    min-width: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.date-picker-button .fa-angle-down:before {
    color: #41c9f0;
    font-size: 28px;
    padding: 0 5px;
}

.summary-value {
    font-size: 17px;
    padding-right: 6px;
    color: $grey;
}

.dates-value {
    color: #41c9f0;
    font-size: 17px;
    padding-right: 5px;
    text-transform: uppercase;
}

.alarm-line-container {
    .widget {
        height: 50vh;
    }
}
