.marker {
    cursor: pointer;
    text-align: center;
    width: 30px;
    height: 30px;
    position: relative;
}

.marker svg {
    display: block;
    margin: auto;
    height: 100%;
}

.markerContainer {
    display: flex;
    height: 100%;
}

.marker-name {
    display: block;
    position: fixed;
    color: white;
    margin-top: -25px;
    z-index: 1;
    margin-left: -15px;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.hidden {
    display: none;
}

.pulsating-circle-green:after {
    position: absolute;
    content: '';
    width: 120%;
    height: 120%;
    margin-top: -10%;
    margin-left: -10%;
    box-sizing: border-box;
    border-radius: 45px;
    background-color: rgb(0, 255, 120);
    animation: pulse-ring 3.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    z-index: -1;
}

.pulsating-circle-yellow:after {
    position: absolute;
    content: '';
    width: 120%;
    height: 120%;
    margin-top: -10%;
    margin-left: -10%;
    box-sizing: border-box;
    border-radius: 45px;
    background-color: rgb(255, 255, 0);
    animation: pulse-ring 3.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    z-index: -1;
}

.pulsating-circle-red:after {
    position: absolute;
    content: '';
    width: 120%;
    height: 120%;
    margin-top: -10%;
    margin-left: -10%;
    box-sizing: border-box;
    border-radius: 45px;
    background-color: rgb(255, 0, 0);
    animation: pulse-ring 3.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    z-index: -1;
}

@keyframes pulse-ring {
    0% {
        transform: scale(0.33);
    }
    80%,
    100% {
        opacity: 0;
    }
}

.mapinfo-hidden {
    visibility: hidden;
}
