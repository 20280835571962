.SelectorButton {
    background-color: #111d24;
    border-radius: 0px;
    border: 1px solid #293d43;
    color: #91999d;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system,system-ui,"Segoe UI","Liberation Sans",sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.15385;
    margin: 5px;
    padding: 8px .8em;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    white-space: nowrap;  
  }
  
  .SelectorButton:hover,
  .SelectorButton:focus {
    background-color: #122a34;
    color: #cdcccf;
  }
            
  .SelectorButton-Active {
    background-color: #122a34;
    border-radius: 0px;
    border: 1px solid #293d43;
    box-shadow: none;
    color: #cdcccf;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system,system-ui,"Segoe UI","Liberation Sans",sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.15385;
    margin: 5px;
    padding: 8px .8em;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    white-space: nowrap; 
    outline-width: 1px;
    outline-style: solid;
    outline-color: #3ebde2; 
  }
