.currentitem {
    font-weight: 600;
}

.navlist a {
    text-decoration: none;
    color: #41c9f0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 20px;
    margin-right: 25px;
    position: relative;
    &.currentitem {
        &:after {
            content: '';
            background-color: #41c9f0;
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 2px;
        }
    }
}
.navitem svg .nav-icon {
    fill: #919191;
}

.navitem .currentitem {
    border-bottom: 2px solid #41cdf5;
}
.navitem .currentitem svg .nav-icon {
    fill: #68b4c9;
}

.navitem a:hover {
    text-decoration: none;
    border-bottom: 2px solid #41cdf5;
}

.navlist {
    list-style-type: none;
    padding: 0;
    display: flex;
    margin: 0 auto;
    justify-content: flex-start;
    border-bottom: 1px solid #2c4147;
    margin-bottom: 20px;
    overflow-x: auto;
}

.navcontent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    height: 2em;
    width: 100%;
}
