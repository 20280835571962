.sidepanel-connection {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    color: #41c9f0;
    padding-bottom: 1em;
}

.sidepanel-data {
    display: flex;
    width: 90%;
    font-size: 16px;
    align-items: center;
    border-top: 1px solid #143744;
    padding: 1em 0 1em 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.sidepanel-metadata {
    color: #325663;
    font-size: 1em;
    font-weight: lighter;
}

.external-links {
    display: flex;
}

.sidepanel-imgtxt {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-right: 1em;
}

.sidepanel-img {
    margin-right: 1em;
}

.side-header-cell {
    padding-right: 15px;
}

.fish-farm-sidePanel-table {
    padding-left: 2em;
}
