@import '../../../styles/vars.scss';

.comment-list {
    margin-top: 15px;
    display: flex;
    align-items: flex-start;
    flex-flow: column;

    .my-comment {
        float: right;
        background-color: $blue-faded;
        border: $blue;
        align-self: flex-end;

        .comment-author {
            display: none;
        }
        .comment-rm {
            float: right;
        }
    }
    .their-comment {
        float: left;
        background-color: $teal;
    }
}

.comment-wrapper {
    padding: 4px 15px;
    // padding-bottom: 3px;
    border: 1px solid $teal;
    border-radius: 4px;
    margin-bottom: 2px;
    //width: 75%;

    .comment-author {
        color: $blue;
        margin-right: 5px;
        font-weight: 600;
    }

    .comment-timestamp {
        font-size: 14px;
        color: $grey;
    }
    .comment-rm {
        padding-left: 20px;
    }
    .comment-content {
        color: $light;
        white-space: pre-wrap;
    }
    .comment-content.inline {
        font-style: italic;
    }
}
.comment-header {
    font-weight: normal;
}

.input-comment {
    background: $form-input;
    border: none;
    width: 100%;
    // height: 10px;
    min-height: 86px;
    color: $light;
    //padding: 10px;
    font-size: 16px;
    font-weight: lighter;
}

.input-comment::placeholder {
    color: $light;
    font-family: 'Rajdhani', sans-serif;
    font-size: 18px;
}
.comment-container {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: flex-start;
    // justify-content: center;
    flex-flow: column;
    width: 100%;

    form {
        width: 100%;
    }

    .btn-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .btn {
            margin-top: 4px;
        }
    }

    .comment-mention {
        position: relative;
        font-weight: 600;
        color: $yellow;
        cursor: pointer;

        .user-info {
            display: none;
            position: absolute;
            background: $teal;
            color: $light;
            font-weight: normal;
            padding: 10px;
            border: 1px solid $darkblue;
            z-index: 2;
        }

        &:hover {
            > .user-info {
                display: block;
            }
        }
    }
}

.more-comments {
    color: $blue;
    font-size: 14px;
    cursor: pointer;
}
