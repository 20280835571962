@import '../../styles/vars.scss';
/* autoprefixer grid: on */

.content-grid-report {
    display: grid;
    grid-template:
        'sidebar report report'
        'sidebar report report'
        / 1fr 100%;
    grid-gap: 0;
    height: 100%;
    max-width: 100%;
    width: 100%;
}

.sidebar-grid-report {
    grid-area: sidebar;
    background-color: #0d171e;
    width: 5em;
    margin-left: 1em;
    margin-top: 6.4em;
    overflow: hidden;
}

.reportlist-grid {
    grid-area: report;
    border-radius: 0.4em;

    .btn {
        margin-top: 0;
    }
}

.delete-icon {
    cursor: pointer;
    line {
        stroke: $red;
    }
}

.download-icon {
    path {
        stroke: $blue;
    }
}

@media only screen and (max-width: 1024px) {
    .sidebar-grid-report {
        display: hidden;
        width: 0px;
        margin: 0;
    }

    .list {
        display: flex;
        flex-direction: column;
    }
}

@media all and (-ms-high-contrast: none) {
    .reportlist-grid {
        max-width: 95vw;
        min-width: 80vw;
        grid-area: dash;
        margin-left: 8em;
    }
}

.reportlist-row {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin: 0.4em;
    align-items: center;
    font-size: 15px;
    text-decoration: none;
    color: #ccc;
    background-color: #0d171e;
    border: 1px solid #2c4147;
    border-radius: 0.4em;
}

.reportname-header {
    display: flex;
    justify-content: left;
    align-items: center;
    flex: 0 3 1500px;
    margin-left: 20px;
    height: 100%;
}

.reportlist-name-cell {
    display: flex;
    flex: 0 3 1500px;
    justify-content: left;
    border-right: 1px solid #2c4147;
    height: 100%;
    align-items: center;
    padding-left: 20px;
    padding: 20px;
}

.reportlist-date-cell {
    display: flex;
    flex: 0 1 150px;
    justify-content: center;
    border-right: 1px solid #2c4147;
    height: 100%;
    align-items: center;
    padding-left: 20px;
    padding: 20px;
}

.reportlist-download-cell {
    display: flex;
    flex: 1 0 96px;
    height: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 15px;
    flex-direction: column;
    cursor: pointer;
}

.reportlist-download-cell .download-text {
    padding-top: 8px;
    color: $blue;
}
.download-text {
    color: $blue;
}

.reportlist-header {
    display: flex;
    flex-direction: row;
    justify-content: left;
    height: 3em;
    align-items: center;
    color: #919191;
    font-size: 16px;
}

.date-header-element {
    display: flex;
    flex: 0 1 150px;
    justify-content: left;
}

.download-header-element {
    display: flex;
    flex: 0 1 100px;
    justify-content: left;
}

.download-icon {
    font-size: 15px;
}

.less-top-margin {
    margin-top: 3em;
}

.reportlist-link {
    color: #41cdf5;
}

@media only screen and (max-width: 600px) {
    .reportlist-grid {
        max-width: 100%;
        min-width: 100%;
        grid-area: report;
        margin: 0;
    }

    .sidebar-grid-report {
        width: 0px;
        display: hidden;
    }
}
