.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}

.toggle-btn {
    text-decoration: none;
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    height: 3em;
    width: 600px;
    border-style: none;
    color: #919191;
    cursor: pointer;
    font-size: 15px;
    margin-top: 1em;
    justify-content: center;
    align-items: center;
    background-color: #0d171e;
    border: 1px solid #3f5e6a;
    border-radius: 0.4em;
}

.table-header {
    height: 20px;
    /* width: 107px; */
    width: 100%;
    color: #41c9f0;
    font-family: Rajdhani;
    font-size: 17px;
    letter-spacing: 0.34px;
    line-height: 20px;
}

.add-row {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #41c9f0;
    font-family: Rajdhani;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 15px;
    height: 36px;
    width: 87px;
    border: 1px solid #2c4147;
    border-radius: 5px;
    background-color: #0d171e;
    cursor: pointer;
}

.admin-add-row {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #41c9f0;
    font-family: Rajdhani;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 15px;
    height: 36px;
    width: auto;
    border: 1px solid #2c4147;
    border-radius: 5px;
    background-color: #0d171e;
    cursor: pointer;
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 5px;
}

.adminuser-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 600px;
    padding-top: 40px;
    padding-bottom: 20px;
}
