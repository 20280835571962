.loading-area {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;

    .loading-text {
        font-size: 32px;
        margin-top: 6px;
    }
}

.rich-list-expand-area .loading-area,
.ReactModalPortal .loading-area {
    margin-top: 20px;
    font-size: 26px;
}
