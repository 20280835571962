@import '../../../styles/vars.scss';

.value-cell-group {
    .value-cell-wrapper {
        display: flex;
        justify-content: space-between;
        flex-flow: row nowrap;

        .rounded {
            display: flex;
            justify-content: center;

            .single-value-wrapper {
                display: flex;
                height: 120px;
                width: 120px;
                background: $teal;
                border-radius: 50%;
                justify-content: center;
                align-items: center;
                flex-flow: wrap;
                flex-direction: column;

                .single-value {
                    font-size: 30px;
                    line-height: 1;
                }

                .single-value-unit {
                    font-size: 15px;
                    line-height: 1.2;
                }
            }
        }

        .value-cell-name {
            text-align: center;
            font-size: 18px;
            margin-top: 14px;
            font-weight: 500;
        }
    }
}
