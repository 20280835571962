$dark: rgb(10, 22, 28);
$light: rgb(230, 230, 240);
$teal: rgb(45, 65, 80);
$blue: rgb(65, 205, 245);
$grey: rgb(100, 100, 100);
$wcag-grey: #808080;
$red: rgb(255, 0, 85);
$green: rgb(0, 255, 120);

.documentlist-grid {
    grid-area: notes;
    border-radius: 0.4em;

    .list-item-title {
        flex: 0 0 270px;
        max-width: 270px;
    }

    .list-btn {
        button {
            margin-top: 0;
        }
    }

    .list-item-small {
        flex: 0 0 120px;
    }

    .list-item-plantName {
        flex: 0 0 170px;
    }

    .list-filter-check {
        margin-right: 10px;

        &.disabled .checkbox {
            background-color: $grey !important;
        }
    }

    .center-align {
        text-align: center;
    }

    @media only screen and (max-width: 600px) {
        .ReactModal__Content {
            width: 88%;
            height: 90%;
        }

        .ReactModal__Content form {
            width: 100%;
        }

        .ReactModal__Overlay--after-open {
            overflow-y: scroll !important;
        }

        .form-container {
            height: auto !important;
            margin-top: 20px !important;
        }
    }

    .document-link {
        color: #41cdf5;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .svg-inline--fa {
            color: white;
            padding-right: 2px;
            margin-right: 4px;
        }
    }

    .link-link {
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .svg-inline--fa {
            color: white;
            padding-right: 4px;
            margin-right: 4px;
        }
    }

    .delete {
        cursor: pointer;

        line {
            stroke: $red;
        }
    }

    @media only screen and (max-width: 1025px) {
        .document-item {
            display: flex;
            position: relative;
            flex-flow: row wrap;
        }
        .document-header {
            display: none;
        }
    }
}
