.admin-role-add-button {
    margin-bottom: 1.5em;
}

.admin-role-modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 80em;
    min-height: 30em;
}
.admin-role-modal-bottom-buttons {
    display: flex;
    justify-content: space-between;
}
.admin-role-modal-bottom,
.admin-role-modal-content {
    .btn {
        margin-top: 0;
    }
}

.admin-role-modal-permissions {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    height: 23em;
    width: 100%;
    overflow-y: scroll;
    // background-color: #14242c;
}

.admin-role-modal-items {
    width: 100%;
    display: flex;
    flex-direction: row;
    // background-color: #14242c;
}

.admin-role-modal-item {
    width: 100%;
    margin: 0.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 0;
}

.admin-role-modal-items:nth-child(2n) {
    // background-color: #172931;
}

.admin-role-modal-item-button {
    margin: 0;
    width: 2em;
}

.admin-role-modal-users {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    height: 28.5em;
    width: 100%;
    overflow-y: scroll;
    background-color: #14242c;
}

.admin-role-modal-tabs {
    display: flex;
    margin-top: -40px;
    margin-left: -40px;
    margin-right: -40px;
    flex-direction: row;
    position: absolute;
    width: 100%;
    height: 2.5em;
    justify-content: space-between;
}

.admin-role-modal-tab {
    border-bottom: 1px solid rgb(45, 65, 80);
    border-left: 1px solid rgb(45, 65, 80);
    border-right: 1px solid rgb(45, 65, 80);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.admin-role-modal-tab > p {
    margin-top: 28px;
    display: flex;
    justify-content: center;
}

.admin-role-modal-tab--active {
    background-color: rgb(45, 65, 80);
}

.admin-role-modal-tab:hover {
    background-color: #121e26;
}

.admin-role-modal-tab--active:hover {
    background-color: rgb(45, 65, 80);
}

.admin-role-modal-content {
    width: 100%;
}
