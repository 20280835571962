@import '../../../../styles/vars.scss';

.depth-distribution-chart {
    margin: 8px 0px 5px 20px;
    position: relative;
    width: 100%;

    .depth-distribution-chart-bars {
        position: relative;
        flex: 0 0 100%;
        display: flex;
        flex-direction: column;

        .depth-distribution-chart-bar {
            display: flex;
            flex-flow: row nowrap !important;
            justify-content: center;

            &.left {
                justify-content: flex-start;
                margin-left: 20px;
            }
        }
    }

    .highlighted {
        background-color: white !important ;
    }

    .x-label {
        white-space: nowrap;
        position: absolute;
        left: 0;
        color: $light;
        border-radius: 50%;
        text-align: left;
        &.guideline:before {
            background-color: rgba(65, 201, 240, 0.1);
            position: absolute;
            content: '';
            height: 1px;
            width: calc(100% - 20px);
            left: 22px;
            top: 6px;
            z-index: 1;
            animation-name: bar-horizontal;
            -webkit-animation-name: bar-horizontal;
            animation-duration: 2s;
            -webkit-animation-duration: 2s;
            transition: all 2s;
            -webkit-transition: all 2s;
        }

        > span {
            display: block;
            font-size: 14px;
            margin-top: -4px;
            letter-spacing: 0.5px;
        }
    }

    .depth-distribution-chart-progress {
        height: 4px;
        margin-top: 6px;
        align-items: center;
        justify-content: center;
        z-index: 2;
        opacity: 0.7;
        border-radius: 1px;
        animation-name: bar-horizontal;
        -webkit-animation-name: bar-horizontal;
        animation-duration: 2s;
        -webkit-animation-duration: 2s;
        transition: all 2s;
        -webkit-transition: all 2s;
    }

    @keyframes bar-horizontal {
        from {
            // width: 0%;
        }
    }

    @-webkit-keyframes bar-horizontal {
        from {
            // width: 0%;
        }
    }

    .widget-header {
        padding-top: 8px;
    }

    .depth-distribution-hover-hidden {
        display: none;
    }

    .depth-distribution-hover {
        position: absolute;
        color: $light;
        padding: 2px;
        padding-top: 0;
        cursor: default;
        background-color: #0d171e;
        z-index: 4;
        font-size: 14px;
    }
}
