$primaryDark: #0d171e;

.job-data {
    list-style: none;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

//customTaskListTable 
.taskListWrapper {
    display: table;
}

.taskListTableRow {
    display: table-row;
    text-overflow: ellipsis;
}

.taskListTableRow:nth-of-type(even) {
    background-color: #192c39;
}

.taskListCell {
    display: table-cell;
    vertical-align: middle;
    white-space: nowrap;
    overflow: visible;
    text-overflow: ellipsis;
    min-width: 200px;
}

.taskListName {
    white-space: pre-wrap;
}


.taskListNameWrapper {
    display: flex;
    align-items: center;
}

.taskListExpander {
    color: rgb(86 86 86);
    font-size: 1rem;
    padding: 0.15rem 0.2rem 0rem 0.2rem;
    user-select: none;
    cursor: pointer;
}

.taskListEmptyExpander {
    font-size: 0.6rem;
    padding-left: 1rem;
    user-select: none;
}

.ganttTable {
    display: table;
    // border-bottom: #e6e4e4 1px solid;
    // border-top: #e6e4e4 1px solid;
    // border-left: #e6e4e4 1px solid;
}

.ganttTable_Header {
    display: table-row;
    list-style: none;
}

.ganttTable_HeaderSeparator {
    border-right: 1px solid rgb(196, 196, 196);
    opacity: 1;
    margin-left: -2px;
}

.ganttTable_HeaderItem {
    display: table-cell;
    vertical-align: -webkit-baseline-middle;
    vertical-align: middle;
}

//custom tooltip css
.tooltip-wrapper {
    background-color: #081b23;
    display: flex;
    flex-direction: column;
    padding: 10px 20px 10px 20px;
    justify-content: center;
    align-items: center;
    width: 30vmin;
    border: #2c4954 solid 1px;
    border-radius: 3px;

    hr {
        width: 100%;
        opacity: 0.3;
    }

    span {
        font-size: 18px;
        color: #bbbbbb;
    }
}