.input-control :valid ~ .invalid-feedback {
    display: none;
}

.optimar__control {
    min-height: 3em;
}

.form-container {
    height: 100%;
    padding-bottom: 20px !important;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    width: 100%;
    min-width: min(90vw, 500px);
}

form {
    width: 100%;
    max-width: 600px;
}

.form-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 600px;
    width: 100%;
    /* padding-top: 20px;
    padding-bottom: 20px; */
}

.input-control,
.date-input-control input {
    text-decoration: none;
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    height: 3em;
    width: 100%;
    border-style: none;
    color: #919191;
    font-size: 15px;
    /* margin-top: 1em; */
    justify-content: center;
    align-items: center;
    background-color: #0d171e;
    border: 1px solid #3f5e6a;
    border-radius: 0;
    padding-left: 5px;
}

.rdtPicker {
    background: #0d171e !important;
    border: 1px solid rgb(45, 65, 80) !important;
    width: auto !important;
}
.rdtPicker table th:hover,
.rdtPicker table td:hover {
    background: #428bca !important;
    color: white;
}
.rdtPrev,
.rdtSwitch,
.rdtNext {
    border-bottom: 1px solid rgb(45, 65, 80) !important;
}

textarea {
    min-height: 80px !important;
}

.form-group input[type='checkbox'] {
    height: 20px;
    -webkit-appearance: checkbox;
    width: 20px;
    display: block;
}

select {
    text-decoration: none;
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    height: 3em;
    width: 100%;
    border-style: none;
    color: #919191;
    cursor: pointer;
    font-size: 15px;
    /* margin-top: 1em; */
    justify-content: center;
    align-items: center;
    background-color: #0d171e;
    border: 1px solid #3f5e6a;
    border-radius: 0;
    padding-left: 5px;
}

label {
    display: inline-block;
    padding-top: 20px;
}

.submit {
    text-decoration: none;
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    height: 3em;
    width: 100%;
    border-style: none;
    color: #919191;
    cursor: pointer;
    font-size: 15px;
    margin-top: 1em;
    justify-content: center;
    align-items: center;
    background-color: #0d171e;
    border: 1px solid #3f5e6a;
    border-radius: 0;
}

.submit :disabled {
    background: #919191;
}

.formErrors {
    color: #ff0000;
}

.button :disabled {
    border: 1px solid #383838;
}

button:disabled,
button[disabled] {
    color: #383838;
    cursor: default;
}

.user-successfully-added {
    color: green;
}

.geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
}

.geosuggest__item {
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .form-container {
        margin-top: 40px;
    }
}

.search-items {
    background-color: transparent;
    border: 0;
    color: #e6e6f0;
    background: #0d171e;
    line-height: 1;
    padding: 5px 5px 5px 10px;
    width: 100%;
    top: 0.5em;
    left: 0.5em;
    z-index: 5;
    text-decoration: none;
    font-family: 'Rajdhani', sans-serif;
    height: 3em;
    width: 100%;
    border-style: none;
    color: #919191;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    border: 1px solid #3f5e6a;
    border-radius: 0;
    padding-left: 5px;
}

.search-items:focus {
    outline: none;
    border-bottom: 1px solid #41cdf5;
}

.search-items::placeholder {
    color: #bbb;
}

.search-items-dropdown {
    display: flex;
    flex-direction: column;
    background: #0d171e;
    border: 1px solid #2d4150;
    z-index: 5;
    position: absolute;
    top: 3em;
    left: 0.65em;
    top: 77px;
    left: 0px;
    width: 100%;
    max-height: 340px;
    overflow-y: auto;
}

.search-items-drodown-element {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    cursor: pointer;
    padding-left: 10px;
}

.search-items-drodown-element:hover {
    background: #13232d;
}

.ReactModal__Content:has(.form-container)::-webkit-scrollbar {
  width: 6px;
} 
.ReactModal__Content:has(.form-container)::-webkit-scrollbar-thumb {
  background-color: rgb(45 65 80);
  border-radius: 4px;
} 
.ReactModal__Content:has(.form-container)::-webkit-scrollbar-thumb:hover {
  background-color: #778c9d;
  border-radius: 4px;
} 