.hideframe {
    display: none !important;
}
.support .body {
    padding-left: 10px;
}
.content.center {
    justify-content: center;
}

.discussion .files {
    max-width: 600px;
}
