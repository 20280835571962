@import '../../../../styles/vars.scss';
.filter-input {
    border-radius: 0px;
    -webkit-appearance: none;
    color: $light;
    border: 1px solid $teal;
    background-color: $dark;
    &:focus {
        border-bottom: 1px solid $blue;
    }
}
