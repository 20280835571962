@import '../../../styles/vars.scss';

.vertical-bar {
    position: relative;
    // padding: 8px 0 5px 0;

    .vertical-wrapper {
        display: flex;
    }

    .bar-gauge-upload-status {
        position: absolute;
        top: -28px;
        right: 0;
        font-size: 15px;
        color: $wcag-grey;
    }

    .old-data {
        color: $yellow;
    }

    .bar-gauge-bar {
        background-color: $teal;
        width: 16px;
        position: relative;
        height: 55px;
        flex: 0 0 16px;
    }

    .bar-gauge-number {
        display: block;
        min-height: 30px;
        height: 100%;

        > span {
            display: inline-block;
            font-size: 20px;
            font-weight: 500;
        }
    }

    .bar-gauge-bar-progress {
        background-color: var(--color);
        height: 100%;
        height: var(--progress);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .bar-gauge-bar-progress-ocl {
        background-color: var(--color);
        height: 100%;
        height: var(--progress);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        &.downtime {
            transform: rotate(180deg);
        }
    }

    .bar-gauge-values {
        display: none;
        justify-content: space-between;
        font-size: 16px;
        margin: -11px 0px 0 10px;
        letter-spacing: 0.5px;
        height: 80px;
        flex-direction: column-reverse;
    }

    .bar-gauge-bar-marker {
        background-color: var(--color);
        border-left: 3px solid $dark;
        border-right: 3px solid $dark;
        position: absolute;
        top: 0;
        bottom: 0;
        bottom: var(--at);
        height: 9px;
    }

    .time-since {
        color: $yellow;
        font-size: 13px;
        left: 0;
    }

    // Data

    .data-wrapper {
        display: block;
        margin-left: 10px;
        transform: translateY(8px);
        margin-top: -9px;
        span {
            line-height: 1em;
        }
    }

    .data-title__label {
        color: #ececec;
        font-size: 18px;
    }

    .data {
        display: flex;
        flex-flow: row nowrap;
    }

    .data-value__number {
        color: #ececec;
        font-size: 46px;
        font-weight: 500;
        line-height: 1;
        margin: 0;
        padding: 0;
    }

    .data-units {
        margin-left: 8px;
        transform: translateY(3px);
    }

    .data-units__total {
        color: #ececec;
        display: block;
        font-size: 18px;
        margin: 0;
        padding: 0;
    }

    .data-units__unit {
        color: #b4b4b4;
        display: block;
        font-size: 16px;
        margin: 0;
        padding: 0;
    }
}
