$minorColor: #1e2e34;
$white: #fff;

.date-picker-wrapper {
    margin: auto 0 auto 10px;
}

.input-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;

    input {
        color: $white;
        min-height: 35px;
        min-width: 200px;
        background-color: $minorColor;
        border: none;
        font-size: 1rem;
        font-weight: 400;
        padding: 5px 0 5px 5px;
    }

    input::placeholder {
        color: $white;
        opacity: 0.3;
    }

    span {
        position: absolute;
        right: 5px;
        cursor: pointer;
    }

}

.react-datepicker__input-container {
    display: flex;
}

button.react-datepicker__close-icon {
    right: -15%;
}

.react-datepicker {
    background-color: #14242e;
    color: $white;
    border: none;
    border-radius: 10px;
}

.react-datepicker__header {
    background-color: $minorColor;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day {
    color: $white;
    opacity: 0.8;
}

.react-datepicker__current-month:hover,
.react-datepicker__day-name:hover,
.react-datepicker__day:hover {
    background-color: #456876;
}