.ocl-solution {
    width: 100%;

    .data-title {
        padding-bottom: 20px;
    }

    .ocl-main {
        width: 100%;
        height: 300px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        .vertical-bars {
            flex: 0 0 68%;
            height: 300px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;

            .vertical-bar {
                height: 100%;
                padding-right: 5px;

                .vertical-wrapper {
                    height: 100%;
                    align-items: flex-end;

                    .bar-gauge-bar {
                        height: 100%;
                    }
                }
            }
        }
        .ocl-right-container {
            //Setting width to prevent "hopping" when time updates.
            width: 220px;
            height: 300px;
            .ocl-time {
                // position: absolute;
                // right: 9%;
                font-size: 70px;
                span {
                    text-align: left;
                }
            }
            .ocl-gauge {
                flex: 0 0 28%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .ocl-line-bar {
        width: 100%;
        height: 100px;
        margin-top: 20px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        .etf {
            flex: 0 0 15%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .heading {
                font-size: 19px;
                font-weight: 700;
            }
        }
    }

    .time-since {
        display: none;
    }
}
