@import '../../styles/vars.scss';
.claim-disclaimer {
    color: $light;
    font-size: 14px;
    margin-top: 20px;
    // max-width:50%;
}
.main-header-action {
    .main-header-right {
        display: flex;
    }
}
.support {
    .search-items-dropdown {
        position: absolute;
        width: 400px;
        top: 45px;
    }

    .linkednote {
        margin-top: 20px;
        border-top: 1px solid $teal;

        .files-and-comments {
            display: flex;
            justify-content: flex-start;
            .comment-container {
                flex: 0 1 30%;
                .comment-header {
                    margin: unset;
                    font-size: 18px;
                    font-weight: 600;
                }
            }
        }
    }
    .edit-icon {
        width: 18px;
        margin-left: 5px;
        line,
        polygon {
            stroke: $blue;
        }
        cursor: pointer;
    }
    .close {
        line {
            stroke: $red;
        }
        cursor: pointer;
        position: absolute;
        right: 5px;
        top: 5px;
    }
    .support-header {
        border-bottom: 1px solid $teal;
        display: flex;
        justify-content: space-between;
        .refs {
            display: flex;
            flex-direction: column;
        }
        .title {
            font-size: 20px;
        }
        input {
            background: #1e2e34 !important;
            border: 0 !important;
            border-radius: 0 !important;
        }
        margin-bottom: 10px;
    }
    .content {
        display: flex;
        color: $light;
        flex-flow: row wrap;
        overflow: revert;
        height: auto;
        .info {
            flex: 0 0 24%;
            font-size: 17px;

            td:nth-child(1) {
                font-weight: 600;
                min-width: 100px;
            }
            td:nth-child(2) {
                div {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    :only-child {
                        margin-left: auto;
                    }
                }
            }
            input,
            select {
                background: #1e2e34 !important;
                border: 0 !important;
                border-radius: 0 !important;
            }
            padding-right: 2%;
        }
        .body {
            flex: 0 0 76%;
            .description {
                margin-bottom: 15px;
            }
            @media only screen and (max-width: 600px) {
                flex: 0 0 100%;
                width: 100%;
                padding: 20px 0;
            }

            .discussions {
                display: flex;
                margin-bottom: 15px;
                flex-flow: row wrap;
                .discussion {
                    margin-right: 15px;
                    width: 45%;
                    textarea {
                        resize: none;
                    }

                    @media only screen and (max-width: 600px) {
                        width: 100%;
                    }
                }
            }

            .files {
                .fileupload {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    input {
                        width: 190px;
                    }
                }
                td {
                    min-width: 60px;
                }
                thead {
                    td {
                        font-weight: 600;
                    }
                }
                tbody {
                    td:nth-child(1) {
                        color: $blue;
                        cursor: pointer;
                    }
                }
            }

            textarea {
                background: #1e2e34 !important;
                border: 0 !important;
                border-radius: 0 !important;
            }
        }
    }
    .vcp-startend {
        display: flex;
        justify-content: flex-start;
    }
    .vcp-startend > div {
        display: inline-block;
        margin-right: 15px;
    }
    .vcp-form {
        .input-control {
            border-width: 0;
        }
    }
    .btnRow {
        display: flex;
        justify-content: flex-end;
    }

    .btnRow > button {
        margin-right: 30px;
    }
}
